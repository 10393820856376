.wrapper_bank_fee_info_edit {
  display: grid;
  width: 565px;
  grid-gap: 24px;
  padding: 20px 0;
}

.wrapper_bank_fee_info_edit .wrapper_btns {
  display: grid;
  grid-template-columns: 1fr max-content;
}

.wrapper_bank_fee_info_edit .phone-wrapper {
  grid-column: unset;
}

.wrapper_bank_fee_info_edit .wrapper_btns .cancel {
  justify-self: start;
}
