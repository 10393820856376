.root {
  background-color: var(--colour-neutral-grey-6);
  display: grid;
  gap: 1px;
  position: absolute;
  right: 0;
  top: 4em;
  width: calc(100vw - 15em);
  z-index: 100;
}
