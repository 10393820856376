.notification {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  gap: 2em;
  grid-template-columns: 3.4em 1fr 15em 10em;
  padding: 1em;
}

.notificationIcon {
  border-radius: 50%;
  padding: 0.5em;
}

.notification > :global(button) {
  width: 100%;
}
