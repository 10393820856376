.divider {
  border-top: 1px solid var(--colour-neutral-grey-4);
}

.containerButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin: 24px 0;
}

.textOR {
  margin: 0 24px;
}

.containerColumns {
  display: flex;
  flex: 1;
  padding: 24px 0;
  gap: 24px;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
}

.columnTitle {
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 22px;
}

.footerText {
  margin-top: 24px;
}
