.note {
  padding: 16px;
  background-color: var(--colour-brand-1);
  border: 1px solid var(--colour-brand-2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 24px;
}

.noteTitle {
  font-size: 16px;
  line-height: 24px;
  color: var(--colour-brand-9);
  margin-bottom: 0;
}

.noteTimeWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.noteTime {
  color: var(--colour-brand-9);
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.list {
  list-style: none;
  margin-top: 1rem;
  padding: 0;
}

.list > :global(li) {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.collapse :global(.ant-collapse-header) {
  flex-direction: row-reverse;
  font-weight: 600;
  padding: 1em 0 !important;
}

.text {
  margin-bottom: 8px;
}
