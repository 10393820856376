.ant-checkbox-group.custom-checkbox-block {
  display: grid;
  width: 100%;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
}

.ant-checkbox-group.custom-checkbox-block.custom-checkbox-block_3 {
  grid-template-columns: repeat(3, 1fr);
}

.ant-checkbox-group.custom-checkbox-block.custom-checkbox-block_4 {
  grid-template-columns: repeat(4, 1fr);
}

.ant-checkbox-group.custom-checkbox-block .ant-checkbox {
  display: none;
}
.ant-checkbox-group.custom-checkbox-block .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.ant-checkbox-group.custom-checkbox-block .ant-checkbox-wrapper {
  min-height: 40px;
  border: 2px solid #bfbfbf;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  transition: 0.2s ease all;
  background: #ffffff;
}

.ant-checkbox-group.custom-checkbox-block .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  background: #e6f7ff;
  border: 2px solid #40a9ff;
}
