.wrapper {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper iframe {
  width: 100%;
  border: none;
}
