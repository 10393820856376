.diligence-item-notes__header {
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  padding: 8px 24px;
}

.diligence-item-notes__header div:last-child {
  display: flex;
  gap: 8px;
}

.diligence-item-notes__header div:first-child {
  margin-bottom: 4px;
}

.diligence-notes {
  display: flex;
  flex-direction: column;
  padding: 1em;
  row-gap: 16px;
}

.note-item {
  border: 1px solid #f0f0f0;
  padding: 16px;
}

.note-item__header {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
}

.note-item__user {
  align-items: center;
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.note-item__desc {
  margin-top: 8px;
}

.note-item__date {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
}

.note-item__user-role {
  background: #f0f0f0;
  padding: 2px 6px;
}

.note-item__user-name {
  font-weight: 700;
}

.note-item__desc {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.note-item__note-actions {
  align-items: flex-end;
  display: flex;
  gap: 8px;
  margin-right: -8px;
}

.note-item__action-btn {
  background: transparent;
  border: none;
  color: #8c8c8c;
  cursor: pointer;
}

.note-item__badge .ant-badge-status-dot {
  height: 8px;
  width: 8px;
}
