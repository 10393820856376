.lar_profile_info {
  max-height: 1500px;
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;
}

.lar_profile_info__inner {
  align-items: flex-start;
  background-color: var(--widget-color);
  border-radius: var(--widget-border-r);
  display: flex;
  flex-wrap: nowrap;
  padding: 21px 18px;
  transition: 0.7s;
}

.lar-profile_info__corp {
  align-items: center;
  display: flex;
}

.lar-profile_info__corp-img {
  margin-left: 8px;
}

.lar-profile_info__corp-text {
  color: #8c8c8c;
  font-feature-settings: 'tnum' on, 'lnum' on;
  font-size: 11px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
}

.lar-profile_info__img-container {
  height: 100%;
  max-width: 326px;
}

.lar-profile_info__img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.lar_profile_info_uncollapsed {
  max-height: 0;
}

.lar_profile_info_collapsed {
  max-height: 0;
  overflow: hidden;
}

.lar-profile_info__content-block {
  display: flex;
  width: 100%;
}

.lar-profile_info__info-list-block {
  margin-left: 33px;
  width: 60%;
}

.lar-profile-status {
  color: #fdfdff;
  display: inline-block;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  padding: 6px 8px;
}

.lar-profile-status_submited {
  background: #73d13d;
}

.lar_profile_info .unique_data .label {
  color: #8c8c8c;
  font-size: 12px;
  line-height: 20px;
}

.lar_profile_info .unique_data .value {
  color: #1f1f1f;
  font-feature-settings: 'tnum' on, 'lnum' on;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}

.lar_profile_info .unique_data {
  margin-bottom: 15px;
}

.lar-profile_info__actions-block {
  margin-left: 24px;
}

.lar-profile_info__actions {
  display: flex;
  align-items: center;
}

.lar-profile_info__action {
  cursor: pointer;
}

.lar-profile_info__action:nth-child(2n) {
  margin-left: 16px;
}

.quote-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quote-title-wrapper span {
  margin: 0;
}

@media screen and (max-width: 1100px) {
  .lar-profile_info__content-block {
    flex-direction: column;
    row-gap: 24px;
  }

  .lar-profile_info__info-list-block {
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .lar_profile_info .unique_data .value {
    font-size: 14px;
  }
}

@media screen and (max-width: 475px) {
  .lar_profile_info__inner {
    align-items: flex-end;
    flex-direction: column-reverse;
    gap: 20px;
  }
}
