.root {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  padding: 1rem;
  color: #1f2c5f;
  filter: drop-shadow(0px 2px 4px rgba(40, 41, 61, 0.04)) drop-shadow(0px 8px 16px rgba(96, 97, 112, 0.16));
  background-color: #fdfdff;
}

.header {
  display: inline-flex;
  align-items: center;
  grid-gap: 0.5rem;
}

.viewButtons {
  width: 100%;
  overflow: auto;
}

.root .title {
  color: inherit;
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0;
}

.messageText {
  grid-column: 2;
}

.close,
.message > :global(time) {
  justify-self: flex-end;
}

.close {
  cursor: pointer;
}
