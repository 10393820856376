.broker-landing-popup-footer {
  display: flex;
  flex-direction: column;
}

.broker-landing-popup-row-footer {
  display: flex;
  justify-content: flex-end;
}

.broker-landing-popup-row-footer > * + * {
  margin-left: 16px;
}

.broker-landing-popup-content .input_container,
.broker-landing-popup-content .phone-wrapper {
  margin-bottom: 24px;
}

.broker-landing-popup-content .split-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.broker-landing-popup-content .description-12 {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  display: block;
}

.broker-landing-popup-content .description-12 a {
  color: #2a4fde;
  font-weight: 600;
}

.broker-landing-popup-footer > * + * {
  margin-top: 16px;
}
