.header {
  padding: 16px 25px 8px;
  background-color: #f5f5f5;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  z-index: 2;
  position: relative;
  width: inherit;
}

.header * {
  margin: 0;
}

.headerTop {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  white-space: nowrap;
}

.headerTopTitle {
  text-transform: capitalize;
  margin-right: 16px;
}

.headerTopTitleWithBtn {
  margin-left: 40px;
}

.headerTopId {
  margin-left: 16px;
  display: inline-block;
}

.headerAddress {
  color: #1f1f1f;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headerMid {
  display: flex;
  grid-gap: 0.5rem;
  overflow-x: auto;
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 12px;
}

.headerBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 22px;
}

.lastVisit {
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}

.closeBtn {
  margin-right: 12px;
  transform: rotate(180deg);
}

.more {
  position: absolute;
  padding: 10px;
  right: 15px;
  z-index: 2;
}

.more:hover .moreList {
  opacity: 1;
  z-index: 100;
  visibility: visible;
}

.moreList {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 100%;
  padding: 4px 0;
  opacity: 0;
  z-index: -1;
  transition: 0.2s;
  visibility: hidden;
}

.moreListItem {
  padding: 5px 12px;
  white-space: nowrap;
  transition: 0.2s;
  cursor: pointer;
}

.moreListItem:hover {
  background-color: #f5f5f5;
}

.addInfo {
  display: flex;
  align-items: center;
}

.archived {
  color: #595959;
  padding-right: 40px;
}
