.inviteButton {
  position: absolute;
  bottom: 100%;
  left: 120px;
  width: auto !important;
  margin-bottom: 1.5rem;
  border-color: var(--colour-brand-9);
  background-color: var(--colour-brand-9);
}

.inviteButton:hover {
  border-color: var(--colour-brand-9);
  background-color: var(--colour-brand-9);
}
