.root :global(.ant-modal-body) {
  display: grid;
  gap: 1rem;
}

.root :global(.ant-modal-footer) {
  padding: 0 24px 24px;
}

.root :global(.ant-modal-header) {
  padding: 24px 24px 0;
}

.root :global(.ant-modal-close) {
  top: 7px;
  right: 5px;
}

.block {
  background-color: var(--colour-neutral-grey-3);
  border: 1px solid var(--colour-neutral-grey-6);
  display: grid;
  grid-template-columns: 2rem 1fr;
  padding: 1rem;
  text-align: justify;
}

.blueBlock {
  background-color: var(--colour-brand-1);
  border-color: var(--colour-brand-5);
}

.greenBlock {
  background-color: var(--colour-green-1);
  border-color: var(--colour-green-5);
}
