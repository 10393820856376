.root {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 250px;
  color: var(--colour-neutral-grey-11);
  border: 1px solid var(--colour-neutral-grey-5);
  background-color: var(--colour-neutral-grey-1);
}

.pointer {
  cursor: pointer;
}

.actions {
  position: absolute;
  right: 0.125rem;
  top: 0.125rem;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  overflow: hidden;
}

.image {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rootAction .button {
  border: 0;
  opacity: 0;
  padding: 0.25rem;
  width: 2rem;
  height: 2rem;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.3);
}

.rootAction .button:hover {
  color: #ffffff;
}

.rootAction:hover {
  background-color: var(--colour-neutral-grey-5);
}

.rootAction:hover .button {
  opacity: 1;
}

.rootAction .button svg {
  width: 100%;
  height: 100%;
  stroke: transparent;
  fill: currentColor;
}

.fileWrapper {
  display: flex;
  grid-gap: 0.5rem;
  padding: 0.5rem;
}

.fileTypeImageWrap {
  display: flex;
  color: var(--colour-brand-5);
}

.fileInfo {
  font-size: 0.75rem;
  min-width: 0;
}

.fileTypeImageWrap img,
.fileTypeImageWrap svg {
  width: auto;
  height: 40px;
  stroke: currentColor;
}

.fileName {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
