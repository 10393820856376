/*------------IMPORT COMMON STYLES-----------------*/
@import url('drawer_common.css');
@import url('button_common.css');
@import url('ant/button.css');
@import url('ant/modal.css');

/* FONTS */

@font-face {
  font-family: 'PosterGothicATF';
  src: local('MyFont'), url(../fonts/PosterGothic/PosterGothicATF.otf) format('opentype');
}

@font-face {
  font-family: 'PosterGothicATF';
  src: local('MyFont'), url(../fonts/PosterGothic/PosterGothicATF-Medium.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'PosterGothicATF';
  src: local('MyFont'), url(../fonts/PosterGothic/PosterGothicATF-Bold.otf) format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'PosterGothicATF';
  src: local('MyFont'), url(../fonts/PosterGothic/PosterGothicATF-Heavy.otf) format('opentype');
  font-weight: 700;
}

/* FONTS */

body {
  margin: 0;
  min-width: 320px;
}

/*COLORS*/

:root {
  /*main*/
  --awesome_purple: #6c5ce7;
  --light_purple: #afa6f2;
  --purple: #877aeb;
  --dodger_blue: #1890ff;
  --error: #fe3e3e;
  --widget-color: #ffffff;
  --black: #000000;
  --main_blue_color: #40a9ff;
  --main_dark_blue_color: #1f2c5f;

  --dr_white: #fafafa;
  --white_smoke: #f5f5f5;
  --nero_black: #1f1f1f;
  --sea_weed: #0f172a;
  --color-main-gray: #e5e5e5;

  /*gray*/
  --gray_pale_blue: #e2e8f0;
  --dark_gray: #434343;
  --pearl_gray: #cbd5e1;
  --light_gray: #d9d9d9;
  --disabled_button: #a9b1cc;

  --colour-red-1: #fff1f0;
  --colour-red-5: #ff4d4f;

  --colour-green-1: #f6ffed;
  --colour-green-2: #d9f7be;
  --colour-green-5: #73d13d;
  --colour-green-6: #52c41a;
  --colour-green-7: #389e0d;
  --colour-green-8: #237804;
  --colour-green-9: #135200;

  --colour-gold-2: #fff1b8;
  --colour-gold-5: #ffc53d;
  --colour-gold-7: #d48806;
  --colour-gold-9: #874d00;

  --colour-brand-1: #e6f7ff;
  --colour-brand-2: #91d5ff;
  --colour-brand-4: #69c0ff;
  --colour-brand-5: #40a9ff;
  --colour-brand-9: #242b5c;

  /* neutral grey */
  --colour-neutral-grey-1: #fff;
  --colour-neutral-grey-2: #fafafa;
  --colour-neutral-grey-3: #f5f5f5;
  --colour-neutral-grey-4: #f0f0f0;
  --colour-neutral-grey-5: #d9d9d9;
  --colour-neutral-grey-6: #bfbfbf;
  --colour-neutral-grey-7: #8c8c8c;
  --colour-neutral-grey-8: #595959;
  --colour-neutral-grey-10: #262626;
  --colour-neutral-grey-11: #1f1f1f;

  /*border-radius*/
  --widget-border-r: 8px;

  /*redesign*/
  --main-red-color: #ff4d4f;
  --secondary-red-color: #cf1322;
  --main-darkblue-color: #242b5c;
  --secondary-darkblue-color: #1f3047;
  --main-blue-color: #40a9ff;
  --main-light-blue-color: #e6f7ff;
  --main-bg-gray-color: #f5f5f5;
  --main-divider-gray-color: #d9d9d9;
  --main-divider-gray-color2: #fdfdfd;
  --secondary-divider-gray-color: #bfbfbf;

  /* quotes */
  --quote-row-height: 35px;
  --quote-row-fz: 14px;
  --quote-row-lh: 18px;

  /* navigation */
  --nav-item-height: 114px;
}

/*COLORS*/

body * {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

input,
button,
textarea {
  outline: none;
}

body .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.2);
}

.description-12 {
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
}

.description-14 {
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
}

.loading_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#root {
  height: inherit;
}

#main {
  align-content: flex-start;
  display: grid;
  grid-template-rows: 4em 1fr;
  height: inherit;
}

@media screen and (min-width: 900px) {
  #main {
    grid-template-columns: 15em 1fr;
  }
}

.ant-modal-footer {
  display: flex;
  justify-content: right;
}

.ant-popover-buttons {
  display: flex;
  justify-content: flex-end;
}

.ant-btn {
  align-items: center;
  display: flex !important;
  gap: 0.5em;
  justify-content: center;
}

.links-list-item:not(:last-child)::after {
  content: ', ';
}

.error {
  color: var(--error);
}

.scenario-required-field,
.auction-required-field {
  position: relative;
}

.scenario-required-field::after {
  content: '';
  position: absolute;
  left: -22px;
  top: 11px;
  background-color: var(--colour-brand-5);
  border-radius: 100%;
  width: 8px;
  height: 8px;
}

.auction-required-field::before {
  content: '';
  position: absolute;
  left: -12px;
  top: 11px;
  background-color: var(--colour-green-6);
  border-radius: 100%;
  width: 8px;
  height: 8px;
}
