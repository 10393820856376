.tableHeader {
  display: flex;
  margin-bottom: 15px;
  gap: 0.7%;
  position: sticky;
  top: 0;
  z-index: 10;
}

.quotesList {
  width: 100%;
}

.quoteItem {
  display: flex;
  margin-bottom: 15px;
  gap: 0.7%;
}

.col {
  border: 1px solid #bfbfbf;
  min-width: 12%;
  max-width: 12%;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Helvetica', sans-serif;
  position: relative;
  background-color: var(--widget-color);
}

.col1 {
  min-width: 31%;
  max-width: 31%;
  padding: 0;
}

.col5 {
  min-width: 42%;
  max-width: 42%;
}

.colAccent {
  background-color: #377ecb;
  color: #fff;
}

.quoteMain {
  align-items: flex-start;
}

.head {
  background-color: #f0f0f0;
  border: 1px solid #bfbfbf;
  min-height: 40px;
  color: #1f1f1f;
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Helvetica', sans-serif;
}

.quotePayment,
.quoteRate,
.quoteMonthlyPayment {
  font-weight: 700;
}

.quotePayment > span,
.quoteRate > span,
.quoteMonthlyPayment > span {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  position: absolute;
  bottom: 40px;
}

.quoteMainTitle {
  color: #377ecb;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 4px 16px;
  width: 100%;
  background-color: rgba(64, 169, 255, 0.24);
  font-family: 'Helvetica', sans-serif;
}

.quoteMainRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  color: #1f1f1f;
  padding: 4px 16px;
}

.quoteRowBlock {
  border-bottom: 1px solid var(--light_gray);
}

.quoteMainRow * {
  font-family: 'Helvetica', sans-serif;
}

.info {
  cursor: pointer;
}

.modal :global(.ant-modal-close-x) {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
}

.hidden {
  display: none;
}
