.body {
  /*padding: 16px 24px;*/
}
.requestItem {
  width: 100%;
  min-height: 245px;
  border: 2px solid #d9d9d9;
  cursor: pointer;
  user-select: none;
  transition: 0.2s ease all;
}
.requestItem.requestItemActive {
  border: 2px solid #40a9ff;
}
.requestItem :global(svg) {
  transition: 0.2s ease all;
}
.requestItem.requestItemActive :global(svg path) {
  transition: 0.2s ease all;
  fill: #40a9ff;
}
.requestItemHead {
  padding: 16px;
  background: #fafafa;
  text-align: center;
}

.requestItemContent {
  padding: 16px;
}
.requestItemTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.requestItemDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
  text-align: center;
}
