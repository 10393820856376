.root {
  display: grid;
  align-content: flex-start;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto 1fr;
  padding: 0;
  height: calc(100vh - 4rem);
  background-color: var(--colour-neutral-grey-2);
}

.smallContentWidth {
  width: calc(100% - 500px);
}

.noNavRoot {
  grid-template-columns: 4fr;
}

.root > :global(:not(:first-child)) {
  overflow-y: auto;
}

.root > :global(:nth-child(2)) {
  grid-row: 2/4;
}

.root.noNavRoot > :global(:nth-child(2)) {
  grid-column: 1/3;
}

.root :global(main) {
  position: relative;
}

.publicMessagesWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  left: 0;
  padding: 2em;
  position: absolute;
  right: 0;
  z-index: 2;
}
