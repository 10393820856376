.columnTitle {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  color: var(--colour-neutral-grey-10);
  margin-bottom: 5px;
}

.columnSubtitle {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: var(--colour-neutral-grey-8);
}

.dealInfoWrapper {
  display: flex;
}

.dealInfoId {
  min-width: 60px;
  margin-right: 20px;
  width: 12%;
}

.dealInfoType {
  min-width: 70px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 30%;
}

.dealInfoAmount {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 58%;
}

.auctionStatsWrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.auctionStatsTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auctionStatsItem {
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  width: 39px;
}

.selectionProcessWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectionProcessActions {
  width: 162px;
}

.event {
  line-height: 26px;
}

.winnerModal {
  display: flex;
  align-items: center;
}

.winnerModal :global(.ant-modal-footer) {
  display: none;
}

.winnerModal :global(.ant-modal-body) {
  padding-top: 48px;
}

.trophyIcon {
  color: var(--colour-green-6);
}
