.filter {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.filter button {
  width: 115px;
  min-width: max-content;
  max-width: 100%;
}
