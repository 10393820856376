.auction-dashboard-wrapper {
  padding: 24px;
  position: relative;
  overflow-x: hidden;
}

.auction-dashboard-wrapper .screen-bg-full,
.auction-dashboard-wrapper .screen-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
}

.auction-dashboard-wrapper .screen-bg-full {
  background: #f5f5f5;
  bottom: 0;
  right: 0;
  z-index: -2;
}

.auction-dashboard-wrapper .auction-dashboard {
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  align-items: center;
  margin: auto;
}

.auction-dashboard-wrapper .auction-dashboard .lar-quotes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  margin-top: 48px;
  width: 100%;
  margin-bottom: 32px;
}

.auction-dashboard-wrapper .auction-dashboard .title {
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  color: #1f1f1f;
  text-align: center;
}

.auction-dashboard-wrapper .auction-dashboard .subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #1f1f1f;
  margin-top: 24px;
}

.quote-preview .lar-card,
.auction-dashboard-wrapper .auction-dashboard .lar-quotes .lar-card {
  background: var(--widget-color);
  border-radius: 0;
  transition: 0.3s box-shadow;
  position: relative;
}

.lar-card-wrapper {
  padding: 24px;
  border: 1px solid var(--light_gray);
}

.quote-preview .lar-card .horizontal-line,
.auction-dashboard-wrapper .auction-dashboard .lar-quotes .lar-card .horizontal-line {
  width: calc(100% + 48px);
  margin: 16px -24px 8px;
  height: 1px;
  background-color: #f5f5f5;
}

.quote-preview .lar-card .card-row,
.auction-dashboard-wrapper .auction-dashboard .lar-quotes .lar-card .card-row {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
}

.quote-preview .lar-card .card-row .key,
.quote-preview .lar-card .card-row .value,
.auction-dashboard-wrapper .auction-dashboard .lar-quotes .lar-card .card-row .key,
.auction-dashboard-wrapper .auction-dashboard .lar-quotes .lar-card .card-row .value {
  font-size: 14px;
  line-height: 22px;
}

.quote-preview .lar-card .card-row .key,
.auction-dashboard-wrapper .auction-dashboard .lar-quotes .lar-card .card-row .key {
  color: #8c8c8c;
}

.quote-preview .lar-card .card-row .value,
.auction-dashboard-wrapper .auction-dashboard .lar-quotes .lar-card .card-row .value {
  color: #0f172a;
  text-align: right;
}

.quote-preview .lar-card .bid-table-list__list-item-footer,
.auction-dashboard-wrapper .auction-dashboard .lar-quotes .lar-card .bid-table-list__list-item-footer {
  padding-top: 1em;
}

.auction-dashboard-wrapper
  .auction-dashboard
  .lar-quotes
  .lar-card
  .bid-table-list__list-item-footer
  .additional-field {
  cursor: pointer;
}

.quote-preview .lar-card .bid-table-list__list-item-footer > span,
.auction-dashboard-wrapper .auction-dashboard .lar-quotes .lar-card .bid-table-list__list-item-footer > span {
  display: flex;
  justify-content: center;
  width: 100%;
}

.auction-dashboard-wrapper .auction-dashboard .reject-quotes {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #1f1f1f;
  margin: 36px 0 32px;
}

.auction-dashboard-wrapper .auction-dashboard .reject-quotes b {
  cursor: pointer;
  transition: 0.3s color;
}

.auction-dashboard-wrapper .auction-dashboard .reject-quotes b:hover {
  color: var(--awesome_purple);
}

.auction-dashboard .faq-wrapper,
.auction-dashboard .terms-wrapper,
.auction-dashboard .contact-wrapper {
  background: #ffffff;
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--light_gray);
}

.auction-dashboard .contact-wrapper .contact-schedule,
.auction-dashboard .contact-wrapper .contact-sources .contact-source {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  transition: 0.3s box-shadow;
}

.auction-dashboard .contact-wrapper .contact-schedule:hover,
.auction-dashboard .contact-wrapper .contact-sources .contact-source:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
}

.auction-dashboard .contact-wrapper .contact-schedule {
  padding: 24px;
  display: flex;
}

.auction-dashboard .contact-wrapper .contact-schedule img {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.auction-dashboard .contact-wrapper .contact-schedule .schedule-column {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
}

.auction-dashboard .contact-wrapper .contact-schedule .schedule-column > span {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #0f172a;
  margin-bottom: 16px;
}

.auction-dashboard .contact-wrapper .contact-schedule .schedule-column button {
  width: max-content;
}

.auction-dashboard .contact-wrapper .contact-sources {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 28px;
}

.auction-dashboard .contact-wrapper .contact-sources .contact-source {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}

.auction-dashboard .contact-wrapper .contact-sources .contact-source .link {
  font-size: 16px;
  line-height: 24px;
  color: var(--main-blue-color);
  margin-top: 4px;
  cursor: pointer;
}

.auction-dashboard .contact-wrapper .contact-sources .contact-source span {
  font-size: 12px;
  line-height: 20px;
  color: #595959;
}

.auction-dashboard .contact-wrapper .contact-sources .contact-source img {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.auction-dashboard .faq-wrapper {
  margin-top: 24px;
}

.auction-dashboard .faq-wrapper .faq-list > * {
  margin-top: 24px;
}

.auction-dashboard .faq-wrapper .faq-list .faq-item {
  max-height: 42px;
  overflow: hidden;
  transition: 0.3s max-height;
}

.auction-dashboard .faq-wrapper .faq-list .faq-item.active-faq {
  max-height: 200px;
}

.auction-dashboard .faq-wrapper .faq-list .faq-item .faq-head span {
  font-size: 16px;
  line-height: 24px;
  color: #0f172a;
}

.auction-dashboard .faq-wrapper .faq-list .faq-item .faq-head {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  cursor: pointer;
}

.auction-dashboard .faq-wrapper .faq-list .faq-item .faq-head img {
  transition: 0.3s transform;
}

.auction-dashboard .faq-wrapper .faq-list .faq-item.active-faq .faq-head img {
  transform: rotate(180deg);
}

.terms-wrapper {
  margin-top: 24px;
}

.terms-wrapper .terms-list {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}

.terms-wrapper .terms-list > * + * {
  margin-top: 24px;
}

.terms-wrapper .terms-list .term-item {
  display: grid;
  grid-template-columns: 139px 1fr;
  grid-gap: 69px;
}

.terms-wrapper .terms-list .term-item .term-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1f1f1f;
}

.quote-preview .lar-card {
  width: 312px;
}

.confirm-quote .quote-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  background: #f0f0f0;
}
.confirm-quote .quote-preview.quote-preview_blue {
  background: var(--main-darkblue-color);
}

.confirm-quote .quote-title {
  padding: 24px 0;
  text-align: center;
}

.confirm-quote .ant-modal-content {
  border-radius: 8px;
}

.confirm-quote .quote-footer {
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.confirm-quote .quote-footer .agreement-created-at {
  margin-left: 24px;
  margin-top: 8px;
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
}

.reject-quotes.assign-new .reject-body {
  border: none;
}

.reject-quotes.assign-new .reject-body > .drop_down_wrapper + .drop_down_wrapper {
  margin-top: 24px;
}

.reject-quotes.assign-new .ant-modal-content {
  border-radius: 12px;
}

.reject-quotes .reject-title {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.reject-quotes .reject-title > span {
  margin-top: 8px;
}

.reject-quotes .reject-body {
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid #d9d9d9;
}

.reject-quotes .reject-body .actions-row {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
}

.reject-quotes .reject-body .attached-broker {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.reject-quotes .reject-body .attached-broker > img {
  border-radius: 99px;
  width: 72px;
  height: 72px;
}

.reject-quotes .reject-body .attached-info {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

.reject-quotes .reject-body .attached-info > * + * {
  margin-top: 4px;
}

.reject-quotes .reject-body .schedule-call {
  background: rgba(82, 62, 176, 0.05);
  border: 1px solid rgba(82, 62, 176, 0.32);
  box-sizing: border-box;
  border-radius: 24px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-blue-color);
  margin-top: 28px;
  cursor: pointer;
}

.reject-quotes .reject-body .schedule-call span {
  margin-right: 12px;
}

.confirm-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: calc(100vh - 56px);
}

.confirm-wrapper .confirm-quote {
  max-width: 380px;
}

.confirm-wrapper .confirm-quote .quote-preview {
  background-color: unset;
}

.confirm-wrapper .confirm-quote .quote {
  border: 4px #f0f0f0 solid;
}

.view-lender-comments {
  color: var(--main_blue_color);
  margin-top: 14px;
  cursor: pointer;
  border-top: 1px solid var(--white_smoke);
  padding-top: 14px;
}
