.root {
  display: flex;
  gap: 0.5em;
}

.iconOption {
  align-items: center;
  border: 0.1em solid var(--colour-neutral-grey-4);
  cursor: pointer;
  display: flex;
  height: 3em;
  justify-content: center;
  width: 3em;
}

.selectedIconOption {
  background-color: var(--colour-brand-1);
  border: 0.2em solid var(--colour-brand-4);
}
