.pdf-load-error {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  padding: 5em;
}

.pdf-load-error.add-new {
  justify-content: flex-start;
  margin-top: 48px;
  padding: 0 24px;
}

.pdf-load-error.add-new .add-new-doc > button.error {
  background-color: red;
}

.pdf-load-error > span {
  margin: 16px 0 24px;
}

.pdf-load-error > button {
  padding: 5px 16px;
}

.add-new-doc {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
}

.add-new-doc > button {
  width: max-content;
  margin: 0 auto;
}

.add-new-doc textarea {
  margin: 8px 0 16px;
}

.add-new-doc .add-new-form {
  margin-top: 26px;
}

.add-new-doc .add-new-form > button {
  margin-bottom: 32px;
}

.add-new-doc .add-new-form input,
.add-new-doc .add-new-form textarea {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 12px;
}

.add-new-doc .add-new-form input::placeholder,
.add-new-doc .add-new-form textarea::placeholder {
  font-size: 14px;
  line-height: 22px;
}

.add-new-doc .add-new-form > * {
  width: 100%;
}

.add-new-doc .document {
  text-align: center;
  margin-bottom: 24px;
}
