.root > :global(.document-view-content > b) {
  font-weight: 500;
}

.item {
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--colour-neutral-grey-6);
  display: grid;
  gap: 1em;
  grid-template-columns: 1fr 13em 13em 1.6em 1.6em;
  padding: 1em;
}

.item > :global(b) {
  font-weight: 700;
}

.item > :global(.btn_default) {
  width: 100%;
  border: none;
}

.item > :global(.btn_default > span) {
  align-items: center;
  display: flex;
  gap: 1em;
  justify-content: center;
}

.hint {
  display: flex;
  gap: 0.5em;
  justify-content: flex-end;
}

.actionButton {
  padding: 0;
  background-color: transparent;
  border: none;
}
