.happens-next {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 46px;
  background: #f5f5f5;
}

.happens-next .next-wrapper {
  width: 684px;
  padding: 32px 24px 24px;
  margin: 48px 0;
  background-color: white;
}

.happens-next .next-wrapper > img {
  width: calc(100% + 48px);
  margin: 32px -24px 0;
}

.happens-next .next-wrapper > span {
  margin-top: 32px;
  display: block;
}

.happens-next .next-wrapper .title {
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  margin-top: 16px;
  color: #1f2c5f;
  font-family: 'PosterGothicATF', sans-serif;
}

.happens-next .next-wrapper .subtitle {
  text-align: center;
  font-size: 16px;
  color: #0f172a;
}

.happens-next .next-wrapper button {
  margin: 48px auto 0;
  max-width: 488px;
  width: 100%;
}
