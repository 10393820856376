.header {
  grid-column: 1/3;
  height: 4em;
  display: flex;
  justify-content: space-between;
  padding: 0 calc(50vw - 40em);
}

.header > :global(:first-child) {
  height: inherit;
}

.header > :global(:last-child) {
  display: flex;
  gap: 1em;
  align-items: center;
}

.mobileHeader {
  grid-column: 1/3;
  display: flex;
  justify-content: space-between;
  padding: 0 1em;
  align-items: center;
}

.sidebar {
  position: fixed;
  height: 100vh;
  z-index: 1000;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1em;
  top: 4em;
  align-items: center;
  width: 0;
  overflow: hidden;
  transition-duration: 0.5s;
}

.activeSidebar {
  width: 100vw;
}
