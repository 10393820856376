.custom-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

/*.custom-collapse .ant-collapse-item:last-child > .ant-collapse-content {*/
/*    border-radius: 0 0 8px 8px;*/
/*}*/
.custom-collapse.ant-collapse {
  /*border-radius: 8px;*/
  border: 1px solid var(--main-divider-gray-color);
  border-bottom: none;
}

.custom-collapse.ant-collapse .ant-collapse-content {
  border-top: 1px solid var(--secondary-divider-gray-color);
}
.custom-collapse.ant-collapse > .ant-collapse-item:last-child,
.custom-collapse.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  /*border-radius: 8px;*/
}
.custom-collapse .ant-collapse-header {
  background-color: #ffffff;
}

/*.custom-collapse.ant-collapse > .ant-collapse-item:last-child{*/
/*    border-radius: 0 0 8px 8px;*/
/*}*/

.custom-collapse_with-list-children .ant-list-items {
  padding: 0 15px;
}
