.table :global(.ant-table-thead > tr > th) {
  font-size: 0.875rem;
  font-weight: 700;
  background-color: rgba(250, 250, 250, 1) !important;
}

.table :global(.ant-table-thead > tr > th),
.table :global(.ant-table-tbody > tr > td) {
  padding: 1rem !important;
}

.table :global(.ant-table > .ant-table-container > .ant-table-content > table),
.table :global(.ant-table > .ant-table-container > .ant-table-content > table > thead > tr > th) {
  border-color: rgba(0, 0, 0, 0.06) !important;
}
