.custom-list {
}

.custom-list.ant-list-split .ant-list-item {
  border-bottom: none;
}

.custom-list .ant-list-item-meta-title {
  margin-bottom: 0;
}
.custom-list.ant-list-bordered .ant-list-item {
  padding-right: 5px;
  padding-left: 17px;
}

.custom-list .ant-list-item-meta-description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #8c8c8c;
}
.custom-list .ant-list-item-meta-avatar {
  margin-right: 9px;
}

.custom-list .ant-list-item-action-split {
  display: none;
}
.custom-list.ant-list-bordered .ant-list-header {
  padding-right: 17px;
  padding-left: 17px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}
.custom-list .ant-list-item {
  padding: 7px 0;
}

.custom-list__view-more-btn {
  display: block;
  height: 36px;
  cursor: pointer;
  border: none;
  width: 100%;
  background: #f0f0f0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: var(--main-darkblue-color);
}

.custom-list__action-btn {
  border: 1px solid #242b5c;
  /*width: 128px;*/
  max-width: 300px;
  flex-wrap: nowrap;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #242b5c;
  transition: 0.2s ease-in-out all;
  color: #ffffff;
}

.custom-list .custom-list_bordered {
}

.custom-list__action-btn-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.custom-list__action-btn:hover,
.custom-list__action-btn:focus {
  background-color: #40a9ff;
  border-color: #40a9ff;
  color: #ffffff;
}
