.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1.5rem;
  position: relative;
  padding: 1.5rem;
  background: #fafafa;
}

.close {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
}

.inner {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
}

.replyIcon {
  color: var(--colour-brand-5);
}
