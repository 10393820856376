.root {
  --root-outer-margin: 1rem;

  border-bottom: 1px solid var(--colour-neutral-grey-5);
  display: grid;
  grid-column: 1/3;
  grid-template-columns: repeat(4, 1fr);
  padding: var(--root-outer-margin) 0;
}

.root > :global(.ant-breadcrumb) {
  margin-left: var(--root-outer-margin);
}

.root > :global(h1) {
  font-size: 1.75em;
  font-weight: 600;
  grid-column: 1/3;
  margin-left: var(--root-outer-margin);
}

.buttonsBar {
  align-items: center;
  display: grid;
  gap: 0.5em;
  grid-column: 1;
  grid-template-columns: auto auto 1fr;
  justify-content: space-between;
  margin-left: var(--root-outer-margin);
  padding-right: var(--root-outer-margin);
  width: 100%;
}

.buttonsBar > :global(:first-child > span) {
  margin-right: 1em;
}

.buttonsBar :global(button) {
  border-radius: 0;
  height: 3em;
  padding: 0;
  width: 3em;
}

.buttonsBar :global(button > svg) {
  vertical-align: middle;
}

.buttonsBar > div {
  display: flex;
  align-items: center;
}

.buttonsBar > button {
  height: calc(3em + 2px);
  justify-self: flex-end;
  width: calc(3em + 2px);
}

.sortingBox {
  background-color: var(--colour-neutral-grey-5);
  padding: 1px;
  gap: 1px;
  display: flex;
}

.sortingBox > :global(button) {
  border: none;
}

.deadlines {
  display: flex;
  grid-column: 3/5;
  grid-row: 1/3;
  justify-self: flex-end;
  margin-right: var(--root-outer-margin);
}

.deadlines > :global(div) {
  border: 1px solid var(--colour-neutral-grey-5);
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.deadlines > :global(div:first-child) {
  border: 2px solid var(--colour-red-5);
}

.deadlines :global(label) {
  font-size: 1.2em;
  grid-column: 1/3;
}

.deadlines :global(big) {
  font-size: 2em;
  font-weight: 500;
}

.deadlines :global(sup) {
  color: var(--colour-neutral-grey-7);
  font-size: 0.5em;
  font-weight: 400;
}

.brokerScenariosButtons {
  grid-column: 3/5;
  justify-self: flex-end;
  margin-right: 1rem;
  gap: 0.5em;
  display: flex;
}

.brokerScenariosButtons > :global(*) {
  align-items: center;
  display: flex;
  gap: 0.5em;
  height: 3em;
}
