.user-preview-wrapper {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.user-preview-wrapper > * + * {
  margin-left: 16px;
}

.user-preview-wrapper button {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--main-blue-color);
  background: none;
  border: none;
  cursor: pointer;
}

.id-column > img {
  margin-left: 4px;
  opacity: 0;
  transition: 0.3s opacity;
}

.ant-table tbody tr:hover .id-column > img {
  opacity: 1;
}

.ant-table .status-tag {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.ant-table .status-tag > .color-indicator {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  margin-right: 8px;
}

.section_table_wrapper .table-header-wrapper {
  padding: 10px 25px;
  width: 100%;
  border-bottom: 1px solid #cbd5e1;
}

.drawer-header {
  padding: 28px 24px;
  display: flex;
  align-items: center;
  background: #fafafa;
  border-bottom: 1px solid #d9d9d9;
}

.drawer-header img {
  cursor: pointer;
}

.drawer-header span {
  margin-left: 28px;
}

.drawer-wrapper.notes .drawer-header span {
  margin-left: 18px;
}

.drawer-user {
  padding: 24px;
}

.drawer-user .avatar,
.drawer-user .avatar-image {
  width: 82px;
  height: 82px;
  cursor: default;
  font-size: 40px;
  user-select: none;
}

.drawer-user .user-top {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 16px;
}

.drawer-user .user-top-data {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 12px;
}

.drawer-user .user-top-data .description-12 {
  margin-top: 8px;
  display: block;
}

.drawer-user .user-top-data .timezone-wrapper {
  display: flex;
  align-items: center;
  height: max-content;
}

.drawer-user .user-top-data .timezone-wrapper span {
  margin-left: 8px;
}

.role-tag {
  font-size: 12px;
  line-height: 20px;
  border-radius: 2px;
  padding: 1px 8px;
  display: block;
  width: max-content;
  margin-top: 8px;
}

.role-tag,
.role-tag.PLA {
  color: #6c5ce7;
  background: rgba(215, 211, 248, 0.35);
  border: 1px solid #afa6f2;
}

.role-tag.Broker {
  color: #faad14;
  background: #fffbe6;
  border: 1px solid #fffbe6;
}

.role-tag.Lender {
  color: #52c41a;
  background: #f6ffed;
  border: 1px solid #73d13d;
}

.drawer-user .user-bottom {
  margin-top: 34px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.drawer-user .user-bottom button {
  width: 100%;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  padding: 4px;
}

.drawer-deals {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 24px;
  background: var(--main-darkblue-color);
}

.drawer-deals .description-12 {
  color: white;
}

.drawer-body {
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.drawer-body.company {
  border-top: 1px solid #d9d9d9;
}

.drawer-body.company .company-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #1f1f1f;
}

.drawer-wrapper {
  display: grid;
  grid-template-rows: max-content max-content 1fr;
  height: max-content;
}

.drawer-scroll {
  overflow: auto;
}

.drawer-body.company .unique_data {
  margin-bottom: 8px;
}

.drawer-body.company .company-row {
  margin-top: 18px;
  margin-bottom: 36px;
}

.drawer-body.company .company-row img {
  width: 48px;
  height: 48px;
  border-radius: 100px;
  object-fit: cover;
}

.drawer-body.company .company-name {
  font-size: 16px;
  line-height: 24px;
  color: #434343;
  margin-left: 16px;
}

.drawer-body div.wide {
  grid-column: 1/-1;
}

.drawer-body .unique_data {
  display: flex;
  flex-direction: column;
}

.drawer-body .unique_data .label {
  font-size: 11px;
  line-height: 16px;
  color: #8c8c8c;
}

.drawer-body .unique_data .value {
  font-size: 16px;
  line-height: 24px;
  color: #434343;
}
