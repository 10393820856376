.wrapper_reminders {
  padding-top: 20px;
}
.wrapper_reminders .section_title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #0f172a;
  margin-bottom: 16px;
}
.wrapper_reminders .section_title_desc {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}

.wrapper_reminders .reminder__desc {
  margin: 0 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #8c8c8c;
}
.wrapper_reminders .reminder_title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #0f172a;
}
