.root {
  display: grid;
}

.header {
  align-items: center;
  background-color: #377ecb;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 2fr;
  user-select: none;
}

.subItemHeader {
  background-color: #e8f5ff;
}

.title {
  align-items: center;
  color: #fff;
  display: flex;
  font-weight: 600;
  gap: 0.5em;
  padding-left: 0.5em;
}

.subItemHeader .title {
  color: #377ecb;
}

.iconWrapper {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 2em;
  justify-content: center;
  width: 2em;
}

.icon {
  color: var(--main_blue_color);
}

.collapsed {
  display: none;
}

.additionalHeaderInfo {
  background-color: #fff;
  border-bottom: 1px solid var(--colour-neutral-grey-5);
  border-right: 1px solid var(--colour-neutral-grey-5);
  height: 100%;
  padding: 0.5em;
  text-align: right;
}
