.root {
  display: grid;
  grid-gap: 0.25rem;
  cursor: pointer;
  padding: 0.875rem 0.625rem;
  min-height: var(--nav-item-height);
  color: var(--colour-neutral-grey-11);
  background-color: #fff;
  border-bottom: 1px solid var(--colour-neutral-grey-5);
  transition: background-color 300ms;
}

.root:hover,
.active {
  color: var(--colour-brand-9);
  background-color: #bae7ff;
  border-color: #bae7ff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 0.875rem;
  min-width: 0;
}

.root .title {
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}

.date {
  font-size: 0.75rem;
  white-space: nowrap;
  line-height: 1;
}

.root :global(.ant-badge-dot) {
  box-shadow: none;
  height: 0.75em;
  top: auto;
  bottom: 0;
  width: 0.75em;
}
