.root {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 50vh;
  width: 100%;
}

.wrapper {
  align-items: center;
  background: #fff;
  border-radius: 8;
  border: 1px solid #cbd5e1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 384px;
  min-height: 200px;
  padding: 15px;
  width: 100%;
}

.label {
  color: #1f1f1f;
  font-size: 1.1em;
  font-weight: 600;
  margin-top: 20px;
}

.icon {
  color: #877aeb;
}

.btn {
  padding: 8px 23px;
}

.text {
  color: '#1f1f1f';
  font-size: 24px;
  font-weight: 600;
}
