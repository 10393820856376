.tooltip {
  gap: 8px;
  display: flex;
  font-size: 14px;
  line-height: 22px;
  color: var(--black);
  background-color: transparent;
  border: 1px solid transparent;
  padding: 8px;
}

.imageWrapper {
  max-width: 24px;
  max-height: 24px;
}

.tooltip.info {
  background-color: var(--dr_white);
  border-color: var(--colour-neutral-grey-4);
}

.tooltip.success {
  background-color: var(--colour-green-1);
  border-color: var(--colour-green-6);
}

.tooltip.accent {
  background-color: var(--colour-brand-1);
  border-color: var(--colour-brand-5);
}

.tooltip.warn {
  background-color: transparent;
  border-color: #faad14;
}

.small {
  font-size: 12px;
  line-height: 14px;
}

.large {
  padding: 17px 19px;
}
