.custom-drawer {
}
.custom-drawer.custom-drawer_default {
}
.custom-drawer.custom-drawer_default .custom-drawer__header {
  padding: 15px 24px;
  border-bottom: 1px solid var(--light_gray);
}
.custom-drawer.custom-drawer_default .custom-drawer__body {
  padding: 24px;
}
.custom-drawer.custom-drawer_default .custom-drawer__header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.custom-drawer.custom-drawer_default .custom-drawer__header-row:nth-child(2n) {
  margin-top: 24px;
}
.custom-drawer.custom-drawer_default .custom-drawer__header-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #1f1f1f;
}
