.ant-collapse {
  border: none;
  background-color: transparent;
}

.ant-collapse > .ant-collapse-item {
  border: none;
}

.ant-collapse-content {
  border: none;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  display: flex;
  margin-left: 0;
  width: 100%;
}

.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  align-items: center;
  background-color: #ffffff;
  min-height: 58px;
  padding-right: 0;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  font-weight: 600;
  margin-right: 16px;
}

.accordion-title-line {
  width: 100%;
  border-top: 1px dashed #bfbfbf;
  margin: auto 0;
}
