.root :global(.ant-modal-body) {
  --border-radius-standard: 0.2em;
  --border-standard: 1px solid #d9d9d9;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}

.recipientsWrapper {
  background-color: #fafafa;
  border-radius: var(--border-radius-standard);
  border: var(--border-standard);
  grid-row: 1/3;
  padding: 1em;
}

.recipientsWrapper > :global(label) {
  margin-bottom: 1em;
  display: block;
}

.suggestedRecipients {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.suggestedRecipients > :global(div) {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.suggestedRecipients > :global(div > div) {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}

.selectedRecipients {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}

.selectedRecipients > :global(.ant-tag) {
  align-items: center;
  background-color: #91d5ff;
  color: #242b5c;
  cursor: pointer;
  display: flex;
  gap: 0.5em;
  margin: 0;
  padding: 0.25em 0.5em;
}

.templateWrapper,
.messageWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.templateWrapper > :global(div) {
  background-color: #f5f5f5;
  border-radius: var(--border-radius-standard);
  border: var(--border-standard);
  padding: 1em;
}

.templateWrapper > :global(div p) {
  margin: 1em 0;
}

.messageWrapper :global(.ant-input) {
  border-radius: var(--border-radius-standard);
}

.root :global(.ant-modal-footer) {
  display: flex;
  gap: 1em;
  justify-content: flex-end;
}

.error {
  color: red;
}
