.diligence-document-view .diligence-content-wrapper .diligence-content .ant-tabs-tab {
  padding: 18px 0 19px;
}

.diligence-document-view .diligence-content-wrapper .diligence-content {
  border-left: 1px solid var(--colour-neutral-grey-4);
}

.diligence-document-view .diligence-content-wrapper .diligence-content .actions {
  display: flex;
  margin-right: 24px;
}

.diligence-document-view .diligence-content-wrapper .diligence-content .actions > * + * {
  margin-left: 12px;
}

.diligence-document-view .diligence-content-wrapper .diligence-content .actions button {
  padding: 8px 16px;
}

.diligence-document-view .diligence-content-wrapper .diligence-content .actions button > span {
  height: 24px;
}

.diligence-document-view .diligence-content-wrapper .diligence-content .description .description-actions {
  position: absolute;
  top: 1em;
  gap: 0.5em;
  right: 0;
  cursor: pointer;
  display: flex;
}

.diligence-document-view .diligence-content-wrapper .diligence-content .description {
  border-bottom: 1px solid var(--colour-neutral-grey-4);
  display: flex;
  flex-direction: column;
  margin: 0 1em;
  padding: 1em 0;
  position: relative;
  color: var(--colour-neutral-grey-11);
}

.diligence-document-view .diligence-content-wrapper .diligence-content .description b {
  color: var(--colour-neutral-grey-10);
  margin-bottom: 1em;
}

.diligence-document-view .diligence-content-wrapper .diligence-content .description > textarea,
.diligence-document-view .diligence-content-wrapper .diligence-content .description > span {
  margin-top: 8px;
}

.diligence-document-view .diligence-content-wrapper .diligence-content .key-fields-wrapper {
  padding: 24px 20px;
}

.diligence-document-view .diligence-content-wrapper .diligence-content .key-fields-wrapper .key-fields {
  margin-top: 16px;
}

.diligence-document-view .diligence-content-wrapper .diligence-content .key-fields-wrapper .key-fields > * + * {
  margin-top: 36px;
}

.diligence-document-view
  .diligence-content-wrapper
  .diligence-content
  .key-fields-wrapper
  .key-fields
  .key-field
  .grey {
  color: #8c8c8c;
}

.diligence-document-view
  .diligence-content-wrapper
  .diligence-content
  .key-fields-wrapper
  .key-fields
  .key-field
  > .grey {
  margin: 8px 0;
  display: block;
}

.diligence-document-view
  .diligence-content-wrapper
  .diligence-content
  .key-fields-wrapper
  .key-fields
  .key-field
  > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.diligence-document-view
  .diligence-content-wrapper
  .diligence-content
  .key-fields-wrapper
  .key-fields
  .key-field
  .value-wrapper
  svg {
  cursor: pointer;
}

.diligence-document-view
  .diligence-content-wrapper
  .diligence-content
  .key-fields-wrapper
  .key-fields
  .key-field
  .value-wrapper
  form {
  width: 100%;
}

.diligence-document-view
  .diligence-content-wrapper
  .diligence-content
  .key-fields-wrapper
  .key-fields
  .key-field
  .value-wrapper
  form,
.diligence-document-view .diligence-header form {
  display: flex;
}

.diligence-document-view .diligence-header button,
.diligence-document-view .diligence-content-wrapper .diligence-content .description .description-actions button,
.diligence-document-view
  .diligence-content-wrapper
  .diligence-content
  .key-fields-wrapper
  .key-fields
  .key-field
  .value-wrapper
  button {
  font-size: 14px;
  line-height: 22px;
  padding: 4px 12px;
}

.diligence-document-view
  .diligence-content-wrapper
  .diligence-content
  .key-fields-wrapper
  .key-fields
  .key-field
  .value-wrapper.edit
  button {
  margin-left: 8px;
}

.diligence-document-view
  .diligence-content-wrapper
  .diligence-content
  .key-fields-wrapper
  .key-fields
  .key-field
  .value-wrapper.edit
  input {
  margin-right: 8px;
}

.diligence-document-view .diligence-content-wrapper .diligence-content .dropdown-wrapper {
  background: #fafafa;
}

.diligence-document-view .diligence-content-wrapper .diligence-content .dropdown-wrapper .dropdown-header {
  padding: 16px 18px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.diligence-document-view .diligence-content-wrapper .diligence-content .dropdown-wrapper .dropdown-header > b {
  color: #f00;
}

.diligence-document-view .diligence-content-wrapper .diligence-content .dropdown-wrapper .dropdown-header > img {
  transition: 0.3s transform;
}

.diligence-document-view
  .diligence-content-wrapper
  .diligence-content
  .dropdown-wrapper.active-dropdown
  .dropdown-header
  > img {
  transform: rotate(180deg);
}

.diligence-document-view .diligence-content-wrapper .diligence-content .dropdown-wrapper .dropdown-body {
  max-height: 0;
  padding: 0 18px;
  transition: 0.3s;
  overflow: hidden;
}

.diligence-document-view
  .diligence-content-wrapper
  .diligence-content
  .dropdown-wrapper
  .dropdown-body
  .expand-row
  > span {
  margin-left: 8px;
}

.diligence-document-view
  .diligence-content-wrapper
  .diligence-content
  .dropdown-wrapper.active-dropdown
  .dropdown-body {
  max-height: 500px;
  padding: 0 18px 16px;
}
