.iharmoni_documents__disclosures {
  position: relative;
  height: 100%;
  border-left: 1px solid #cbd5e1;
}

.iharmoni_documents__disclosures .head {
  height: 158px;
  padding: 22px;
  display: grid;
  grid-gap: 8px;
}

.iharmoni_documents__disclosures .head .title {
  font-size: 24px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: var(--nero_black);
}

.iharmoni_documents__disclosures .head .file_desc {
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #475569;
}

.iharmoni_documents__disclosures .head .wrapper_upload_document {
  justify-self: start;
  margin-top: 8px;
  display: grid;
  align-items: center;
  grid-gap: 1rem;
  grid-template-columns: max-content;
}

.iharmoni_documents__disclosures .head .options {
  position: absolute;
  right: 24px;
  top: 24px;
}

.iharmoni_documents__disclosures .body {
  background: var(--gray_pale_blue);
  padding: 32px;
  height: inherit;
  overflow-y: auto;
}

.iharmoni_documents__disclosures .body img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.iharmoni_documents__disclosures .body .wrapper_png_viewer .png_viewer canvas {
  width: 100% !important;
  height: 100% !important;
  margin: 0 auto;
  object-fit: contain;
}

.iharmoni_documents__disclosures .body .wrapper_png_viewer .wrapper_btn {
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.iharmoni_documents__disclosures .body .wrapper_png_viewer .wrapper_btn svg {
  cursor: pointer;
}

.iharmoni_documents__disclosures .body .wrapper_png_viewer .wrapper_btn span {
  line-height: 24px;
}

.iharmoni_documents__disclosures .body .wrapper_png_viewer .wrapper_btn .prev svg {
  transform: rotateY(180deg);
}
