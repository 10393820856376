.actionsList {
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 16px;
  display: flex;
}

.select {
  width: 100%;
}

.chatIndicator {
  margin: 0;
}

.chatIndicatorActive {
  background-color: transparent;
}
