.root {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: flex-end;
}

.attachmentWrapper {
  position: relative;
}

.attachmentInfo {
  background-color: #1f1f1f66;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  padding: 4px;
  font-size: 11px;
  font-weight: 600;
  color: var(--colour-neutral-grey-1);
  height: 0;
  opacity: 0;
  bottom: -100%;
  transition: 0.2s opacity;
}

.infoIconWrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.infoIconWrapper:hover .attachmentInfo {
  bottom: 40px;
  opacity: 1;
  height: auto;
  z-index: 1;
}

.uploadButton,
.attachment {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 11.5em;
  justify-content: center;
  position: relative;
  width: 11.5em;
}

.attachment {
  background-color: var(--colour-neutral-grey-2);
}

.uploadButton {
  border: 1px dashed var(--colour-brand-5);
}

.attachment > :global(img) {
  height: inherit;
  object-fit: cover;
  width: inherit;
}

.attachmentActionsBar {
  background-color: #1f1f1f66;
  height: 40px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.attachmentDoc {
  padding-bottom: 40px;
}
