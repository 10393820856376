.auctionSection {
  padding-top: 20px;
  background-color: #fafafa;
}

.auctionInner {
  padding-left: 24px;
  padding-right: 24px;
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}

.auctionInnerWrapper {
  margin-top: 16px;
}

.auctionHeader {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.auctionTitle {
  font-size: 24px;
  line-height: 32px;
  color: #262626;
  font-weight: 700;
  margin-bottom: 0;
}

.auctionButtons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.tabs > :global(.ant-tabs-nav) {
  margin: 0;
}

.tabs:not(:global(.custom-tabs_left)) > :global(.ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list) {
  padding: 0 2em;
}

.timelineWrapper {
  padding: 16px;
  border: 1px solid var(--colour-neutral-grey-6);
}
