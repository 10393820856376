.root {
  align-items: center;
  display: flex;
  gap: 1em;
}

.root > :global(form),
.titleWrapper {
  align-items: center;
  display: flex;
  gap: 0.5em;
}

.root > :global(form > button) {
  height: 2em;
  padding: 0.2em 1em;
}

.statusWrapper {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.statusBadge {
  align-items: center;
  color: #fff;
  display: flex;
  gap: 0.5em;
  height: 2em;
  padding: 0.25em 0.5em;
}

.redStatusBadge {
  background: #ff4d4f;
}

.purpleStatusBadge {
  background: #5d5fef;
}

.blueStatusBadge {
  background: #40a9ff;
}

.yellowStatusBadge {
  background: #faad14;
}

.greenStatusBadge {
  background: #389e0d;
}

.actionButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
