.wrapper {
  margin-top: 5px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}

.wrapper :global(.ant-badge-status) {
  line-height: normal;
}

.hasIndicator {
  background-color: var(--colour-brand-5);
}
