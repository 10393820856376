.root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
}

.chartsWrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
