.root {
  --root-outer-margin: 1rem;

  display: grid;
  grid-column: 1/3;
  grid-template-columns: repeat(4, 1fr);
  padding: var(--root-outer-margin) 0;
  padding-right: 18px;
  border-bottom: 1px solid var(--colour-neutral-grey-5);
}

.root > :global(.ant-breadcrumb) {
  margin-left: var(--root-outer-margin);
}

.title {
  font-size: 1.75rem;
  font-weight: 600;
  grid-column: 1/3;
  margin-left: var(--root-outer-margin);
}

.buttonsBar {
  display: grid;
  align-items: center;
  grid-gap: 0.5em;
  grid-column: 1;
  grid-template-columns: auto auto 1fr;
  justify-content: space-between;
  margin-left: var(--root-outer-margin);
  padding-right: var(--root-outer-margin);
  width: 100%;
}

.buttonsBar :global(button) {
}

.buttonsBar > div {
  display: flex;
  align-items: center;
}

.buttonsBar > button {
  justify-self: flex-end;
}

.sortingBox {
  display: flex;
  background-color: var(--colour-neutral-grey-5);
}
