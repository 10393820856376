.root {
  max-width: 40em;
  margin-top: 1em;
}

.main {
  background-color: #fff;
  border: 1px solid var(--colour-neutral-grey-5);
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.description {
  background-color: var(--colour-neutral-grey-3);
  display: grid;
  gap: 1em;
  grid-template-columns: 1.6em 1fr;
  padding: 1em;
}

.description > :global(p) {
  margin: 0;
  text-align: justify;
}

.main > :global(form) {
  display: flex;
  flex-direction: column;
}

.main > :global(form > .ant-row:first-child) {
  align-items: baseline;
  display: flex;
  flex-direction: row-reverse;
  gap: 1em;
  justify-content: flex-end;
}

.main > :global(form > .ant-row:first-child > .ant-form-item-control) {
  flex-grow: unset;
}

.main > :global(form > button) {
  width: 30%;
  align-self: flex-end;
}
