.title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 16px;
}

.box {
  padding: 1.5rem;
  background-color: #ffffff;
  border: 1px solid var(--colour-neutral-grey-5);
}

.subtitle {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
}

.contactSection {
  width: 100%;
}

.contactHeader {
  border: 1px solid var(--light_gray);
  padding: 8px 12px;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark_gray);
  font-weight: 600;
  background-color: var(--colour-neutral-grey-4);
}

.contactBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--light_gray);
  padding: 16px;
  background-color: var(--widget-color);
}

.contactBodyLeft {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  color: var(--sea_weed);
}

.contactBodyLeft a {
  font-size: 14px;
  line-height: 22px;
  color: var(--sea_weed);
}

.contactPhoto {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 16px;
}

.contactName,
.contactPhone {
  margin-bottom: 0;
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid var(--colour-neutral-grey-6);
}

.contactBodyRight {
  display: flex;
  align-items: center;
  gap: 16px;
}

.contactBodyRight button {
  width: 115px;
  min-width: max-content;
}

.contactFooter {
  border: 1px solid var(--light_gray);
  padding: 8px 12px;
  background-color: var(--widget-color);
}

.contactFooter span {
  font-weight: 700;
}

.barChartSection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin-bottom: 32px;
  gap: 16px;
}

.barChartWrapper {
  background-color: var(--widget-color);
}

.tableSection {
  width: 100%;
}
