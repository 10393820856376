.wrapper_mandates {
  padding-top: 10px;
}

.wrapper_mandates .active_list {
  border-bottom: 1px solid #cbd5e1;
  padding-bottom: 24px;
}

.wrapper_mandates .section_title {
  font-weight: 500;
  font-size: 14px;
  padding-top: 16px;
  line-height: 22px;
  color: #1f1f1f;
}

.wrapper_mandates .add_new_reminder {
}

.wrapper_mandates .reminders_list {
  display: grid;
  grid-template-columns: 1fr max-content 1fr 1fr max-content;
  grid-gap: 20px;
}

.wrapper_mandates .add_new_reminder__form {
  display: grid;
  grid-template-columns: 200px max-content 200px 120px;
  grid-gap: 16px;
}

.wrapper_mandates .every {
  margin-top: 12px;
  display: grid;
  grid-gap: 16px;
}

.wrapper_mandates .every .add_new_reminder__form {
  grid-template-columns: 200px max-content 200px 120px max-content;
}

.wrapper_mandates .add_new_reminder__form .bordered .ant-dropdown-trigger {
  padding: 3px 12px;
}

.wrapper_mandates .every .add_new_reminder__form > svg {
  align-self: end;
  margin-bottom: 4px;
  cursor: pointer;
}

.wrapper_mandates .add_new_reminder__form .split {
  align-self: end;
  padding-bottom: 8px;
}

.wrapper_mandates .add_new_reminder .submit {
  margin-top: 24px;
}
