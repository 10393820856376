.root {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.grid {
  display: grid;
  grid-template-columns: 25em auto;
}

.grid > :global(*) {
  border-bottom: 1px solid var(--colour-neutral-grey-4);
  padding: 1em;
}
