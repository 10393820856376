.loan-packages-overview .loan-package-item {
  width: 100%;
  border: 1px solid #cbd5e1;
}
.loan-packages-overview.ant-list-split .ant-list-item {
  border-bottom: none;
}
.loan-package-item__data {
  padding: 24px 24px 9px 24px;
  min-height: 115px;
}
.loan-packages-overview .loan-package-item .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
}
