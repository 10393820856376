.iconsBox {
  align-items: center;
  background-color: var(--colour-neutral-grey-4);
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 1rem;
  gap: 0.5rem;
}

.blueIconCircle,
.greenIconCircle {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 4rem;
  justify-content: center;
  width: 4rem;
}

.blueIconCircle {
  background-color: var(--colour-brand-5);
}

.greenIconCircle {
  background-color: var(--colour-green-5);
}
