.auction-auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 64px);
  background: #fdfdff;
}

.auction-auth > span {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}

.auction-auth .auth-wrapper {
  margin-top: 32px;
  width: 448px;
  background: #ffffff;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  padding: 24px 52px;
}

.auction-auth .auth-wrapper .inputs {
  margin-top: 46px;
}

.auction-auth .auth-wrapper .inputs > * + * {
  margin-top: 24px;
}

.auction-auth .auth-wrapper > span {
  margin-top: 24px;
  display: block;
}

.auction-auth .auth-wrapper button {
  margin-top: 24px;
}
