.root {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 15em 15em;
}

.root > :global(button) {
  width: 100%;
}

.root > :global(button svg) {
  vertical-align: bottom;
}

.root > :global(.ant-table-wrapper) {
  grid-column: 1/4;
}

.root > :global(.ant-select) {
  height: fit-content;
}

.root :global(td:last-child button) {
  background-color: transparent;
  border: none;
  cursor: pointer;
  float: right;
}

.status {
  grid-column: 3/4;
}
