.drawerWrapper {
  overflow: hidden;
}

.drawer p {
  margin: 0;
}

.mainInfo {
  margin-bottom: 18px;
  display: flex;
}

.mainInfoImage {
  margin-right: 12px;
}

.mainInfoText {
  display: flex;
  flex-direction: column;
}

.mainInfoName {
  font-weight: 700;
  margin-bottom: 5px;
}

.mainInfoRole {
  margin-bottom: 9px;
  padding: 1px 8px;
  color: var(--awesome_purple);
  background-color: rgba(215, 211, 248, 0.35);
  border: 1px solid var(--light_purple);
  width: max-content;
  border-radius: 2px;
}

.mainInfoLastOnline {
  font-size: 12px;
  line-height: 20px;
  color: var(--colour-neutral-grey-7);
}

.mainInfoCompany {
  font-size: 14px;
  line-height: 22px;
}

.deals {
  background-color: var(--main_dark_blue_color);
  padding: 8px;
  color: var(--white_smoke);
  margin-bottom: 16px;
}

.mortgageOfficer {
  padding: 8px 12px;
  border: 1px solid var(--light_gray);
  margin-bottom: 16px;
}

.mortgageOfficerTitle {
  font-size: 12px;
  line-height: 20px;
  color: var(--colour-neutral-grey-7);
}

.contacts {
  border-top: 1px solid var(--light_gray);
  padding: 16px 0;
}

.workedWith {
  border-top: 1px solid var(--light_gray);
  padding: 16px 0;
}

.item {
  margin-bottom: 16px;
}

.item:last-child {
  margin-bottom: 0;
}

.itemRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  color: var(--colour-neutral-grey-7);
  margin-bottom: 4px;
}

.drawerWrapper {
  overflow: auto;
}

.drawerHeader {
  background-color: #fafafa;
  border-bottom: 1px solid #d9d9d9;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex: 1;
}

.headerTitle {
  font-size: 16px;
  font-weight: 600;
  font-family: Inter;
  position: absolute;
  right: 50%;
  transform: translate(50%, 0);
}

.section {
  padding: 16px;
  border-bottom: 1px solid #d9d9d9;
}

.drawerCompanyInfo {
  display: flex;
  flex-direction: row;
}

.drawerCompanyDetails {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.companyDetaisTitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--colour-neutral-grey-11);
}
.companyDetaisSubTitle {
  line-height: 22px;
  color: var(--colour-neutral-grey-8);
}

.divider {
  margin: 16px 0;
  height: 1px;
  background-color: var(--colour-neutral-grey-4);
}

.titleUpperCase {
  font-family: Inter;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  color: var(--colour-neutral-grey-7);
  margin-bottom: 8px !important;
}

.drawerCompanyInfo {
  display: flex;
  flex-direction: row;
}

.companyAdminTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px !important;
}

.customBtn {
  padding: 0px 7px;
  margin: 0;
  font-size: 14px;
}

.companyDetailsRowContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.companyDetailsRowTitle {
  line-height: 22px;
}

.companyDetailsRowValue {
  line-height: 22px;
  color: var(--colour-neutral-grey-8);
}

.buttonEdit {
  height: 24px;
  cursor: pointer;
}

.buttonEditOn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 24px;
}

.buttonEditOnTitle {
  line-height: 24px;
  margin-right: 8px;
  color: #40a9ff;
}

.buttonEditOnIcon {
  color: #40a9ff;
  font-size: 20px;
}

.avatarWrapper {
  grid-column: 1/4;
  margin-bottom: 1em;
}

.avatarWrapper :global(.ant-badge) {
  cursor: pointer;
}

.avatarWrapper svg {
  background-color: #40a9ff;
  border-radius: 50%;
  bottom: -1em !important;
  height: 2em;
  padding: 0.25em;
  right: 0.5em !important;
  top: auto !important;
  width: 2em;
}

.usernameTitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.subTitle {
  font-size: 14px;
  line-height: 22px;
  color: var(--colour-neutral-grey-8);
}

.containerButton {
  padding: 16px;
}
