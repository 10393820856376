.document-view {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  position: relative;
}

.document-view.empty .document-view-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
}

.document-view .document-entry-block,
.document-view .add-document-entry-block {
  background-color: var(--colour-neutral-grey-1);
  border: 1px solid var(--colour-neutral-grey-6);
  padding: 1em 1.5em;
}

.document-view .document-entry-block {
  display: grid;
  grid-template-columns: 1fr 8em;
}

.document-view .document-entry-block b {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.document-view .document-entry-block.edit-mode {
  display: flex;
  gap: 1em;
}

.document-view .document-entry-block .ant-spin {
  align-self: end;
}

.document-view .add-document-entry-block {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.document-view .document-entry-block .button-block {
  display: flex;
  justify-content: right;
  grid-gap: 1em;
}

.document-view .document-entry-block .button-block button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.document-view .document-entry-block .document-desc {
  grid-column: 1/3;
}

.document-entry-block.edit-mode .ant-spin-container {
  display: flex;
  gap: 1em;
}

.document-view .document-view-content {
  display: flex;
  flex-direction: column;
  gap: 1em;
  height: 100%;
  overflow-y: auto;
  padding: 1.5em 1em;
}

.document-view .document-view-content .question-list {
  display: grid;
  gap: 1em;
  margin-top: 0.5em;
}

.document-view .document-view-content .question-list > b {
  font-size: 1.25em;
}

.document-view .document-view-content .question-list .question-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.document-view .document-view-content .question-list input {
  padding: 0.5em 1em;
}

.document-view .document-view-content .question-list .actions {
  display: flex;
  gap: 1em;
  justify-content: flex-end;
  margin-top: 1em;
}

.bid-table-list__list-item-header-amount > b {
  font-size: 0.8em;
}

.diligence-content-wrapper .reject-diligence {
  padding: 24px;
  max-height: 600px;
  overflow: auto;
}

.diligence-content-wrapper .reject-diligence strong {
  margin: 24px 0 6px;
  display: block;
}

.diligence-content-wrapper .reject-diligence .ant-select-selection-placeholder {
  font-size: 14px;
}

.diligence-content-wrapper .reject-diligence > textarea {
  margin-top: 16px;
}

.diligence-content-wrapper .reject-diligence .question-row {
  display: grid;
  grid-template-columns: 1fr 24px;
  grid-gap: 12px;
  margin-top: 10px;
}

.diligence-content-wrapper .reject-diligence .questions-list {
  margin-top: 10px;
  border-top: 1px solid var(--colour-neutral-grey-4);
}

.diligence-content-wrapper .reject-diligence .questions-list > textarea {
  margin-top: 10px;
}

.diligence-content-wrapper .reject-diligence .question-row .anticon {
  height: max-content;
}

.diligence-content-wrapper .reject-diligence .questions-list > button {
  background: #6c5ce7;
  border-radius: 24px;
  border: none;
  color: var(--colour-neutral-grey-1);
  cursor: pointer;
  margin-top: 12px;
  outline: 0;
  padding: 7px 12px;
}

.diligence-content-wrapper .reject-diligence .ant-checkbox-wrapper {
  margin-top: 16px;
}

.diligence-content-wrapper .reject-diligence .actions {
  margin: 24px 0 12px;
}

.document-entry-block.with-divider {
  margin-bottom: 1em;
  position: relative;
}

.document-entry-block.with-divider::after {
  background-color: var(--colour-neutral-grey-6);
  bottom: -1rem;
  content: '';
  height: 1px;
  position: absolute;
  width: 100%;
}

.diligence-document-view .document-view .wrapper_btn .prev {
  left: 12px;
}

.diligence-document-view .document-view .wrapper_btn .next {
  right: 12px;
}

.diligence-document-view .document-view .wrapper_btn > div {
  cursor: pointer;
}

.diligence-document-view .document-view .wrapper_btn .prev {
  transform: rotateY(180deg);
}

.diligence-document-view .document-view .wrapper_btn > span {
  margin: 43px 24px;
}

.diligence-document-view .diligence-content-wrapper .document-view .document-select {
  width: 228px;
  position: absolute;
  top: 12px;
  left: 12px;
  background-color: white;
  z-index: 10;
}

.diligence-document-view .diligence-content-wrapper .document-view .fullscreen-trigger {
  background: var(--colour-neutral-grey-1);
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  top: 12px;
  right: 12px;
  height: 40px;
  cursor: pointer;
  z-index: 1;
}

.diligence-document-view .document-view .doc-loading {
  min-height: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.diligence-document-view .document-view .react-pdf__Page__canvas {
  margin: 64px auto 18px;
}

.diligence-document-view .document-view .wrapper_btn {
  display: flex;
  height: 100%;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.diligence-document-view .document-view .wrapper_btn .next,
.diligence-document-view .document-view .wrapper_btn .prev {
  align-items: center;
  background: white;
  border: 1px solid var(--colour-neutral-grey-4);
  display: flex;
  height: 40px;
  justify-content: center;
  pointer-events: auto;
  position: absolute;
  top: 50%;
  width: 40px;
}

.diligence-document-view .diligence-content-wrapper {
  display: flex;
}

.diligence-document-view .diligence-content-wrapper .document-view {
  width: 60%;
  flex-shrink: 0;
}

.diligence-document-view .diligence-content-wrapper .diligence-content {
  width: 40%;
  flex-shrink: 0;
  margin-left: auto;
}

.diligence-document-view .diligence-content-wrapper .ant-tabs > .ant-tabs-nav {
  margin-bottom: 0;
  padding-left: 24px;
}

.diligence-document-view .ant-tabs-tabpane {
  max-height: 541px;
  overflow: auto;
}

.diligence-document-view .document-view {
  background-color: var(--colour-neutral-grey-3);
  min-height: 600px;
  overflow-x: auto;
  position: relative;
}

.diligence-document-view .ant-modal-header {
  padding: 12px 24px;
}

.diligence-document-view .ant-modal-title {
  font-weight: unset;
}

textarea.error,
.diligence-document-view .edit-field-error textarea,
.diligence-document-view .edit-field-error input {
  border-color: #f00 !important;
}

.diligence-content-wrapper .reject-diligence .status {
  color: var(--colour-neutral-grey-1);
  padding: 6px 9px;
  text-transform: capitalize;
}

.diligence-document-view .ant-modal-header {
  padding: 12px 24px 8px;
}

.diligence-document-view .ant-modal-title {
  font-weight: unset;
}
