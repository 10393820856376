.dropDown {
  border: 1px solid var(--colour-neutral-grey-7);
}

.dropDown :global(.ant-dropdown-trigger) {
  display: grid;
  grid-template-columns: 1fr 3em;
  padding: 0.5em;
  width: auto;
}
