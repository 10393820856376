.wrapper_communications .footer {
  display: grid;
  grid-gap: 24px;
  margin-top: 42px;
}

.wrapper_communications .update {
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}

.wrapper_communications .update span {
  color: #000;
}

.wrapper_communications .wrapper_btn {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 42px;
}

.wrapper_communications .switch {
  display: grid;
  grid-template-columns: max-content 1fr;
  height: max-content;
  grid-gap: 22px;
}

.wrapper_communications .switches_wrapper {
  display: grid;
  grid-gap: 24px;
  margin-top: 24px;
}

.wrapper_communications .switch-text {
  display: grid;
  grid-gap: 8px;
}

.wrapper_communications .switch-text .switch-title {
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #0f172a;
}

.wrapper_communications .switch-text .switch-subtitle {
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
}

@media screen and (max-width: 500px) {
  .wrapper_communications .wrapper_btn {
    grid-gap: 16px;
  }
}
