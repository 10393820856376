.regularFooter {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.loanDecisionFooter {
  display: grid;
  padding: 1em;
  grid-template-columns: 13em 13em 13em 2em 1fr 13em;
  background-color: #fff;
}

.loanDecisionFooter > :global(svg) {
  align-self: center;
  margin: 0 1em;
}

.loanDecisionFooter > :global(button) {
  width: 100%;
}

.loanDecisionFooter > :global(button > span) {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 1em;
}

.loanDecisionFooter > :global(button:nth-child(2)) {
  border-width: 1px 0;
}

.loanDecisionFooter > :global(button:last-child) {
  grid-column: 6;
}
