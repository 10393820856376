.registration-sub-header {
  background: #fafafa;
  display: flex;
  height: 4.5em;
  inset: 56px 0 auto 0;
  justify-content: center;
  padding: 0 24px;
  width: 100vw;
  z-index: 10;
}

.registration-sub-header .sub-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.registration-sub-header .sub-header-content .registration-progress,
.registration-sub-header .sub-header-content .registration-actions {
  display: flex;
}

.registration-sub-header .sub-header-content .registration-actions > * + * {
  margin-left: 16px;
}

.registration-sub-header .sub-header-content .registration-progress span {
  font-size: 14px;
  line-height: 22px;
  color: var(--dark_gray);
}

.registration-sub-header .sub-header-content .registration-progress img {
  margin-left: 8px;
}

.registration-sub-header .sub-header-content .registration-progress .progress-item.progress-item_active span {
  color: var(--main-blue-color);
}

.registration-sub-header .sub-header-content .registration-progress > .progress-item + .progress-item {
  margin-left: 24px;
}

.registration-sub-header .sub-header-content .registration-progress > .progress-item img {
  transition: 0.3s opacity;
}

.main-registration-wrapper-page {
  grid-column: 1/3;
}

@media (max-width: 750px) {
  .registration-sub-header {
    height: max-content;
    padding: 12px 24px 8px;
  }

  .registration-sub-header .sub-header-content .registration-progress,
  .registration-sub-header .sub-header-content {
    flex-direction: column;
  }

  .registration-sub-header .sub-header-content {
    align-items: start;
  }

  .registration-sub-header .sub-header-content .registration-progress > .progress-item + .progress-item {
    margin-left: 0;
    margin-top: 8px;
  }

  .registration-sub-header .sub-header-content .registration-actions {
    margin-top: 12px;
    width: 100%;
  }

  .registration-sub-header .sub-header-content .registration-actions button {
    width: 100%;
  }
}
