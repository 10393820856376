.list {
  list-style: none;
  margin-top: 1rem;
  padding: 0;
}

.listItem {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.listItemMarker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  color: #fff;
  background-color: var(--colour-brand-5);
  border-radius: 50%;
}

.collapse :global(.ant-collapse-header) {
  flex-direction: row-reverse;
  font-weight: 600;
  padding: 1em 0 !important;
}

.collapse :global(.ant-collapse-content-box) {
  display: grid;
  grid-template-columns: 2em 1fr;
  grid-gap: 1em 0.5em;
  text-align: justify;
}
