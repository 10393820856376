.ant-modal-header {
  padding: 24px 24px 0;
  border: none;
}

.ant-modal-footer {
  padding: 0 24px 24px;
  border: none;
}

.ant-modal-body {
  padding: 24px;
}

.ant-modal-body > *:last-child {
  margin-bottom: 0;
}
