.bid-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 318px;
  border: 2px solid transparent;
  background-color: #f5f5f5;
  transition: 0.2s ease all;
  position: relative;
}

.bid-card:hover {
  border: 2px solid var(--main-blue-color);
}

.bid-card.bid-card_active {
  border: 2px solid var(--main-blue-color);
}

.bid-card__img-wrapper {
  display: flex;
  width: 100%;
}

.bid-card__img {
  height: 164px;
  width: 100%;
  object-fit: cover;
}

.bid-card__title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #1f1f1f;
  margin-top: 12px;
}

.bid-card:hover .bid-card__img-styling-bg {
  background: #ffffff;
}

.bid-card.bid-card_active .bid-card__img-styling-bg {
  background: #ffffff;
}

.bid-card__desc {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 12px;
  line-height: 16px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #8c8c8c;
}
