.wrapper_milestones {
  margin: 0 -32px -24px;
}

.wrapper_milestones .section_title {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 12px;
  align-items: center;
  padding: 20px 24px;
}

.wrapper_milestones .section_title .title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #0f172a;
}

.wrapper_milestones .section_title .btn_default {
  padding: 4px 20px;
}

.wrapper_milestones .milestones_list {
}

.wrapper_milestones .milestones_list .body .every,
.wrapper_milestones .milestones_list .header {
  grid-template-columns: 44px 300px 1fr;
  display: grid;
}

.wrapper_milestones .milestones_list .body .every span,
.wrapper_milestones .milestones_list .header span {
  font-size: 12px;
  padding: 12px;
  line-height: 20px;
  color: #595959;
  display: block;
}

.wrapper_milestones .milestones_list .header span {
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: #cbd5e1;
}

.wrapper_milestones .milestones_list .header span:last-child {
  border-width: 1px 0 0 0;
}

.wrapper_milestones .milestones_list .body .every .input_container:last-child .wrapper_input {
  border-right: 0;
}

.wrapper_milestones .milestones_list .body .every .input_container .wrapper_input input:focus {
  box-shadow: unset;
  border: unset;
}

.wrapper_milestones .milestones_list .body .every span,
.wrapper_milestones .milestones_list .body .every .input_container {
  height: 100%;
  border-right: 1px solid #cbd5e1;
}

.wrapper_milestones .milestones_list .body .every .input_container:last-child {
  border-right: unset;
}

.wrapper_milestones .milestones_list .body .every .input_container .wrapper_input input {
  border: unset;
  box-shadow: unset;
}

.wrapper_milestones .milestones_list .body .every .input_container * {
  height: inherit;
}

.wrapper_milestones .milestones_list .body .every span {
  font-size: 14px;
  line-height: 22px;
  color: #0f172a;
}

.wrapper_milestones .milestones_list .body .every span:first-child {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #000;
}

.wrapper_milestones .milestones_list .body .every {
  border-top: 1px solid #cbd5e1;
}

.wrapper_milestones .milestones_list .body .every .input_container .wrapper_input {
  margin-top: 0;
}

/*.wrapper_milestones .milestones_list .header span{*/
/*}*/

.wrapper_milestones .milestones_list > div {
  grid-template-columns: 44px 300px 1fr;
}
