.universal-carousel {
  /*position: relative;*/
}
.universal-carousel-container {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.universal-carousel__btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(50% - 23px);
  cursor: pointer;
  width: 30px;
  height: 43px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.universal-carousel__btn_prev {
  left: 12px;
}
.universal-carousel__btn_next {
  right: 12px;
}
.universal-carousel .slick-dots {
  background-color: rgba(0, 0, 0, 0.6);
}
.universal-carousel .slick-dots.slick-dots-bottom {
  bottom: 5px;
  padding: 5px 0;
  margin: 0 !important;
  border-radius: 0 0 8px 8px;
}
.universal-carousel__media-container {
}
.universal-carousel__media-container_image {
}
.universal-carousel__media-container_youtube-video {
}
.universal-carousel__media-container_video {
}
.universal-carousel__media-container_video video {
  object-fit: cover;
}
.universal-carousel__media-container_image {
  object-fit: cover;
}
