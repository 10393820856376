.root {
  display: grid;
}

.root > :global(div:not(:last-child)) {
  border-bottom: 1px solid var(--colour-neutral-grey-4);
}

.root > :global(div) {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em 0;
}

.yes {
  border: 1px solid var(--colour-neutral-grey-4);
  padding: 1em;
  display: flex;
  gap: 1em;
}

.yesSelected {
  border-color: var(--colour-green-5);
}

.root :global(h1) {
  font-size: 1.1em;
  font-weight: 600;
  margin: 0;
}

.loanTypes {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
}

.loanTypes > :global(h1) {
  grid-column: 1/3;
}

.docTypeList {
  list-style: none;
  padding: 0;
}

.qualificationItemsWrapper {
  display: flex;
  gap: 0.5em;
}

.qualificationItem {
  border: 2px solid var(--colour-neutral-grey-4);
  padding: 0.5em 0;
  text-align: center;
  width: 5em;
}

.qualificationItemSelected {
  border-color: var(--colour-brand-5);
}

.additionalInfoList {
  background-color: var(--colour-neutral-grey-4);
  padding: 1em 2em;
  list-style: none;
}
