.payments_wrapper__form {
  border: 1px solid #cbd5e1;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px 14px 14px;
  margin-top: 20px;
}

.payments_wrapper__form .radio_title {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.payments_wrapper__form .check_made,
.payments_wrapper__form .prefered_payments {
  display: grid;
  grid-gap: 16px;
}

.payments_wrapper__form .ant-radio-group {
  display: grid;
  grid-gap: 16px;
}

.payments_wrapper__form .check_made {
  margin-top: 20px;
}

.payments_wrapper__form .inputs {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  max-width: 500px;
}

.payments_wrapper__form .inputs .two_col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
}

.payments_wrapper__form .inputs .phone-wrapper {
  grid-column: unset;
}

.payments_wrapper__form .inputs .ant-dropdown-trigger {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 100%;
  padding: 6px 12px;
  display: grid;
  grid-template-columns: 1fr max-content;
}

.payments_wrapper__form .footer {
  display: flex;
  gap: 1em;
  margin-top: 2em;
}

@media screen and (max-width: 700px) {
  .payments_wrapper__form {
    border: unset;
    padding: 0;
  }

  .payments_wrapper__form .inputs .two_col {
    grid-template-columns: 1fr;
  }
}
