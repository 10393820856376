.wrap {
  width: 100%;
  height: 138px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 2px rgba(40, 41, 61, 0.04);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}
.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 28px;
  color: #141414;
}
.extra {
  width: 100%;
  align-items: center;
  text-align: center;
}
.desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
}
