.actions {
  display: flex;
  gap: 0.5em;
  justify-content: flex-end;
}

.menu :global(.ant-dropdown-menu) {
  max-height: 300px;
}

.menu :global(.ant-dropdown-menu-item) {
  color: var(--colour-neutral-grey-7);
}

.menu :global(.ant-dropdown-menu-item:last-child) {
  border-top: 1px solid var(--colour-neutral-grey-4);
}

.menu :global(.ant-dropdown-menu-item .ant-dropdown-menu-title-content) {
  color: rgba(0, 0, 0, 0.85);
}

.reassign {
  border: 0;
  padding: 5px 12px;
  margin: -5px -12px;
  width: 100%;
  justify-content: flex-start;
  background-color: transparent;
  box-shadow: none;
  outline: none;
}

.reassign:hover {
  color: inherit;
  background-color: transparent;
}

.reassign:hover svg {
  stroke: var(--colour-neutral-grey-7);
}
