.root :global(.ant-modal-header),
.root :global(.ant-modal-footer) {
  border: 0;
}

.root :global(.ant-modal-footer > .ant-btn) {
  height: 2.625rem;
  border-radius: 0;
}

.root :global(.ant-modal-footer > .ant-btn-primary):not(:disabled) {
  background-color: #1f2c5f;
  border-color: #1f2c5f;
}
