.additionalTermsButton {
  background-color: transparent;
  border: 1px solid var(--colour-neutral-grey-6);
  cursor: pointer;
  padding: 0.25em 0.5em;
}

.additionalTermsText {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-width: 220px;
}

.additionalTermsText p {
  margin: 0;
}
