.universal-table {
}
.universal-table.universal-table_with-header {
}
.universal-table.universal-table_with-header .universal-table__header {
  border: 1px solid var(--main-divider-gray-color);
  border-bottom: none;
  /*border-radius: 8px 8px 0 0;*/
}
.universal-table.universal-table_with-sidebar {
  display: flex;
}
.universal-table.universal-table_header-no-x-padding .universal-table__header {
  padding-left: 0;
  padding-right: 0;
}
.universal-table.universal-table_with-sidebar.universal-table_with-header {
  border: 1px solid var(--main-divider-gray-color);
  /*border-radius: 8px;*/
}
.scenarios-page .universal-table.universal-table_with-sidebar .universal-table__table-inner {
  overflow: auto;
}
.scenarios-page .universal-table.universal-table_with-sidebar.universal-table_with-header {
  overflow: hidden;
}
.universal-table.universal-table_with-sidebar.universal-table_with-header .universal-table__header {
  border: none;
}
.universal-table.universal-table_with-sidebar.universal-table_with-header .ant-table table {
  border: none;
  border-radius: 0;
}
.universal-table.universal-table_with-sidebar.universal-table_with-header .ant-table-thead > tr > th {
  border-top: 1px solid var(--main-divider-gray-color);
}
.universal-table.universal-table_with-sidebar.universal-table_with-header .ant-table table tr > th:first-child {
  border-top-left-radius: 0 !important;
}
.universal-table.universal-table_with-sidebar.universal-table_with-header
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 0 !important;
}
.universal-table.universal-table_with-sidebar.universal-table_with-header .ant-table-pagination.ant-pagination {
  padding: 0 32px;
  gap: 15px;
}
.universal-table.universal-table_with-sidebar .universal-table__table-inner {
  width: 100%;
}

.universal-table.universal-table_with-pagination .pagination_btn {
  padding: 8px 24px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  transition: 0.4s;
  /*border-radius: 24px;*/
}
.universal-table.universal-table_with-pagination .ant-pagination-disabled {
  display: none;
}
.universal-table.universal-table_with-pagination .ant-pagination-item {
  border: unset;
}
.universal-table.universal-table_with-pagination .ant-pagination-item a {
  color: #000;
  font-weight: 600;
}
.universal-table.universal-table_with-pagination .ant-pagination-item-active {
  border: 1px solid var(--main-divider-gray-color);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
  /*border-radius: 24px;*/
}

.universal-table.universal-table_with-header .ant-table table {
  border: 1px solid var(--main-divider-gray-color);
  /*border-radius: 0 0 8px 8px !important;*/
}
.universal-table__header {
  padding: 8px 20px;
  width: 100%;
  background-color: #ffffff;
}
.universal-table__sidebar {
  padding: 16px;
  border-right: 1px solid var(--main-divider-gray-color);
  background-color: #ffffff;
}
.universal-table__header.universal-table__header_with-tab-menu {
  padding-bottom: 0;
}
/*.universal-table_default-padding .ant-table.ant-table-middle .ant-table-tbody > tr > td{*/
/*    padding: 13px 10px;*/
/*}*/
.universal-table_default-padding .ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.universal-table_default-padding .ant-table.ant-table-middle .ant-table-thead > tr > th,
.universal-table_default-padding .ant-table.ant-table-middle .ant-table-tbody > tr:not(.ant-table-measure-row) > td,
.universal-table_default-padding .ant-table.ant-table-middle tfoot > tr > th,
.universal-table_default-padding .ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 15px !important;
}

.universal-table_centered-content:not(.teams-table, .diligence-table)
  .ant-table.ant-table-middle
  .ant-table-tbody
  > tr
  > td {
  text-align: center;
  vertical-align: middle !important;
}
