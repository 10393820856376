.root {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  max-width: 1400px;
}

.title {
  color: var(--colour-brand-5);
  margin-bottom: 16px;
}

.block {
  border: 1px solid var(--colour-neutral-grey-5);
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.congratulationsBlock {
  padding: 32px;
  text-align: center;
  border: 2px solid var(--colour-brand-5);
}

.congratulationsBlock::after,
.congratulationsBlock::before {
  content: '';
  position: absolute;
  top: 50%;
  width: 170px;
  height: 250px;
  background-image: url('assets/images/confetti.png');
}

.congratulationsBlock::before {
  left: 0;
  transform: rotate(180deg) translateY(50%);
}

.congratulationsBlock::after {
  right: 0;
  transform: translateY(-50%);
}

.block h3 {
  font-weight: 700;
}

.block p {
  margin-bottom: 0;
}

.half a {
  color: inherit;
}

.blockRow {
  margin-bottom: 16px;
}

.blockRow p:last-of-type {
  margin-bottom: 16px;
}

.block button {
  width: 100%;
}

.block > *:nth-last-child(2) {
  flex-grow: 1;
}

.blockRow:not(:last-of-type) {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--colour-neutral-grey-4);
}

.half {
  width: calc(50% - 16px);
}

.actions {
  display: flex;
  align-items: center;
  gap: 32px;
  justify-content: center;
  width: 100%;
}

.list {
  max-width: 785px;
}

.iconWrapper {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(215, 211, 248, 0.54);
  padding: 0;
  margin: 0;
}

.trophyIcon {
  color: var(--awesome_purple);
}
