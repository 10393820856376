.btn_default {
  padding: 8px 24px;
  /*border-radius: 24px;*/
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  background: #fff;
  color: #000;
  border: 1px solid var(--light_gray);
  transition: 0.4s;
  display: grid;
  height: max-content;
  grid-template-columns: max-content 1fr;
  grid-gap: 8px;
  justify-self: center;
  margin: 0 8px;
}

.btn_default:first-child {
  margin-left: 0;
}

.btn_default:last-child {
  margin-right: 0;
}

.btn_default:hover {
  background: #f7f7f7;
}

.btn_default:first-child {
  margin-left: 0;
}

.btn_default:last-child {
  margin-right: 0;
}

.btn_default.dark-grey {
  border: 1px solid #a9b1cc;
}

.btn_disabled {
  cursor: not-allowed;
}

.btn_rounded {
  border-radius: 24px;
}

.btn_disabled svg path {
  cursor: not-allowed;
}

.btn_disabled:hover svg path {
  cursor: not-allowed;
}

.btn_only_text {
  grid-template-columns: 1fr;
  grid-gap: unset;
}

.btn_purple_transparent {
  background: transparent;
  box-shadow: none;
  border: 1px solid transparent;
  color: var(--awesome_purple);
}

.btn_purple_bordered:hover,
.btn_purple_transparent:hover,
.btn_purple {
  background: var(--awesome_purple);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  color: #fff;
  border: 1px solid var(--awesome_purple);
}

.btn_purple svg path,
.svg_no_fill svg path {
  transition: 0.4s;
}

.btn_purple:hover svg path {
  /*fill: var(--awesome_purple);*/
  stroke: var(--awesome_purple);
}

.svg_no_fill:hover svg path {
  fill: none;
}

.btn_purple_bordered:hover svg path {
  fill: #fff;
  stroke: var(--awesome_purple);
}

.btn_purple_bordered,
.btn_purple:hover {
  background: #fff;
  color: var(--awesome_purple);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  border: 1px solid var(--awesome_purple);
}

.btn_purple:disabled {
  background: var(--disabled_button);
  border: 1px solid var(--disabled_button);
  color: #fff;
  cursor: default;
}

.btn_error {
  border: 1px solid var(--error);
  color: var(--error);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
}

.btn_hover_disabled:hover {
  background: transparent;
  box-shadow: none;
  color: #fff;
  border: 1px solid transparent;
}

.btn_default_no_gap {
  grid-gap: 0;
  grid-template-columns: max-content;
}

.btn_light_blue,
.btn_red {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  color: #ffffff;
  font-size: 14px;
  padding: 6px;
}

.btn_light_blue_big,
.btn_red_big {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  color: #ffffff;
  height: 52px;
  font-size: 20px;
  line-height: 140%;
  font-weight: 600;
  text-transform: uppercase;
  justify-content: center;
}

.btn_red,
.btn_red_big {
  background-color: #ff0a00;
}

.btn_light_blue:hover,
.btn_light_blue_big:hover,
.btn_light_blue,
.btn_light_blue_big {
  border: none;
  background-color: #008ff8;
}

.btn_light_blue_big span,
.btn_red_big span {
  font-family: 'PosterGothicATF', sans-serif;
}

.btn_red.btn_red_outlined {
  border: 3px solid #1f3047;
  outline: 1px solid #ff4d4f;
}

.btn_red.btn_default:hover {
  background-color: #af3031;
}

.btn_red span {
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn_text_dark span {
  color: #1f1f1f !important;
}

.btn_darkblue_transparent {
  background: transparent;
  box-shadow: none;
  border: 1px solid transparent;
  color: var(--main-darkblue-color);
}

.btn_darkblue_bordered:hover,
.btn_darkblue {
  background: var(--main-darkblue-color);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  color: #fff;
  border: 1px solid var(--main-darkblue-color);
}

.btn_darkblue_transparent:hover {
  background-color: unset;
}

.btn_darkblue svg path,
.svg_no_fill svg path {
  transition: 0.4s;
}

.btn_darkblue:hover svg path {
  /*fill: var(--awesome_purple);*/
  stroke: #ffffff;
}

.svg_no_fill:hover svg path {
  fill: none;
}

.btn_darkblue_bordered:hover svg path {
  fill: #fff;
  stroke: var(--main-darkblue-color);
}

.btn_darkblue_bordered,
.btn_darkblue:hover {
  background: var(--main-blue-color);
  border: 1px solid var(--main-blue-color);
  color: #ffffff;
}

.btn_darkblue:disabled {
  background: var(--disabled_button);
  border: 1px solid var(--disabled_button);
  color: #fff;
  cursor: default;
}

/*----------STYLES FOR BLUE BUTTON---------------*/
.btn_blue_transparent {
  background: transparent;
  box-shadow: none;
  border: 1px solid transparent;
  color: #293bbc;
}

.btn_blue_bordered:hover,
.btn_blue {
  background: #293bbc;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  color: #fff;
  border: 1px solid #293bbc;
}

.btn_blue_transparent:hover {
  background-color: unset;
}

.btn_blue svg path,
.svg_no_fill svg path {
  transition: 0.4s;
}

.btn_blue:hover svg path {
  /*fill: var(--awesome_purple);*/
  stroke: #ffffff;
}

.svg_no_fill:hover svg path {
  fill: none;
}

.btn_blue_bordered:hover svg path {
  fill: #fff;
  stroke: #293bbc;
}

.btn_blue_bordered,
.btn_blue:hover {
  background: var(--main-blue-color);
  border: 1px solid var(--main-blue-color);
  color: #ffffff;
}

.btn_blue:disabled {
  background: var(--disabled_button);
  border: 1px solid var(--disabled_button);
  color: #fff;
  cursor: default;
}
