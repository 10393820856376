.wrapper_checkbox {
  display: grid;
  grid-template-columns: 44px max-content;
  align-items: center;
  cursor: pointer;
}

.wrapper_checkbox.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.wrapper_checkbox .wrapper_switcher {
  background: #bfbfbf;
  border-radius: 16px;
  height: 22px;
  width: 44px;
  transition: background 0.4s;
  position: relative;
}

.wrapper_checkbox .wrapper_switcher .checkbox {
  background: #fff;
  content: '';
  width: 18px;
  height: 18px;
  left: 2px;
  top: 2px;
  position: absolute;
  border-radius: 16px;
  transition: left 0.4s;
}

.wrapper_checkbox .switcher_text {
  font-size: 15px;
  line-height: 155%;
  color: var(--dr_white);
  padding-left: 12px;
}

.wrapper_checkbox .switcher_true {
  background: #1890ff;
}

.wrapper_checkbox .switcher_true .checkbox {
  left: 24px;
}
