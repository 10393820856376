.root {
  border-bottom: 1px solid var(--colour-neutral-grey-5);
  display: flex;
  height: 4em;
  justify-content: space-between;
}

.root > :global(div) {
  --border-colour: var(--colour-neutral-grey-5);
  --border-width: 1px;

  background-color: var(--border-colour);
  display: flex;
  gap: var(--border-width);
}

.root > :global(div:first-child) {
  padding-right: var(--border-width);
}

.root > :global(div:last-child) {
  padding: 0 var(--border-width);
  margin-right: 1rem;
}

.root :global(button) {
  align-items: center;
  border-radius: 0;
  border: none;
  display: flex;
  gap: 0.5em;
  height: 100%;
  padding: 1em;
}

.root :global(button:not(.ant-btn-primary)) {
  background-color: #fff;
}
