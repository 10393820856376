.root {
  background-color: #fff;
  display: grid;
  gap: 0.5em 1em;
  grid-template-columns: repeat(4, 1fr);
  padding: 2em;
}

.root > :global(:first-child) {
  grid-column: 1/5;
}

.title {
  font-size: 1.7em;
  font-weight: 600;
}

.root > :not(.title) {
  font-size: 1.1em;
}

.brokerInfoCard {
  grid-column: 1/3;
}
