.ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr:not(.ant-table-measure-row) > td {
  padding: 14.5px 25px !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 14.5px 25px;
  vertical-align: top !important;
}

.ant-table table {
  border: 1px solid var(--main-divider-gray-color);
  /*border-radius: 8px !important;*/
}

.ant-table table tr > th:first-child {
  /*border-top-left-radius: 8px !important;*/
}

.ant-table-container table > thead > tr:first-child th:last-child {
  /*border-top-right-radius: 8px !important;*/
}

.ant-table table {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.ant-table-thead > tr > th {
  font-size: 12px;
  line-height: 20px;
  color: #595959 !important;
  border-bottom: 1px solid var(--secondary-divider-gray-color) !important;
  background: white !important;
}

.ant-table-tbody > tr > td {
  border-bottom: unset !important;
}

.ant-table-thead > tr > th::before {
  display: none;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: rgba(64, 169, 255, 0.1);
}

.ant-table tbody tr {
  cursor: pointer;
}

.ems-list-wrapper {
  margin-top: 48px;
}

.ems-list-wrapper {
  margin-top: 48px;
}

.ems-list-wrapper .table_title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #0f172a;
  padding-left: 24px;
}
