.borrower_link_wrap {
  padding: 21px 16px;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  background: #fafafa;
}
.borrower_link-title {
  color: #000000;
}
.borrower_link-item {
  max-width: 500px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #8c8c8c;
}
.borrower_link-copy {
  color: #40a9ff;
  display: flex;
  cursor: pointer;
  gap: 12px;
}
