.checkboxesVertical,
.checkboxesVerticalPurpose {
  display: flex;
  flex-direction: column;
}

.checkboxesVerticalPurpose :global(.ant-checkbox-wrapper) {
  height: 40px;
}

.noMarginItem {
  margin-bottom: 0.5rem;
}

.formItem :global(.ant-form-item-label label) {
  width: 100px;
}
