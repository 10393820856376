.lar-app-section__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  font-style: normal;
  color: #1f1f1f;
  margin-bottom: 0;
}
.ant-collapse.custom-collapse.lar-app-section {
  border: none;
}
.ant-collapse.custom-collapse.lar-app-section > .ant-collapse-item {
  border-bottom: none;
}
.ant-collapse.custom-collapse.lar-app-section .ant-collapse-content {
  border-top: none;
}
.ant-collapse.custom-collapse.lar-app-section > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  padding: 12px 16px 12px 0;
}
.ant-collapse.custom-collapse.lar-app-section svg {
  transition: 0.2s ease all;
}
