.filterWrapper {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.search {
  width: 264px;
}

.select {
  width: 160px;
}
