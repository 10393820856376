.actionContainer {
  justify-content: flex-end;
  display: flex;
}

.buttonSettings {
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  background-color: transparent;
}
