.widget {
  background-color: #fff;
  border: 1px solid var(--colour-neutral-grey-5);
  display: grid;
  gap: 1em;
  grid-template-rows: auto 1fr auto;
  padding: 1em;
  width: 100%;
}

.fullWidget {
  grid-template-rows: auto 1fr auto auto;
}

.activeWidget {
  /* Border 1px thicker → padding 1px thinner */
  border: 2px solid var(--colour-brand-5);
  padding: calc(1em - 1px);
}

.widget > :global(header) {
  border-bottom: 1px solid var(--colour-neutral-grey-5);
  text-align: center;
}

.widget > :global(header > h1) {
  font-size: 1.1em;
  font-weight: 600;
}

.widget > :global(header > h2) {
  font-size: 2.3em;
}

.sum {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.sum > :global(:first-child) {
  align-items: center;
  display: flex;
  font-size: 1.1em;
  gap: 0.5em;
  cursor: pointer;
}

.circle {
  align-items: center;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 1.7em;
  font-weight: 600;
  height: 4.5rem;
  justify-content: center;
  width: 4.5rem;
}

.red {
  background-color: #ff6365;
}

.green {
  background-color: var(--colour-green-5);
}

.yellow {
  background-color: var(--colour-gold-5);
}

.root :global(ul) {
  color: var(--colour-neutral-grey-7);
  list-style: none;
  margin: 0;
  padding: 0;
}

.root :global(ul span) {
  font-weight: 500;
  margin-right: 0.5em;
}

.buttonsWrapper {
  display: flex;
  gap: 1em;
}

.buttonsWrapper > :global(*) {
  width: 100%;
}

.widgetTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
