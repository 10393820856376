.larChat {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  margin-right: 12px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  padding: 4px 8px 4px 4px;
  background-color: #fdfdff;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
}

.larChat:not(.activeLARChat):hover {
  background-color: #f0f0f0;
}

.larChatRole {
  background-color: #f0f0f0;
  padding: 1px 8px;
  margin-right: 8px;
  display: inline-block;
}

.activeLARChat {
  background-color: #40a9ff;
  border-color: #40a9ff;
  color: #fdfdff;
  cursor: auto;
}

.activeLARChat .larChatRole {
  background-color: #fdfdff;
  color: #40a9ff;
}
