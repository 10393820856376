.bid-table {
  background: #fff;
}

.bid-table.bid-table_user {
  background: transparent;
}

.bid-table_scroll .bid-table-list_responsible {
  box-shadow: -5px 0 11px -6px rgba(34, 60, 80, 0.6) inset;
}

.bid-table_scroll .bid-table-list__list-item_starter {
  box-shadow: 9px 0 8px -5px rgba(0, 0, 0, 0.2);
}

.bid-table_scroll.bid-table_user .bid-table-list_responsible {
  box-shadow: none;
}

.bid-table_scroll.bid-table_user .bid-table-list__list-item_starter {
  box-shadow: none;
}

.bid-table__inner {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 34px;
  background-color: #fff;
}

.bid-table-list {
  width: 100%;
}

.bid-table-list__list-item_starter .bid-table-list__list-item-body {
  background-color: #f5f5f5;
}

.bid-table .ant-spin-nested-loading .ant-spin-container .ant-row {
  flex-wrap: nowrap;
  width: max-content;
}

.bid-table .ant-spin-nested-loading .ant-spin-container .ant-row > div {
  width: 17em !important;
}

.bid-table_user.bid-table .ant-spin-nested-loading .ant-spin-container .ant-row > div {
  margin-right: 23px;
}

.bid-table-list_responsible {
  overflow-x: auto;
  scroll-behavior: smooth;
}

.bid-table-list .ant-row > div {
  border-right: 1px solid #f0f0f0 !important;
}

.bid-table-list .ant-list {
  border: 1px solid #cbd5e1;
  border-radius: 8px;
}

.bid-table-list__list-item-header-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  /* overflow: hidden; */
}

.bid-table-list__list-item-header-label {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #434343;
  font-weight: 600;
  margin: 8px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
}

.bid-table-list__list-item-select {
  margin: 8px 0;
}

.bid-table-list__list-item-header-label > span {
  padding: 0 8px;
}

.bid-table-list__list-item-header-label-qs {
  margin: 6px 0 4px;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
}

.bid-table-list__list-item-header-amount.bid-table-list__list-item-header-amount-qs {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  height: 24px;
  width: auto;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
}

.bid-table-list__list-item-header_starter-item {
  display: flex;
  flex-direction: column;
  padding: 0 8px 8px;
}

.bid-table-list__list-item-header-buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 3px 9px 6px;
  border-top: 1px solid var(--colour-neutral-grey-4);
}

.bid-table-list__list-item-header-select {
  position: relative;
}

.bid-table-list__list-item-header-select:hover .bid-table-list__list-item-header-select-body {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.bid-table-list__list-item-header-select:hover .bid-table-list__list-item-header-select-title {
  background-color: #40a9ff;
}

.bid-table-list__list-item-header-select-title.selected {
  background-color: #e6f7ff;
  color: #40a9ff;
}

.bid-table-list__list-item-header-select:hover .bid-table-list__list-item-header-select-title.selected {
  background-color: #e6f7ff;
}

.bid-table-list__list-item-header-select-title[aria-disabled] {
  cursor: auto;
}

.bid-table-list__list-item-header-select-title[aria-disabled]:hover {
  background-color: #1f2c5f;
}

.bid-table-list__list-item-header-select-title[aria-disabled].winner:hover {
  background-color: #52c41a;
}

.bid-table-list__list-item-header-select-title[aria-disabled].selected:hover {
  background-color: #e6f7ff;
  color: #40a9ff;
}

.bid-table-list__list-item-header-select-title.winner {
  background-color: #52c41a;
  padding: 4px;
  color: var(--widget-color);
}

.bid-table-list__list-item-header-select-title {
  font-size: 14px;
  line-height: 22px;
  background-color: #1f2c5f;
  transition: 0.2s;
  color: #ffffff;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.bid-table-list__list-item-header-select-body {
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: 0.2s;
}

.bid-table-list__list-item-header-select.disabled .bid-table-list__list-item-header-select-title {
  cursor: not-allowed;
}

.bid-table-list__list-item-header-select.disabled:hover .bid-table-list__list-item-header-select-body {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
}

.bid-table-list__list-item-header-select.disabled .bid-table-list__list-item-header-select-title:not(.winner) {
  background-color: #cbd5e1;
}

.bid-table-list__list-item-header-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #262626;
  margin: 0 auto;
  width: max-content;
}

.bid-table.bid-table_small-spacing .bid-table-list__info-block-item {
  padding: 0 1em;
  margin-top: 0;
}

.bid-table-list__info-block-item {
  min-height: 40px;
  padding: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #434343;
  border-bottom: 1px solid #f0f0f0;
  white-space: normal;
  word-break: break-word;
  text-align: right;
}

.bid-table-list__list-item_starter .bid-table-list__info-block-item {
  border-bottom: 1px solid transparent;
}

.bid-table-list__info-item-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #1f1f1f;
}

.bid-table-list__info-item-value {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #0f172a;
}

.bid-table-list__info-item-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
}

.bid-table-list.bid-table-list_no-border-header .ant-list-header .ant-row > .ant-col {
  border-right: none !important;
  padding: 10px 16px;
}

.bid-table-list .ant-list-header .ant-row > .ant-col {
  padding: 10px 16px;
}

.bid-table-list.bid-table-list_no-padding-header .ant-list-header {
  padding-top: 0;
  padding-bottom: 0;
}

.bid-table-list__list-item {
  transition: all 0.2s ease;
  position: relative;
  border: 1px solid #cdcdcd;
  overflow: hidden;
}

.bid-table-list__list-item-icon-block {
  position: absolute;
  top: 17px;
  right: 13px;
}

.bid-table-list__list-item-icon-block._pencil {
  top: 10px;
  right: 5px;
}

.bid-table_user .bid-table-list__list-item {
  border: 1px solid #d9d9d9;
}

.bid-table_user .bid-table-list__list-item-wrapper_border-gradient-blue {
  padding: 5px;
  background: linear-gradient(to bottom, #40a9ff, #7b61ff);
  border-radius: 12px;
}

.bid-table_user .bid-table-list__list-item-wrapper_border-gradient-blue .bid-table-list__list-item-icon-block {
  top: 13px;
}

.bid-table_user .bid-table-list__list-item-wrapper_border-gradient-blue .bid-table-list__list-item-link {
  padding-bottom: 4px;
}

.bid-table_user .bid-table-list__list-item-wrapper_border-gradient-blue .bid-table-list__list-item-header {
  margin-top: 16px;
}

.bid-table_user .bid-table-list__list-item-wrapper_border-gradient-blue .bid-table-list__list-item {
  border-color: transparent;
}

.bid-table_user .bid-table-list__list-item.bid-table-list__list-item_starter {
  border: none;
}

.bid-table_user .bid-table-list__list-item.bid-table-list__list-item_green-border {
  border-color: #389e0d;
}

.bid-table_user .bid-table-list__list-item.bid-table-list__list-item_blue-border {
  border-color: #40a9ff;
}

.bid-table_user .bid-table-list__list-item.bid-table-list__list-item_grey-border {
  border-color: #d9d9d9;
}

.bid-table_user .bid-table-list__list-item.bid-table-list__list-item_purple-border {
  border-color: #6c5ce7;
}

.bid-table_user .bid-table-list__list-item {
  /*border-radius: 12px;*/
  background-color: #ffffff;
}

.bid-table_user .bid-table-list__list-item.bid-table-list__list-item_gray {
  background-color: #f0f0f0;
}

.bid-table-list__list-item.bid-table-list__list-item_gray {
  background-color: #f5f5f5;
}

.bid-table_user .bid-table-list__list-item.bid-table-list__list-item_gray .bid-table-list__info-block-item {
  border-color: #fafafa;
}

.bid-table-list.bid-table-list_gray .ant-row > div {
  border-right: 1px solid #d9d9d9 !important;
}

.bid-table-list__list-item.bid-table-list__list-item_gray .bid-table-list__info-block-item {
  border-color: #d9d9d9;
}

.bid-table_user .bid-table-list__list-item_starter {
  border-radius: 0;
  background-color: transparent;
}

.bid-table-list__list-item_focus-disabled {
  border: 1px solid #bfbfbf;
}

.bid-table-list__list-item_gray {
  background-color: #f0f0f0;
}

.bid-table-list__list-item_starter {
  min-width: 200px;
  border-right: none;
}

.bid-table-list__list-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 16px;
}

.bid-table-list__list-item-link_with-icon .bid-table-list__list-item-link-icon {
  margin-right: 5px;
}

.bid-table-list__list-item_gray .bid-table-list__list-item-link-text {
  color: #595959;
}

.bid-table-list__list-item_gray.bid-table-list__list-item_focus-active .bid-table-list__list-item-link-text {
  color: var(--main-blue-color);
}

.bid-table-list__list-item_gray.bid-table-list__list-item_focus-active {
  background: rgba(215, 211, 248, 0.5);
}

.bid-table-list__list-item-link-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--main-blue-color);
}

.bid-table-list__list-item-header {
  min-height: 133px;
  border-bottom: 1px solid #d9d9d9;
  text-align: center;
}

.bid-table-list__list-item-header .ant-select {
  text-align: left;
}

.bid-table-list__list-item-header-status {
  padding: 8px;
  background-color: var(--colour-neutral-grey-4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.bid-table-list__info-block-item.bid-table-list__info-block-item_label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #1f1f1f;
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
}

.bid-table-list__info-block-item.bid-table-list__info-block-item:last-child {
  border-bottom: none;
}

.bid-table-list.ant-list-grid .ant-col > .ant-list-item {
  margin-bottom: 0;
  margin-top: 0;
}

.bid-table-list.ant-list-split .ant-list-header {
  border-bottom: none;
}

.bid-table-list__list-item-btn-block {
  padding: 12px;
  transition: all 0.2s ease;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: rgba(217, 217, 217, 0.54);
}

.bid-table-list__list-item-btn-block_active {
  background: #6c5ce7;
}

.bid-table-list__list-item-btn {
  width: 100%;
  color: rgba(0, 0, 0, 0.85);
  padding: 5px 12px;
  background-color: #ffffff;
  cursor: pointer;
  transition: 0.2s;
  border: none;
  text-align: left;
}

.bid-table-list__list-item-btn:hover {
  background-color: #f5f5f5;
}

.bid-table-list__list-item-btn_disabled {
  background: rgba(217, 217, 217, 0.54);
}

.bid-table__controls {
  padding: 15px 0;
}

@media screen and (max-width: 450px) {
  .bid-table-list__info-block-item.bid-table-list__info-block-item_label {
    font-size: 12px;
    padding-left: 5px;
    padding-right: 0;
  }

  .bid-table-list__list-item_starter {
    min-width: 150px;
  }

  .lar-profile__auction .lar-profile__auction-info,
  .lar-profile__auction .lar-profile__auction-info .lar-profile__auction-header {
    border: none;
  }
}
