.root {
  display: grid;
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
}
.periodFilter :global(.ant-radio-button-wrapper) {
  height: 2.5rem;
  line-height: 2.375rem;
}

.periodFilter :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
  color: #ffffff;
  background-color: var(--main_dark_blue_color);
  border-color: var(--main_dark_blue_color);
  box-shadow: none;
  outline: none;
}

.periodFilter :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
  display: none;
}

.card :global(.ant-card-body) {
  padding: 1rem;
}

.cardInner {
  display: grid;
  grid-template-columns: 2.5rem 1fr;
  grid-gap: 1rem;
  align-items: flex-start;
}

.cardInnerWithChart {
  grid-template-columns: 4rem 1fr;
}

.cardInnerWithChart .cardIcon {
  padding: 0;
  width: 72px;
  height: 72px;
}

.cardPrice {
  font-size: 1.5rem;
  font-weight: 600;
}

.cardIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 50%;
}

.cardIconDanger {
  color: var(--colour-red-5);
  background-color: var(--colour-red-1);
}

.cardIconWarning {
  color: var(--colour-gold-5);
  background-color: var(--colour-gold-2);
}

.cardIconSuccess {
  color: var(--colour-green-5);
  background-color: var(--colour-green-1);
}

.cardText {
  font-size: 1rem;
}
