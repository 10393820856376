.wrapper_landing_page .input_container .ant-input {
  padding: 4px 16px;
}

.wrapper_landing_page .company_info {
  margin-top: 16px;
  display: grid;
  grid-gap: 14px;
  max-width: 500px;
}

.wrapper_landing_page .company_info .domain {
  display: grid;
  grid-template-columns: max-content 1fr 140px;
  align-items: center;
}

.wrapper_landing_page .company_info .domain .input_title {
  grid-column: 1/-1;
}

.wrapper_landing_page .company_info .domain .wrapper_input {
  margin-top: 0;
}

.wrapper_landing_page .company_info .domain .input_container .ant-input {
  border-radius: 0 3px 3px 0;
}

.wrapper_landing_page .company_info .domain .placeholder {
  background: #f0f0f0;
  display: grid;
  align-content: center;
  border-radius: 3px 0 0 3px;
  padding: 5px 4px;
  height: max-content;
}

.wrapper_landing_page .wrapper_file_uploader {
  border-top: 1px solid #f0f0f0;
  margin: 16px 0;
  padding: 16px 0;
}

.wrapper_landing_page .wrapper_file_uploader__title {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #0f172a;
  margin-bottom: 8px;
}

.wrapper_landing_page .wrapper_file_uploader .avatar_title {
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #0f172a;
  margin-bottom: 8px;
}

.wrapper_landing_page .wrapper_file_uploader .second_title {
  margin-top: 24px;
}

.wrapper_landing_page .wrapper_file_uploader .avatar_title:last-child {
  margin-top: 24px;
}

.wrapper_landing_page .wrapper_inputs_info {
  border-bottom: 1px solid #f0f0f0;
  display: grid;
  grid-gap: 24px;
  padding-bottom: 24px;
  max-width: 500px;
}

.wrapper_landing_page .wrapper_btns {
  display: grid;
  margin: 40px 0;
  grid-gap: 12px;
  grid-template-columns: repeat(2, max-content) 1fr;
}

.wrapper_landing_page .wrapper_btns button:last-child {
  justify-self: end;
}

.wrapper_landing_page .wrapper_color_picker {
  display: grid;
  margin-top: 16px;
}

.wrapper_landing_page .wrapper_color_picker .title {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #0f172a;
}

.wrapper_landing_page .wrapper_color_picker .description {
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
}

.wrapper_landing_page .wrapper_color_picker .rcp-saturation {
  border-radius: unset;
  margin-top: 16px;
}

.wrapper_landing_page .wrapper_color_picker .rcp-body {
  padding: 20px 0 0;
}

@media screen and (max-width: 700px) {
  .wrapper_landing_page .wrapper_file_uploader .wrapper__avatar {
    grid-template-columns: 1fr;
  }

  .wrapper_landing_page .wrapper_btns {
    grid-template-columns: repeat(3, max-content);
  }

  .wrapper_landing_page .wrapper_btns button {
    font-size: 14px;
    line-height: 20px;
  }

  .wrapper_preferences__route {
    min-width: unset;
  }
}

@media screen and (max-width: 400px) {
  .wrapper_landing_page .wrapper_btns {
    grid-template-columns: max-content 1fr;
  }

  .wrapper_landing_page .wrapper_btns button:last-child {
    grid-column: 1/-1;
    justify-self: end;
  }

  .wrapper_landing_page .wrapper_btns button:nth-child(2) {
    justify-self: end;
  }
}
