.lar_creation {
}

.lar_creation_reminders {
  border-bottom: 1px solid #cbd5e1;
  padding-bottom: 24px;
}

.lar_creation .lar_creation_reminders .section_title {
  padding-top: 24px;
}

.lar_creation .section_title {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #0f172a;
}

.lar_creation .wrapper_input input,
.lar_creation .ant-dropdown-trigger {
  max-width: 360px;
}

.lar_creation_reminders .reminders {
  display: grid;
  margin-top: 32px;
  grid-template-columns: max-content max-content 1fr max-content 1fr;
  grid-gap: 26px;
}

.lar_creation_reminders .reminders .split,
.lar_creation_reminders .reminders .drop_down_wrapper {
  align-self: end;
  height: max-content;
}

.lar_creation_reminders .reminders .split {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #8c8c8c;
}

.lar_creation_reminders .check {
  margin-top: 32px;
}

.lar_creation_reminders .submit {
  margin-top: 28px;
  padding: 8px 50px;
}

.lar_creation .refinance_summary,
.lar_creation .lar_application {
  padding-top: 20px;
}

.lar_creation .lar_summary .section_title {
  margin-bottom: 0;
}

.lar_creation .lar_summary .lar_application .section_title {
  margin-bottom: 20px;
}

.lar_creation .lar_application,
.lar_creation .refinance_summary,
.lar_creation .acquisition_rental,
.lar_creation .acquisition_summary {
  display: grid;
  grid-gap: 28px;
}

.lar_creation .acquisition_rental,
.lar_creation .acquisition_summary,
.lar_creation .lar_summary button {
  margin-top: 56px;
}

.lar_creation .time {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
}

.lar_creation .time > div {
  align-self: end;
}

.lar_creation .time .wrapper_picker .ant-picker {
  padding: 7px 12px;
}
