.hero {
  padding: 2rem 3rem;
  color: #ffffff;
  background: radial-gradient(100% 100% at 61.57% 100%, #080812 0%, #191a33 100%);
}

.widgets {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.625rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(45, 167, 255, 0.06) 100%);
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.0288) 0%, rgba(45, 154, 255, 0.72) 100%);
}

.widgetTop {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
}

.widgetIcon {
  color: rgba(64, 169, 255, 1);
  fill: rgba(64, 169, 255, 1);
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
}

.widgetTitle {
  font-size: 1.5rem;
  color: #ffffff;
}

.textSecondary {
  color: rgba(245, 245, 245, 0.72);
}

.card {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.12);
}

.leftInfo {
  font-size: 1rem;
  padding: 1rem;
  height: 100%;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.12);
}

.title {
  font-weight: 600;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.label {
  color: rgba(245, 245, 245, 0.72);
}

.divider {
  border: 1px solid rgba(240, 240, 240, 0.12);
}
