.wrapper {
  width: 100%;
  height: calc(100% - 56px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.root {
  width: 360px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.authHead {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 24px;
  line-height: 32px;
}

.authTitle {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 24px;
  line-height: 32px;
}

.formItem :global(.ant-row) {
  display: flex;
  flex-direction: column;
}

.formItem :global(.ant-col) {
  text-align: left;
}

.formItem :global(.ant-btn) {
  width: 100%;
}
