.root :global(.ant-modal-body) {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.thumb {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-bottom: 8px;
}

.thumb > :global(picture > img) {
  width: 100%;
}

.errorMessage {
  color: #f00;
  white-space: nowrap;
}

.fileNameWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.fileInfoWrapper {
  margin-bottom: 16px;
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.leftCol {
  min-width: 100px;
  max-width: 100px;
}

.rightCol {
  width: 100%;
}

.fileIcon {
  width: 70px;
  height: 70px;
}

.fileIcon img {
  width: 100%;
  height: 100%;
}
