.root {
  display: flex;
  gap: 0.5em;
  padding: 0.5em;
}

.statusGroup {
  border-radius: 2em;
  border: 1px solid var(--colour-neutral-grey-5);
  display: flex;
  gap: 1em;
  padding: 0.25em;
}

.status {
  align-items: center;
  border-radius: 2em;
  cursor: default;
  display: flex;
  gap: 0.5em;
  padding: 0.3em 0.5em;
  transition: 0.3s background-color;
  width: max-content;
}

.clickableStatus {
  cursor: pointer;
}

.status :global(.ant-tag) {
  border-radius: 1.5em;
  border: none;
  margin-right: 0;
  text-align: center;
  width: 2.5em;
}

.activeStatus {
  color: #fff;
}

.activeStatus.purpleStatus {
  background-color: #5d5fef;
}

.purpleStatus :global(.ant-tag) {
  background-color: #afa6f2;
  color: #4833e0;
}

.activeStatus.yellowStatus {
  background-color: #faad14;
}

.yellowStatus :global(.ant-tag) {
  background-color: #ffe58f;
  color: #874d00;
}

.activeStatus.greenStatus {
  background-color: #52c41a;
}

.greenStatus :global(.ant-tag) {
  background-color: #b7eb8f;
  color: #135200;
}

.activeStatus.blueStatus {
  background-color: #3542b1;
}

.blueStatus :global(.ant-tag) {
  background-color: #bae7ff;
  color: #3542b1;
}

.activeStatus.redStatus {
  background-color: #595959;
}

.redStatus :global(.ant-tag) {
  background-color: #d9d9d9;
  color: #1f1f1f;
}
