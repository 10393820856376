.main_wrapper_auth {
  background: url('../../../assets/images/login-background.jpg') no-repeat center;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0 10vw;
  grid-column: 1/3;
}

.main_wrapper_auth_body {
  display: grid;
  align-items: center;
}

.main_wrapper_auth_body .wrapper_verification {
  max-width: 456px;
  width: 100%;
  padding: 3rem;
  background: #ffffff;
}

.main_wrapper_auth_body .auth_head,
.main_wrapper_auth_body .auth_title {
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin: 0 0 2rem;
}

.main_wrapper_auth_body .auth_head {
  font-size: 32px;
  margin-bottom: 24px;
}

.main_wrapper_auth_body .auth_title {
  font-size: 24px;
}

.main_wrapper_auth_body .auth_describe {
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  text-align: center;
  color: var(--dark_gray);
  margin-top: 32px;
}

.main_wrapper_auth_body .wrapper_form_error span {
  font-size: 12px;
  line-height: 14px;
  color: var(--error);
}

.main_wrapper_auth_body .wrapper_auth__inputs {
  display: grid;
  grid-gap: 28px;
}

.main_wrapper_auth_body .wrapper_auth__inputs button {
  margin-top: 12px;
}

@media (max-width: 992px) {
  .main_wrapper_auth {
    align-items: center;
    padding: 4rem 1rem;
  }

  .main_wrapper_auth_body {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .main_wrapper_auth_body .wrapper_verification {
    margin: 0 auto;
  }
}

@media (max-width: 568px) {
  .main_wrapper_auth_body .wrapper_verification {
    padding: 2rem 1rem;
  }
}
