.root {
  display: grid;
  grid-gap: 1rem;
}

.title {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.tableContainer {
  overflow: auto;
}

.tableHeaderCell {
  cursor: pointer;
  display: flex;
  grid-gap: 0.5rem;
  min-height: 23px;
  white-space: nowrap;
}

.tableContainer :global(.ant-table.ant-table-middle) table :global(.ant-table-thead) > tr > th {
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--colour-neutral-grey-11) !important;
}

.tableContainer :global(.ant-table.ant-table-middle) table :global(.ant-table-thead) > tr > th,
.tableContainer :global(.ant-table.ant-table-middle) table :global(.ant-table-tbody) > tr > td {
  white-space: nowrap;
  vertical-align: middle !important;
}

.tableContainer :global(.ant-table.ant-table-middle) table :global(.ant-table-tbody) > tr > td:first-child {
  width: 120px;
  padding: 0 !important;
}

.tableContainer table :global(.ant-table-tbody) > tr:not(:last-child) > td {
  border-bottom: 1px solid var(--secondary-divider-gray-color) !important;
}
