.main {
  background-color: #f5f5f5;
  grid-column: 1/3;
  padding: 0 calc(50vw - 40em);
}

.thinBlueLine {
  height: 13em;
  left: 0;
  position: absolute;
  width: 100%;
}

.main > :global(.ant-spin-nested-loading) {
  padding: 3em 0;
}

.top {
  display: flex;
  margin-bottom: 3em;
  position: relative;
  height: 20em;
}

.phone {
  color: var(--nero_black);
}

.dataWrapper {
  display: flex;
  flex-direction: column;
}

.pictureWrapper {
  flex-basis: 20em;
  margin-right: 1em;
  height: 100%;
  flex-shrink: 0;
}

.pictureWrapper :global(.ant-image) {
  height: 100%;
  width: 100%;
}

.pictureWrapper :global(.ant-image-img) {
  object-fit: cover;
}

.dataWrapper > :global(*) {
  border-radius: 0.5em;
}

.dataWrapper > :global(.ant-image > img) {
  border-radius: inherit;
  object-fit: cover;
}

.brokerInfo {
  background-color: #fff;
  padding: 1em 2em;
  width: 100%;
}

.brokerInfo > :global(ul) {
  list-style: none;
  padding: 0;
  margin-top: 0.5em;
}

.bottomTile {
  background-color: #fff;
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.bottomTile > :global(*) {
  padding: 2em;
}

.bottomTile > :global(:last-child) {
  background-color: #fafafa;
}

.applyNow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}

.applyNow > :global(*:not(:nth-child(2)):not(:nth-child(3))) {
  grid-column: 1/3;
}

.signIn {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.signIn > :global(div:not(:first-child)) {
  border-top: 1px solid var(--colour-neutral-grey-4);
  padding-top: 1em;
}

.signIn :global(ul) {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-top: 1em;
}

.signIn :global(ul > li) {
  display: flex;
  gap: 0.5em;
}

.decoration {
  background-color: rgba(0, 0, 0, 0);
  background-image: repeating-linear-gradient(
    100deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 34%,
    rgba(245, 245, 245, 1) 34%,
    rgba(245, 245, 245, 1) 67%,
    rgba(238, 238, 238, 1) 67%,
    rgba(238, 238, 238, 1) 99%
  );
  background-position: 0 0;
  background-repeat: repeat;
  background-size: auto;
  border-radius: inherit;
  height: 20em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20em;
}
