.custom-search {
  position: relative;
}
.custom-search_default {
}
.custom-search_default .ant-input-group-addon button {
  background-color: var(--main-blue-color);
  border-color: var(--main-blue-color);
}
.custom-search_default .ant-input-group-addon button:hover {
  border-color: var(--main-blue-color);
  background: var(--main-blue-color);
}
.custom-search_default .ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: var(--main-blue-color);
  box-shadow: 0 0 0 2px rgba(64, 169, 255, 0.2);
}
.custom-search .ant-input {
  border: 1px solid var(--main-divider-gray-color);
  border-radius: 0;
}
.custom-search__input {
}
.custom-search__result-block {
  position: absolute;
  background: #ffffff;
  z-index: 10;
  box-shadow: 0px 3px 16px 3px rgba(34, 60, 80, 0.2);
  width: 100%;
}
.custom-search__result-block_disabled {
  display: none;
  opacity: 0;
}
.custom-search__result-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 10px;
  border-bottom: 1px solid var(--light_gray);
  cursor: pointer;
  transition: 0.2s all ease;
  min-height: 43px;
  width: 100%;
}
.custom-search__result-item:hover {
  background: #f5f5f5;
}
.custom-search__no-data-item {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #595959;
  width: 100%;
}
