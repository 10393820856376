.ant-btn {
  white-space: nowrap;
  border-radius: 0;
}

.ant-btn svg {
  transition: 0.5s;
}

.ant-btn-primary {
  background-color: #40a9ff;
  border-color: #40a9ff;
  color: var(--widget-color);
}

.ant-btn-default:hover svg {
  stroke: #40a9ff;
}

.ant-btn-primary:hover {
  background-color: #67bafd;
  border-color: #67bafd;
  color: var(--widget-color);
}

.ant-btn-secondary {
  background-color: #389e0d;
  border-color: #389e0d;
  color: var(--widget-color);
}

.ant-btn-secondary:hover {
  background-color: #47b419;
  border-color: #47b419;
  color: var(--widget-color);
}

.ant-btn-dark {
  background-color: var(--main_dark_blue_color);
  border-color: var(--main_dark_blue_color);
  color: var(--widget-color);
}

.ant-btn-dark:hover {
  background-color: #38498f;
  border-color: #38498f;
  color: var(--widget-color);
}

.ant-btn-lg {
  font-size: 14px;
  line-height: 22px;
  border-radius: 0;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 0 !important;
}

.ant-btn > .anticon {
  line-height: 0 !important;
}
