.root {
  display: grid;
  grid-template-columns: 2fr 1fr;
  max-width: 40em;
  margin-top: 1em;
}

.root > :global(button) {
  background-color: transparent;
  border: none;
  cursor: pointer;
  justify-self: end;
}

.root > :global(button > svg) {
  vertical-align: middle;
}

.root :global(.ant-spin-nested-loading) {
  grid-column: 1/3;
}

.main {
  background-color: #fff;
  border: 1px solid var(--colour-neutral-grey-5);
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.main > :global(div:not(:last-child)) {
  border-bottom: 1px solid var(--colour-neutral-grey-4);
  padding-bottom: 1em;
}

.main > :global(div) {
  display: flex;
  gap: 1em;
  flex-direction: column;
}

.main :global(.ant-radio-group) {
  display: flex;
  flex-direction: column;
}

.multiRow > :global(div:not(:first-child)) {
  color: var(--colour-neutral-grey-8);
}

.main :global(h2) {
  font-size: 1.1rem;
  border-top: 1px solid var(--colour-neutral-grey-4);
  padding-top: 1em;
}

.stateBox {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em 1em;
  margin-bottom: 1em;
}

.stateBox > :global(*) {
  width: 5em;
  margin: 0;
}

.main > :global(form) {
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
}

.main > :global(form > *:not(button)) {
  grid-column: 1/4;
  gap: 1em;
}

.main > :global(form > button:nth-last-child(2)) {
  grid-column: 2;
}

.main > :global(form > button:last-child) {
  grid-column: 3;
}

.customError {
  color: var(--colour-red-5);
  width: 100%;
}
