.wrapper_partner_pdf {
  display: grid;
  width: 100%;
  min-height: calc(100vh - 56px);
  justify-content: center;
}

.broker_partner_agreement {
  margin: 0 auto;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  width: 486px;
  border-radius: 8px;
  display: grid;
  justify-items: center;
}

.broker_partner_agreement__docs_viewer .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.view-pdf-wrapper .fullscreen-trigger {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
  cursor: pointer;
}

.broker_partner_agreement h3 {
  text-align: center;
  margin-bottom: 22px;
}

.broker_partner_agreement__description {
  margin-top: 24px;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  width: 90%;
}

.view-pdf-wrapper {
  position: relative;
}

.broker_partner_agreement__docs_viewer img {
  width: 100%;
  height: 395px;
  background: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
}

.broker_partner_agreement__docs_viewer .wrapper_btn {
  margin: 8px 0 0;
  display: grid;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
  grid-gap: 4px;
  justify-content: center;
}

.broker_partner_agreement__docs_viewer .wrapper_btn .prev,
.broker_partner_agreement__docs_viewer .wrapper_btn .next {
  display: grid;
  align-items: center;
  cursor: pointer;
}

.broker_partner_agreement__docs_viewer .wrapper_btn .prev {
  transform: rotate(180deg);
}

.broker_partner_agreement__btn {
  margin-top: 24px;
  padding-top: 24px;
  width: 100%;
  border-top: 1px solid #e2e8f0;
  display: grid;
  justify-items: center;
}

@media (max-width: 750px) {
  .broker_partner_agreement {
    margin: 0;
  }
}

@media (max-width: 568px) {
  .broker_partner_agreement {
    width: 100%;
  }

  .broker_partner_agreement__description {
    width: 100%;
  }

  .wrapper_partner_pdf {
    padding: 12px;
  }

  .broker_partner_agreement h3 {
    font-size: 20px;
  }
}
