.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 32px;
}

.filter {
  padding-top: 16px;
  display: flex;
  gap: 16px;
}
