.scoring-wrapper {
  padding-top: 24px;
}

.scoring-list {
  margin-top: 24px;
  grid-template-columns: repeat(3, 210px);
  display: grid;
  grid-gap: 12px 20px;
}

.scoring-wrapper .scoring-footer {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.scoring-wrapper .scoring-footer button {
  width: 140px;
  margin-top: 12px;
}
