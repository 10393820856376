.root {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.header {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(4, 1fr);
}

.root h1 {
  font-size: 1.4em;
  margin: 0;
}

.root :global(.ant-radio-group) {
  display: flex;
  grid-column: 2/4;
  justify-content: flex-end;
}

.root :global(.custom-search) {
  grid-column: 4;
}

.root :global(.ant-table-wrapper) {
  grid-column: 1/5;
}

.previewWrapper {
  display: inline-flex;
  position: relative;
}

.actionsNumber {
  color: #fff;
  text-align: center;
  width: 3em;
}

.red {
  background-color: var(--colour-red-5);
}

.yellow {
  background-color: var(--colour-gold-5);
}

.tableHeaderCell {
  cursor: pointer;
  display: flex;
  gap: 0.5em;
  white-space: nowrap;
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
  font-size: 0.875rem;
  color: #ffffff;
  min-width: 50px;
}

.badgeRed {
  background-color: var(--colour-red-5);
}

.badgeOrange {
  background-color: var(--colour-gold-5);
}

.badgeGreen {
  background-color: var(--colour-green-5);
}

.dateRed {
  white-space: nowrap;
  color: var(--colour-red-5);
}

.expiresTitle {
  align-items: center;
  display: flex;
}
.expiresTitle svg {
  margin-left: 4px;
}

.expiresTitle:hover {
  cursor: pointer;
}

.bodyExpirationNote {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-top: 10px;
}
