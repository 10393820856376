.document-view .document-view-footer {
  background-color: var(--colour-neutral-grey-1);
  box-sizing: border-box;
  padding: 1em;
}

.document-view .document-view-footer.blue-submit {
  display: grid;
  grid-template-columns: 1fr 15em;
}

.document-view .document-view-footer.blue-submit b {
  align-self: center;
  font-size: 1.1em;
}

.document-view .document-view-footer.blue-submit button {
  justify-self: end;
}

.document-view .document-view-footer.blue-submit .document-view-footer-description {
  color: #8c8c8c;
}

.document-view > .document-view-footer.green-approve {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.document-view > .document-view-footer.green-approve div {
  display: flex;
  gap: 1em;
}

.document-view > .document-view-footer.green-approve button:not(:last-child) {
  background-color: #52c41a;
  color: var(--colour-neutral-grey-1);
}

.document-view > .document-view-footer.green-approve button:last-child {
  color: #ff4d4f;
}

.document-view .document-view-footer.blue-approve {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.document-view .document-view-footer.blue-approve > span {
  text-align: center;
}

.document-view .document-view-footer.blue-approve > .actions {
  display: inherit;
  gap: inherit;
  justify-content: center;
}
