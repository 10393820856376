.wrapper {
  display: flex;
  justify-content: space-between;
}

.name {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.logo {
  max-width: 160px;
  max-height: 60px;
  margin-bottom: 8px;
}
