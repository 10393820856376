.root {
  list-style: none;
  margin: 0;
  padding: 0;
}

.empty {
  padding: 1rem;
  text-align: center;
}

.date {
  padding: 0.5rem 1.5rem;
  background-color: var(--colour-neutral-grey-4);
}
