.stepsWrapper :global(.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot) {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: var(--widget-color);
  border: 2px solid var(--colour-neutral-grey-5);
  margin-left: 7px;
}

.stepsWrapper :global(.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot),
.stepsWrapper :global(.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot) {
  background-color: var(--main_blue_color);
  background-image: url('../../../../../assets/icons/check.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.stepsWrapper.stepsWrapperWinner
  :global(.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot) {
  background-color: var(--colour-green-6);
}

.stepsWrapper :global(.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after) {
  background-color: var(--main_blue_color);
}

.stepsWrapper.stepsWrapperWinner :global([step='decision'] .ant-steps-item-tail) {
  display: block;
}

.stepsWrapper.stepsWrapperWinner :global([step='decision'] .ant-steps-item-tail::after) {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(to right, #40a9ff, #52c41a);
  width: 85%;
  height: 2px;
  left: -85%;
}

.stepsWrapper :global(.ant-steps-item-finish .ant-steps-item-icon),
.stepsWrapper :global(.ant-steps-item-process .ant-steps-item-icon) {
  width: 24px;
  height: 24px;
  margin-left: 30px;
}

.stepsWrapper :global(.ant-steps-dot .ant-steps-item-tail) {
  top: 50%;
  transform: translateY(-50%);
  margin: 0 0 0 30px;
}

.stepsWrapper :global(.ant-steps-label-vertical .ant-steps-item-content) {
  margin: 0;
  width: 70px;
}

.stepsWrapper :global(.ant-steps-label-vertical .ant-steps-item) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepsWrapper :global(.ant-steps-dot .ant-steps-item-tail::after) {
  width: 100%;
}

.stepsWrapper :global(.ant-steps-item-icon .ant-steps-icon) {
  top: -1px;
}

.stepsWrapper :global(.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon),
.stepsWrapper :global(.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon) {
  top: 0;
}

.stepsWrapper :global(.ant-steps-dot .ant-steps-item-icon) {
  margin-left: 28px;
}
