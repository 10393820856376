.custom_text_area input {
  padding: 4px 12px 26px;
  font-size: 14px;
  line-height: 22px;
  color: var(--sea_weed);
}

.custom_text_area label {
  margin-bottom: 5px;
  display: block;
}

.custom_text_area_error input:focus,
.custom_text_area_error input {
  border: 1px solid var(--error);
}

.custom_text_area_error .ant-input:focus,
.custom_text_area_error .ant-input-focused {
  box-shadow: unset;
}

.custom_text_area_error .ant-input,
.custom_text_area_error .ant-input:hover {
  border: 1px solid var(--error);
}

.custom_text_area_error .error {
  font-size: 12px;
  line-height: 14px;
  color: var(--error);
}
