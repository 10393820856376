.wrapper__avatar {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 16px 48px;
  align-items: center;
  justify-content: start;
}

.wrapper__avatar .avatar_error {
  border: 1px solid var(--error);
}

.wrapper__avatar .avatar_upload {
  justify-self: baseline;
}

.wrapper__avatar .description {
  font-size: 14px;
  grid-column: 1/-1;
  line-height: 22px;
  color: #8c8c8c;
}

@media screen and (max-width: 992px) {
  .wrapper__avatar {
    grid-gap: 16px 32px;
  }
}
