.table :global(.ant-table-thead > tr > th) {
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
  background-color: rgba(250, 250, 250, 1) !important;
}

.table :global(.ant-table-thead > tr > th:first-child > *) {
  display: none;
}

.table :global(.ant-table-thead > tr > th),
.table :global(.ant-table-tbody > tr > td) {
  padding: 1rem !important;
}

.table :global(.ant-table > .ant-table-container > .ant-table-content > table),
.table :global(.ant-table > .ant-table-container > .ant-table-content > table > thead > tr > th) {
  border-color: rgba(0, 0, 0, 0.06) !important;
}

.actions {
  margin: 1rem 0;
}

.docName {
  display: flex;
  align-items: center;
  grid-gap: 0.625rem;
  margin-left: -0.5rem;
  max-width: 30vw;
}

.docName img,
.docName svg {
  width: 2rem;
  height: 2rem;
}
