.status-indicator-wrapper {
  display: flex;
  align-items: center;
}

.status-indicator-wrapper .status-indicator {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  margin-right: 8px;
}

.status-indicator-wrapper .status {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}
