.wrapper_template .rdw-editor-wrapper {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-top: 8px;
  min-height: 250px;
}

.wrapper_template .rdw-editor-wrapper .public-DraftStyleDefault-block {
  margin: 0;
}

.wrapper_template .rdw-editor-wrapper .rdw-editor-main {
  padding: 14px 14px 40px;
  overflow: unset;
}

.wrapper_template .rdw-editor-toolbar {
  margin-bottom: 0;
}

.wrapper_template.wrapper_template_input .rdw-editor-toolbar {
  display: none;
}

.wrapper_template.wrapper_template_no-toolbar .rdw-editor-toolbar {
  display: none;
}

.wrapper_template.wrapper_template_input .rdw-editor-wrapper {
  min-height: 50px;
}

.wrapper_template.wrapper_template_input .rdw-editor-wrapper .rdw-editor-main {
  padding: 14px;
}
