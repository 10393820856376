.wrapper {
  flex-shrink: 0;
  padding: 1rem;
  width: 100%;
  max-width: 210px;
  border: 1px solid rgba(240, 240, 240, 1);
}

.inner {
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
}

.inner :global(.ant-checkbox-group) {
  display: grid;
  grid-gap: 0.375rem;
}

.inner :global(.ant-select),
.inner :global(.ant-radio-group),
.inner :global(.ant-checkbox-group),
.inner :global(.ant-radio-group .ant-space) {
  width: 100%;
}

.inner :global(.ant-radio-wrapper),
.inner :global(.ant-checkbox-wrapper) {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  margin: 0;
}

.inner :global(.ant-radio-wrapper .ant-radio),
.inner :global(.ant-checkbox-wrapper .ant-checkbox) {
  margin-left: auto;
}

.inner :global(.ant-radio-wrapper span.ant-radio + *),
.inner :global(.ant-checkbox-wrapper span.ant-checkbox + *) {
  padding-left: 0;
}

.wrapper .title {
  font-size: 0.875rem;
}
