.section_title h3 {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #000;
}

.section_title h6 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.85);
}

.section_title .heading-20 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
  display: block;
}

.section_title .subheading-16 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0f172a;
}

.section_title .regular-24 {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
}

.section_title .body-14 {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.section_title .body-16 {
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.section_title.quote-loan-amount {
  padding-bottom: 14px;
  margin-bottom: 14px;
  border-bottom: 1px solid var(--white_smoke);
}
