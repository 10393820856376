.wrapper_user_sa_settings .settings {
  background: #fff;
  height: 100%;
  margin: 0 auto;
  border-radius: 12px 12px 0 0;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #cbd5e1;
}

.wrapper_user_sa_settings .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  padding-right: 30px;
}

.wrapper_user_sa_settings h3 {
  padding: 16px 21px;
}

.wrapper_user_sa_settings .ant-tabs > .ant-tabs-nav {
  margin-bottom: 0;
}

.wrapper_user_sa_settings .main {
  border-radius: 0 0 12px 12px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #cbd5e1;
  padding: 0 32px 24px;
}

.wrapper_user_sa_settings .ant-tabs-nav-list {
  padding: 16px 24px;
}
.wrapper_user_sa_settings .ant-tabs-tab {
  padding: 0;
}
@media screen and (max-width: 900px) {
  .wrapper_user_sa_settings {
    width: auto;
  }
}

@media screen and (max-width: 700px) {
  .wrapper_user_sa_settings {
    border: unset;
  }

  .wrapper_user_sa_settings .setting_profile_wrapper,
  .wrapper_user_sa_settings .ant-tabs-nav-wrap,
  .wrapper_user_sa_settings h3 {
    padding: 0;
  }
}

@media screen and (max-width: 450px) {
  .wrapper_user_sa_settings .setting_profile_wrapper__inputs {
    grid-gap: 16px;
    grid-template-columns: 1fr;
  }
}
