.green {
  color: #ffffff;
  background-color: var(--colour-green-5);
}

.yellow {
  color: #ffffff;
  background-color: var(--colour-gold-5);
}

.grey {
  color: #ffffff;
  background-color: var(--colour-neutral-grey-6);
}
