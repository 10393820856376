.root {
  grid-template-columns: 1fr 20em 20em;
}

.root > :global(.ant-table-wrapper) {
  grid-column: 1/4;
}

.columnSorter {
  cursor: pointer;
  display: flex;
}
