.title {
  color: #ffffff;
  text-transform: uppercase;
  padding: 8px;
  margin: 0;
}

.list {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-top: none;
}

.text {
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-top: none;
  padding: 6px 16px;
}

.text p {
  margin: 0;
}

.item {
  border-bottom: 1px dashed #f0f0f0;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sum {
  font-weight: 700;
}
