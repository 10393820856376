.subtitleWrapper * {
  color: var(--colour-neutral-grey-7);
}

.toolbar {
  border: none;
  border-bottom: 1px solid var(--colour-neutral-grey-4);
}

.editorWrapper {
  border: 1px solid var(--colour-neutral-grey-4);
}

.editor {
  min-height: 200px;
  max-height: 700px;
  overflow-y: auto;
  padding: 0 12px;
  resize: vertical;
  position: relative;
}

.inputWrapper {
  position: relative;
}

.inputWrapper :global(.rdw-link-decorator-icon) {
  display: none;
}

.inputWrapper :global(.ant-col-offset-1) {
  margin-left: 0;
}

.inputWrapper :global(.ant-col) {
  max-width: 100%;
}

.length {
  color: var(--colour-neutral-grey-8);
  font-size: 12px;
  font-weight: 500;
}
