textarea.error {
  border-color: #f00 !important;
}

.fullscreen-wrapper .wrapper_btn > div {
  cursor: pointer;
}

.fullscreen-wrapper .wrapper_btn .prev {
  transform: rotateY(180deg);
}

.fullscreen-wrapper {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  inset: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 100px;
}

.fullscreen-wrapper canvas {
  width: 100% !important;
  height: auto !important;
}

.fullscreen-wrapper .wrapper_btn {
  display: flex;
  justify-content: center;
  z-index: 10;
  position: fixed;
  bottom: 42px;
  left: 0;
  width: 100%;
}

.fullscreen-wrapper .wrapper_btn > span {
  color: white;
  padding: 0 24px;
  background: rgba(0, 0, 0, 0.4);
  height: 24px;
}

.fullscreen-wrapper .wrapper_btn {
  position: relative;
  align-items: center;
}

.fullscreen-wrapper .wrapper_btn .prev,
.fullscreen-wrapper .wrapper_btn .next {
  height: 24px;
  background: rgba(0, 0, 0, 0.4);
}

.fullscreen-wrapper .wrapper_btn .prev,
.fullscreen-wrapper .wrapper_btn .next {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.fullscreen-wrapper .wrapper_png_viewer,
.fullscreen-wrapper .fullscreen-pdf-wrapper {
  position: relative;
  max-height: 90vh;
  max-width: 90vw;
}

.fullscreen-wrapper .fullscreen-pdf-wrapper div,
.fullscreen-wrapper .fullscreen-pdf-wrapper img {
  max-width: 100%;
}

.fullscreen-wrapper .fullscreen-pdf-wrapper > svg {
  position: absolute;
  right: -32px;
  top: 0;
  padding: 4px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  transition: 0.3s background-color;
  cursor: pointer;
  z-index: 10;
}

.fullscreen-wrapper .fullscreen-pdf-wrapper > svg:hover {
  background: rgba(0, 0, 0, 0.6);
}

.fullscreen-wrapper .wrapper_btn .zoom-tools {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  display: flex;
  align-items: center;
}

.fullscreen-wrapper .wrapper_btn .zoom-tools span:not(.zoom-amount) {
  padding: 5px;
}

.fullscreen-wrapper .wrapper_btn .zoom-tools .zoom-amount {
  padding: 4px;
  width: 47px;
}

.fullscreen-wrapper .wrapper_btn .zoom-tools > * {
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  transition: 0.3s background-color;
}

.fullscreen-wrapper .wrapper_btn .zoom-tools > *:hover {
  background: rgba(0, 0, 0, 0.6);
}

.fullscreen-wrapper .wrapper_btn .zoom-tools > span {
  margin: 0 8px;
}

.fullscreen-pdf-wrapper .react-transform-wrapper {
  overflow: unset;
}

.select-wrapper .ant-select .ant-select-selector {
  border-radius: 4px !important;
}
