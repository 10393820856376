.filesWrap {
  display: flex;
  grid-gap: 0.5rem;
  padding: 1rem;
  color: var(--colour-neutral-grey-11);
  background-color: var(--colour-neutral-grey-4);
  border-radius: 4px;
}

.row :global(.message-time) {
  /*color: #8c8c8c !important;*/
}

.transparent {
  background-color: transparent;
}

.filesWrapColumn {
  flex-direction: column;
  align-items: flex-start;
}

.row {
  white-space: nowrap;
}

.filesWrap .row {
  width: calc(100% + 20px);
}
