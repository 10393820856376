.root {
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
}

.root > :global(img) {
  height: 100%;
  width: 100%;
}
