.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 684px;
  max-width: 100%;
  background-color: var(--widget-color);
  color: var(--nero_black);
  padding: 24px;
}

.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--nero_black);
  margin-bottom: 24px;
}

.subtitle {
  margin-bottom: 24px;
}

.mainContent {
  background-color: var(--colour-neutral-grey-2);
  border: 1px solid var(--colour-neutral-grey-4);
  padding: 16px;
  margin-bottom: 24px;
}

.descriptionList {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--colour-neutral-grey-5);
  padding-bottom: 8px;
  flex-direction: column;
  height: 145px;
  overflow-x: auto;
}

.descriptionListItem {
  width: 50%;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 8px;
}

.descriptionListItem span {
  font-weight: 700;
  margin-left: 8px;
}

.contacts {
  display: flex;
  background-color: var(--widget-color);
  border: 1px solid var(--colour-neutral-grey-5);
  padding: 12px;
}

.contacts svg {
  transition: stroke 0.2s ease-in-out;
}

.contacts button:hover svg {
  stroke: var(--colour-brand-5);
}

.contactsInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contactsName {
  color: var(--nero_black);
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.contactsPosition {
  font-size: 12px;
  line-height: 22px;
  color: var(--colour-neutral-grey-7);
}

.contactsLink {
  color: var(--colour-neutral-grey-11);
}

.contactsLinkWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.contactsLinkWrapper:last-child {
  margin-bottom: 0;
}

.buttons {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
}

.footer {
  display: flex;
  gap: 18px;
}

.footerText {
  margin-bottom: 0;
}
