.input_container .wrapper_input {
  position: relative;
  margin-top: 8px;
}

.input_hint {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
}

.input_hint p,
.input_hint .wrapper_input {
  grid-column: 1/-1;
}

.input_container .hint {
  position: relative;
  justify-self: end;
}

.input_container .hint:hover .tool_tip {
  display: block;
}

.input_container .tool_tip {
  border-radius: 2px;
  display: none;
  right: 0;
  bottom: 30px;
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  padding: 8px 6px;
  max-width: 200px;
  z-index: 10;
  width: 200px;
}

/*.input_container .tool_tip:before {*/
/*    position: absolute;*/
/*    background: rgba(0, 0, 0, 0.75);*/
/*    content: '';*/
/*    width: 8px;*/
/*    height: 8px;*/
/*    right: 2px;*/
/*    bottom: -3px;*/
/*}*/

.input_container .hint .tool_tip span {
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.input_show_icon .wrapper_input .show_pass_icon {
  position: absolute;
  top: 50%;
  right: 12px;
  cursor: pointer;
  transform: translateY(-50%);
}

.input_show_icon input {
  padding: 11px 40px 11px 12px;
}

.input_title {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.hidden_title_wrapper {
  position: relative;
  height: 22px;
}

.hidden_title_wrapper:after {
  content: attr(data-hidden-title);
  left: 0;
  bottom: 0;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.custom_input:not(.ant-input-group-wrapper) {
  padding: 11px 12px;
  width: 100%;
  background: #ffffff;
  border: 1px solid var(--light_gray);
  transition: 0.4s;
  border-radius: 4px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.input_error .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.input_error:not(.drop_down_wrapper) .custom_input,
.input_error .react-tel-input .form-control {
  border: 1px solid var(--error) !important;
}

/*.input_error .input_title {*/
/*    color: var(--error);*/
/*}*/

.errorMessage {
  font-size: 12px;
  line-height: 14px;
  color: var(--error);
  margin: 4px 0 0;
  grid-column: 1/-1;
}

.custom_input:focus {
  border: 1px solid var(--awesome_purple);
}

.custom_input::placeholder {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.25);
}

.input_container .input_descpription {
  font-size: 14px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.54);
  margin: 4px 0 0;
}

.custom_text_area .ant-input,
.input_container .ant-input {
  padding: 9px 16px;
  border-radius: 4px;
  text-overflow: ellipsis;
}
