.root {
  align-items: center;
  background-color: var(--colour-neutral-grey-4);
  display: grid;
  gap: 1em;
  grid-column: 1/3;
  grid-template-columns: 2.3em 1fr 7em;
  padding: 1em 2em;
}

.root > :global(svg) {
  background-color: var(--colour-neutral-grey-7);
  border-radius: 50%;
  padding: 0.25em;
}
