.onboarding_header {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.08), 0 0.5px 2px rgba(96, 97, 112, 0.16);
  transition: left 0.4s;
  z-index: 100;
}

@media screen and (max-width: 899px) {
  .onboarding_header {
    justify-content: space-between;
  }
}

.onboarding_header_full_size {
  left: 56px;
}

.onboarding_header_full_w {
  width: 100%;
}

.onboarding_header .wrapper_user_avatar,
.wrapper_user_avatar,
.drawer-user .avatar-image,
.user-preview-wrapper img,
.registration-header .wrapper_user_avatar {
  position: relative;
  width: 32px;
  border-radius: 50%;
  height: 32px;
  justify-self: end;
  cursor: pointer;
  object-fit: cover;
}

.wrapper_lar_application .pla .wrapper_user_avatar {
  width: 48px;
  height: 48px;
}

.registration-header .wrapper_user_avatar .avatar,
.wrapper_user_avatar .avatar,
.user-preview-wrapper .avatar,
.drawer-user .avatar,
.logo-row .avatar,
.onboarding_header .wrapper_user_avatar .avatar {
  height: 32px;
  width: 32px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light_gray);
}

.wrapper_lar_application .pla .wrapper_user_avatar .avatar {
  width: 48px;
  height: 48px;
}

.logo-row .avatar {
  margin-right: 16px;
  width: 48px;
  height: 48px;
}

.onboarding_header .wrapper_user_avatar img,
.wrapper_user_avatar img,
.registration-header .wrapper_user_avatar img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: inherit;
}

.onboarding_header .wrapper_user_avatar .wrapper_options_popup,
.registration-header .wrapper_user_avatar .wrapper_options_popup {
  right: 0;
}

.onboarding_header-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

.onboarding_header-actions__action-item {
  display: inline-block;
  width: 40px;
  position: relative;
  cursor: pointer;
}

.onboarding_header-actions__action-item > svg {
  vertical-align: middle;
}

.action-item-count {
  position: absolute;
  top: -5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.action-item-count__text {
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  padding: 0 6px;
  text-align: right;
  color: #ffffff;
  max-width: 53px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-item-count_red {
  background-color: #ff4d4f;
}

.action-item-count_blue {
  background-color: #1f3047;
}

.onboarding_header-actions__action-link:nth-child(2n) {
  margin-left: 24px;
}

.onboarding_header-actions__alerts-wrapper {
  margin-right: 24px;
}

@media screen and (max-width: 700px) {
  .onboarding_header {
    grid-template-columns: max-content 1fr;
  }

  .onboarding_header .mob_menu_header {
    left: -100vw;
    right: 100vw;
    position: fixed;
    background: #1f2c5f;
    transition: right 0.2s;
    top: 56px;
    bottom: 0;
  }

  .onboarding_header .mob_menu_header .nav_bar_link {
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 24px;
    padding: 14px;
    text-decoration-line: unset;
  }

  .onboarding_header .mob_menu_header .nav_bar_link:last-child {
    margin-bottom: 40px;
  }

  .onboarding_header .mob_menu_header .nav_bar_link:active {
    color: unset;
  }

  .onboarding_header .mob_menu_header .nav_bar_link span {
    font-size: 16px;
    line-height: 155%;
    color: #fafafa;
    width: max-content;
    text-decoration: unset;
  }

  .onboarding_header .mob_menu_header .nav_bar_active_link {
    background: #101c50;
  }

  .onboarding_header .burger_menu_wrapper {
    cursor: pointer;
    content: '';
    width: 33px;
    height: 22px;
    margin: auto 0;
    position: relative;
  }

  .onboarding_header .burger_menu_wrapper .burger_menu:before,
  .onboarding_header .burger_menu_wrapper .burger_menu,
  .onboarding_header .burger_menu_wrapper .burger_menu:after {
    position: absolute;
    width: 100%;
    height: 2px;
    content: '';
    display: block;
    background: #8c8c8c;
    top: 0;
  }

  .onboarding_header .burger_menu_wrapper .burger_menu:before {
    top: 8px;
  }

  .onboarding_header .burger_menu_wrapper .burger_menu:after {
    top: 16px;
  }

  .onboarding_header .mob_menu_header_active {
    left: 0;
    right: 0;
    top: 56px;
    bottom: 0;
  }

  .onboarding_header {
    left: 0;
  }
}
