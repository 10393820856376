.wrapper_onboarding__main_content > .chat-wrapper {
  margin: auto !important;
  overflow-y: hidden;
  padding: 0;
  height: 100%;
}

.chat-wrapper .ant-input-group-addon {
  background-color: white;
}

.chat-wrapper {
  display: grid;
  grid-template-columns: minmax(auto, 500px) 3fr;
  margin: -1.5rem -1.5rem 0;
}

.chat-footer .anticon,
.chat-header > img,
.chat-header .anticon {
  cursor: pointer;
}

.chat-users-wrapper .chat-header > img {
  margin: 0 12px;
}

.chat-wrapper .chat-users-wrapper {
  background: #fafafa;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  z-index: 10;
  overflow: hidden;
}

.chat-wrapper.show-archive .chat-users-wrapper {
  padding-top: 0;
}

.chat-wrapper .chat-users-wrapper .close-archive {
  display: flex;
  align-items: center;
  margin: 12px 0;
  cursor: pointer;
}

.chat-wrapper .chat-users-wrapper .close-archive img {
  margin: 0 16px 0 12px;
}

.chat-header {
  display: grid;
  grid-template-columns: 1fr 60px;
  align-items: center;
}

.chat-sidebar-wrapper .chat-header {
  grid-template-columns: max-content 1fr 60px;
}

.chat-wrapper .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.chat-wrapper .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  padding: 0 24px;
  width: 100%;
  overflow: auto;
}

.chat-wrapper .ant-tabs-ink-bar {
  display: none !important;
}

.chat-wrapper .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1f2c5f;
}

.chat-wrapper .ant-tabs-tab.ant-tabs-tab-active::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #1f2c5f;
  bottom: 0;
  left: 0;
}

.chat-wrapper .ant-tabs > .ant-tabs-nav {
  margin-bottom: 0;
  background-color: white;
  border-top: 1px solid #f5f5f5;
}

.chat-wrapper .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid #d9d9d9;
}

.chat-wrapper .ant-tabs-tab {
  padding: 6px 0;
}

.chat-wrapper .chat-tabs .chat-user-group .chat-user-group-header {
  padding: 6px 24px;
  background: #fafafa;
}

.chat-wrapper .chat-tabs .chat-user-group-list {
  display: flex;
  flex-direction: column;
}

.chat-wrapper .chat-tabs .chat-user-group-list .chat-row {
  padding: 8px 16px;
  border-bottom: 1px solid #d9d9d9;
  background-color: white;
  cursor: pointer;
}

.chat-wrapper .chat-tabs .chat-user-group-list .active {
  background-color: var(--colour-neutral-grey-3);
}

.chat-wrapper .chat-tabs .chat-user-group-list .chat-row .chat-row-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.chat-wrapper .chat-tabs .chat-user-group-list .chat-row .chat-row-info > div .name,
.chat-wrapper .chat-tabs .chat-user-group-list .chat-row .chat-row-info > div .message {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.chat-wrapper .chat-tabs .chat-user-group-list .chat-row .chat-row-info .chat-row-top .date {
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
}

.chat-wrapper .ant-tabs-tabpane {
  height: calc(100vh - 196px);
  overflow: auto;
}

.chat-wrapper.show-archive .ant-tabs-tabpane {
  height: calc(100vh - 184px);
}

.chat-messaging {
}

.chat-messaging .forward-bg {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 6;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s opacity;
}

.chat-wrapper.active-forward:not(.chat-sidebar-wrapper) .chat-messaging .forward-bg {
  opacity: 1;
  pointer-events: all;
}

.chat-messaging.active-chat {
  transform: translateX(0);
  opacity: 1;
  pointer-events: all;
}

.chat-messaging .chat-header {
  background: #fafafa;
  display: grid;
  align-items: center;
  grid-template-columns: 40px 1fr 24px 24px;
  padding: 0 24px 0 24px;
  grid-gap: 16px;
}

.chat-messaging .chat-header > a {
  display: flex;
}

.chat-messaging .chat-header .chat-header-info {
  display: flex;
  flex-direction: column;
}

.chat-messaging .chat-header .chat-header-info .last-online {
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
  margin-left: 18px;
}

.chat-messaging .chat-header .chat-header-info .role {
  margin-top: 4px;
  background: #fffbe6;
  border: 1px solid #fffbe6;
  border-radius: 2px;
  font-size: 12px;
  line-height: 20px;
  color: #faad14;
  padding: 1px 8px;
  width: max-content;
}

.chat-messaging .chat-body {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  flex-grow: 1;
  padding: 77px 0 10px;
  margin-top: 2px;
}

.chat-wrapper:not(.chat-sidebar-wrapper) .chat-body {
  z-index: 1;
}

.chat-messaging .chat-body .message-row {
  width: 100%;
  margin-top: 6px;
  padding: 0 17px 0 32px;
  display: flex;
}

.message-actions .ant-dropdown-menu-title-content .anticon {
  margin-right: 8px;
}

.chat-messaging .chat-body .message-row > .anticon {
  opacity: 0;
  transition: 0.3s opacity;
  height: max-content;
}

.chat-messaging .chat-body .message-row > .anticon.ant-dropdown-open,
.chat-messaging .chat-body .message-row:hover > .anticon {
  opacity: 1;
  cursor: pointer;
}

.chat-messaging .chat-body .message-row.my-message {
  justify-content: end;
}

.chat-messaging .chat-body .message-row .message-column {
  display: flex;
  flex-direction: column;
  max-width: 324px;
}

.chat-messaging .chat-body .message-row .message-column .message-time {
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
  margin-top: 7px;
  text-align: right;
}

.chat-messaging .chat-body .message-row:not(.my-message) .message-column .message-text {
  background: #f0f0f0;
  border-radius: 4px;
}

.chat-messaging .chat-body .message-row.my-message .message-column .message-time {
  text-align: left;
}

.chat-messaging .chat-body .message-row.my-message .message-column .message-text {
  background: #1f2c5f;
  color: #ffffff;
  border-radius: 4px;
}

.chat-messaging .chat-body .message-row.my-message .message-column .message-text .messages-author {
  color: #ffffff;
}

.chat-messaging .chat-body .message-row .message-column .message-text.reply-message {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  padding: 8px 12px;
}

.chat-messaging .chat-body .message-row .message-column .message-text.reply-message b {
  margin-bottom: 4px;
}

.chat-messaging .chat-body .message-row .message-column .message-text .reply-icon {
  position: absolute;
  top: 4px;
  right: 4px;
}

.chat-messaging .chat-body .message-row .message-column .message-text {
  position: relative;
  padding: 8px 12px;
  word-break: break-word;
  color: var(--colour-neutral-grey-8);
}

.messages-author {
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  color: #1f1f1f;
  margin-bottom: 0;
}

.chat-messaging .chat-footer {
  border-top: 1px solid #f5f5f5;
  position: relative;
  z-index: 5;
  background-color: white;
  padding: 12px;
}

.chat-messaging .chat-footer .anticon {
  transition: 0.3s opacity;
}

.chat-messaging .chat-footer .action {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 24px;
}

.chat-messaging .chat-footer .anticon.hidden {
  opacity: 0;
  pointer-events: none;
}

.chat-messaging .chat-footer input {
  width: 100%;
  margin: 0;
  height: 100%;
  border: none;
  padding: 17px 17px 17px 68px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: #434343;
  background-color: #f0f0f0;
}

.recording-wrapper {
  display: flex;
  align-items: center;
  margin: 0 auto 0 64px;
}

.recording-wrapper .anticon,
.recording-wrapper .recording-stop,
.recording-wrapper svg {
  cursor: pointer;
}

.recording-wrapper > * + * {
  margin-left: 8px;
}

.recording-wrapper .recording-info {
  background: #f0f0f0;
  border-radius: 24px;
  padding: 9px 24px;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
}

.recording-wrapper .recording-stop {
  width: 40px;
  height: 40px;
  background: #ff4d4f;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  border-radius: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recording-wrapper .recording-stop > div {
  border: 2px solid #ffffff;
  height: 14px;
  width: 14px;
}

.chat-footer .recorded-audio {
  height: 40px;
}

.reply-wrapper .reply-icon {
  display: flex;
  flex-direction: column;
  padding: 15px 24px 0 0;
  margin-left: auto;
}

.reply-wrapper .reply-icon > span:not(.anticon) {
  font-size: 12px;
  line-height: 20px;
  color: var(--main-blue-color);
}

.chat-wrapper.chat-sidebar-wrapper {
  position: fixed;
  display: flex;
  z-index: 1000;
  pointer-events: none;
  inset: 0;
}

.chat-wrapper.chat-sidebar-wrapper.active-sidechat > * {
  pointer-events: all;
}

.chat-wrapper.chat-sidebar-wrapper .chat-users-wrapper {
  transform: translateX(100%);
  transition: 0.3s transform;
}

.chat-wrapper.chat-sidebar-wrapper > * {
  position: absolute;
  width: 450px;
  height: 100vh;
  bottom: 0;
  right: 1.5rem;
}

.chat-wrapper.chat-sidebar-wrapper.active-sidechat:not(.active-forward) .chat-messaging.active-chat {
  transform: translateX(0);
}

.chat-wrapper.sidebar-messages .chat-users-wrapper {
  transform: translateX(0);
}

.chat-wrapper.chat-sidebar-wrapper .chat-users-wrapper > * {
  pointer-events: all;
}

.chat-wrapper.hidden {
  grid-template-columns: 0 1fr;
}

.chat-wrapper.chat-sidebar-wrapper .chat-messaging {
  z-index: 20;
  transform: translateX(100%);
}

.chat-wrapper.chat-sidebar-wrapper .sidebar-chat-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
  transition: 0.3s background-color;
  background-color: rgba(0, 0, 0, 0);
}

.chat-wrapper.chat-sidebar-wrapper.active-sidechat .sidebar-chat-bg {
  background-color: rgba(0, 0, 0, 0.2);
}

.chat-wrapper.chat-sidebar-wrapper .recording-wrapper:not(.validation) {
  margin: 0 64px 0 20px;
}

.chat-wrapper.chat-sidebar-wrapper .recording-wrapper.validation {
  margin: 0 8px 0 64px;
}

.chat-wrapper.chat-sidebar-wrapper .chat-messaging .chat-header {
  grid-template-columns: 36px 40px 1fr 24px 24px;
}

.chat-header-top .name {
  cursor: pointer;
}

.chat-body-title {
  width: calc(100% - 32px);
  padding: 8px 14px 14px;
  position: fixed;
  top: 136px;
  left: 16px;
  right: 16px;
  z-index: 1;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
  text-align: center;
}

.chat-sidebar-wrapper .chat-body-title {
  width: 100%;
  left: 0;
  right: 0;
}

.chat-sidebar-wrapper .chat-messaging {
  box-shadow: -5px 4px 13px rgba(0, 0, 0, 0.1);
}

.chat-body-title-time {
  background-color: #f0f0f0;
  border-radius: 32px;
  color: #595959;
  font-size: 0.9rem;
  line-height: 22px;
  margin: 1.5rem auto 0 auto;
  padding: 4px 8px;
  width: max-content;
}

@media screen and (max-width: 750px) {
  .chat-wrapper.chat-sidebar-wrapper > * {
    right: 0;
  }

  .chat-wrapper {
    grid-template-columns: 1fr;
    position: relative;
  }

  .chat-messaging.active-chat {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
  }

  .chat-messaging .chat-header {
    margin-top: 10px;
    grid-template-columns: 25px 40px 1fr 24px;
  }

  .chat-messaging {
    grid-template-rows: auto 1fr 61px;
  }

  .chat-header-top {
    display: flex;
    flex-direction: column;
  }

  .chat-messaging .chat-header .chat-header-info .last-online {
    margin-left: 0;
  }

  .chat-wrapper:not(.chat-sidebar-wrapper) .chat-body {
    height: calc(100vh - 218px);
  }

  .chat-wrapper {
    margin: -1.5rem 0 0;
  }
}

@media (max-width: 450px) {
  .chat-wrapper.chat-sidebar-wrapper > * {
    width: 100%;
  }
}
