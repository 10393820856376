.ant-btn-text.icon-button {
  padding: 4px;
  background: transparent;
  border: none;
  cursor: pointer;
  height: auto;
}

.ant-btn-text.icon-button span {
  display: none;
}

.ant-btn-text .icon-button-text {
  margin-left: 8px;
  margin-right: 16px;
  font-weight: 500;
  display: inline-block;
}

.ant-btn-text.icon-button-blue .icon-button-text {
  color: #40a9ff;
}

.ant-btn-text.simple-button {
  min-width: 115px;
  padding: 5px 8px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 0;
}

.ant-btn-text.quote-action-button {
  padding: 8px 16px;
  min-height: 40px;
  max-height: 40px;
  color: #1f1f1f;
}

.ant-btn-text.simple-button.accent,
.ant-btn-text.quote-action-button.accent {
  background-color: #389e0d;
  border-color: #389e0d;
  color: #ffffff;
}

.ant-btn-text.quote-action-button img {
  margin-right: 8px;
}

.ant-btn-text.simple-button.main {
  color: #ffffff;
  background-color: #40a9ff;
  border-color: #40a9ff;
}

.ant-btn-text.icon-button-with-bg {
  background-color: var(--main-divider-gray-color2);
  border: 1px solid var(--main-divider-gray-color);
  padding: 5px 7px;
}

.ant-btn-text.slider-arrow {
  padding: 6px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
}

.ant-btn-text.slider-arrow.active {
  border-color: #40a9ff;
}

.btn.btn_icon-container {
  border: none;
  background: transparent;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.ant-btn-text.selection-process-button {
  height: 40px;
  margin-right: 0;
  width: 100%;
}

.ant-btn-text.selection-process-button-winner {
  background-color: var(--colour-green-2);
  border-color: var(--colour-green-6);
  justify-content: flex-start;
  position: relative;
  color: var(--colour-green-7);
}

.ant-btn-text.selection-process-button-winner::after {
  content: '';
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url('../icons/chevron-right-green.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
}

.ant-btn-text.selection-process-button-winner:hover {
  background-color: var(--colour-green-5);
  color: var(--colour-green-8);
}

.ant-btn-text.simple-button.full-width {
  width: 100%;
}

.ant-btn-text.simple-button.full-width.without-x-borders {
  border-left: none;
  border-right: none;
}

.ant-btn-text.simple-button.absolute-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
}

.ant-btn-text.simple-button.dark {
  background-color: var(--main_dark_blue_color);
  border-color: var(--main_dark_blue_color);
  color: var(--widget-color);
}

.ant-btn-text.simple-button.dark:hover {
  background-color: var(--main_blue_color);
  border-color: var(--main_blue_color);
  color: var(--widget-color);
}

.ant-btn-text.simple-button:disabled {
  background-color: var(--colour-neutral-grey-7);
  border-color: var(--colour-neutral-grey-7);
}

.ant-btn-text.simple-button:disabled:hover {
  background-color: var(--colour-neutral-grey-7);
  color: var(--widget-color);
}

.ant-btn-text.simple-button.without-border {
  border-color: transparent;
}

.ant-btn-text.simple-button.large {
  padding: 9px 24px;
  height: 40px;
}

.ant-btn-text.simple-button.max-content {
  min-width: max-content;
}

a.ant-btn {
  padding-bottom: 0.01px;
}
