#diligence-list {
  display: grid;
  gap: 1em;
  grid-template-columns: 25em 1fr 25em 25em;
}

#diligence-list > button {
  width: 100%;
}

#diligence-list > button:first-of-type {
  grid-column: 3;
}

#diligence-list > button:last-of-type {
  grid-column: 4;
}

#diligence-list > .useless-universal-table-wrapper {
  grid-column: 1/5;
}

#diligence-list .diligence-expand {
  background: var(--colour-neutral-grey-3);
  padding: 16px 32px;
}

#diligence-list .masterlist {
  display: block;
  font-size: 16px;
  margin: 12px 0;
}

#diligence-list .masterlist > * {
  font-weight: 600;
}

#diligence-list .diligence-expand > div {
  max-width: 870px;
}

#diligence-list .diligence-expand .expand-col {
  display: flex;
  flex-direction: column;
}

#diligence-list .diligence-expand .expand-col span,
#diligence-list .diligence-expand .expand-row {
  margin-top: 8px;
}

#diligence-list .diligence-expand .expand-row span {
  margin-left: 8px;
}

#diligence-list .universal-table__header {
  position: relative;
}

#diligence-list > .custom-search {
  width: 100%;
}

.diligence-table .universal-table__header {
  padding: 8px 0;
}

.universal-table_default-padding.diligence-table
  .ant-table.ant-table-middle
  .ant-table-tbody
  .ant-table-expanded-row
  td {
  padding: 0 !important;
}
.ant-message-notice.diligence-list__selected-items-msg .ant-message-notice-content {
  padding: 9px;
}
