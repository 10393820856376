.root {
  background-color: var(--colour-neutral-grey-2);
}

.block {
  background-color: #fff;
  border: 1px solid var(--colour-neutral-grey-5);
  max-width: 45em;
  padding: 1em;
}

.spinWrapper > :global(.ant-spin-container) {
  display: grid;
  gap: 1em;
}

.spinWrapper > :global(.ant-spin-container > h1) {
  font-size: 1.4em;
  margin: 0;
}

.goodNews,
.badNews {
  display: flex;
  gap: 0.5em;
  padding: 0.5em;
}

.goodNews {
  background-color: var(--colour-green-2);
  color: var(--colour-green-8);
}

.badNews {
  background-color: var(--colour-gold-2);
  color: var(--colour-gold-7);
}

.basicData {
  border-bottom: 1px solid var(--colour-neutral-grey-5);
  color: var(--colour-neutral-grey-11);
  display: grid;
  font-weight: 500;
  gap: 1em;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 1em;
}

.basicData > :global(:nth-child(even)) {
  text-align: right;
}

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.form > :global(*) {
  margin-bottom: 0;
}

.form :global(.ant-radio-group) {
  padding-top: 0.25em;
}

.form :global(.ant-radio-group > label) {
  display: block;
}

.scheduleGrid {
  background-color: var(--colour-neutral-grey-3);
  border: 1px solid var(--colour-neutral-grey-5);
  display: grid;
  gap: 1em;
  grid-template-columns: 3fr 2fr;
  padding: 1em;
}

.scheduleGrid > :global(:nth-child(1)),
.scheduleGrid > :global(:nth-child(2)) {
  color: var(--colour-neutral-grey-7);
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

.timeWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
