.table_with_header_wrapper .section_title_wrapper {
  align-items: baseline;
  display: grid;
  grid-template-columns: max-content 1fr;
  margin: 0 0 1.5rem;
}

.table_with_header_wrapper .section_title_wrapper button {
  justify-self: end;
}
.table_with_middle-align .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.table_with_middle-align .ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  vertical-align: middle !important;
}
.table_with_header_wrapper .section_table_wrapper {
  margin-top: 24px;
}

.table_with_header_wrapper .section_table_wrapper .ant-table-container table > thead > tr:first-child th {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #1f1f1f;
}

.user-role .ant-select-single .ant-select-selector {
  border: none;
}

.table_with_header_wrapper .section_table_wrapper button:not(.btn_in_table):not(.btn_darkblue) {
  background: #fff;
  border: 1px solid #d9d9d9;
  /*border-radius: 2px 0 0 2px;*/
}

.table_with_header_wrapper .section_table_wrapper .ant-table table {
  border: unset;
}

.table_with_header_wrapper .section_table_wrapper .ant-table .ant-table-title {
  padding: 4px 0;
}

.table_with_header_wrapper .section_table_wrapper .ant-tabs-top > .ant-tabs-nav,
.table_with_header_wrapper .section_table_wrapper .ant-tabs-bottom > .ant-tabs-nav,
.table_with_header_wrapper .section_table_wrapper .ant-tabs-top > div > .ant-tabs-nav,
.table_with_header_wrapper .section_table_wrapper .ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 0;
  padding: 0 24px;
}

.table_with_header_wrapper .section_table_wrapper .ant-space-item .status {
  padding: 2px 8px;
  /*border-radius: 24px;*/
  margin-bottom: 0;
  white-space: nowrap;
}
.borrowers-user-status {
  padding: 2px 8px;
  margin-bottom: 0;
  white-space: nowrap;
}

.table_with_header .ant-table {
  border: 1px solid var(--main-divider-gray-color);
  border-radius: 0;
}

.table_with_header_wrapper .section_table_wrapper .ant-space {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table_with_header_wrapper .section_table_wrapper .ant-space .wrapper_right_side_panel {
  display: flex;
  margin: 0 -8px;
}

.table_with_header_wrapper .section_table_wrapper .ant-space .wrapper_right_side_panel > * {
  margin: 0 8px;
  align-self: center;
}

.table_with_pagination_wrapper .table_with_pagination .pagination_btn {
  padding: 8px 24px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
  transition: 0.4s;
}

.masterlist-add .ant-radio-group {
  display: flex;
  flex-direction: column;
}

.masterlist-add .ant-radio-group > * + * {
  margin-top: 12px;
}

.table_with_pagination_wrapper .table_with_pagination .ant-pagination-disabled {
  display: none;
}

.table_with_pagination_wrapper .table_with_pagination .ant-pagination-item {
  border: unset;
}

.table_with_pagination_wrapper .table_with_pagination .ant-pagination-item a {
  color: #000;
  font-weight: 600;
}

.table_with_pagination_wrapper .table_with_pagination .ant-pagination-item-active {
  border: 1px solid var(--main-darkblue-color);
  color: var(--main-darkblue-color);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
}

.table_with_header_wrapper .section_table_wrapper .ant-table-cell .users_actions {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  grid-gap: 8px;
  justify-content: end;
}

.section_table_wrapper thead th {
  white-space: nowrap;
}

@media (max-width: 568px) {
  .table_with_header_wrapper .section_title_wrapper {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin: 0 0 1.5rem;
  }
}
