/*
	I have no idea why the file with styles for notes drawer
	is called lar_app_card.css but I CBA to reorganise this stuff
*/

.drawer-wrapper.notes .drawer-header {
  padding: 24px;
}

.drawer-wrapper.notes .notes-list .note-group-header {
  background: #f5f5f5;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
}

.drawer-wrapper.notes .notes-list > .note-group + .note-group {
  margin-top: 22px;
}

.drawer-wrapper.notes .notes-list {
  display: flex;
  flex-direction: column;
  padding: 0 0 22px;
}

.drawer-wrapper.notes .notes-list .note-group-body {
  padding: 0 24px;
}

.drawer-wrapper.notes .drawer-header .btn_default {
  font-size: 14px;
  margin-left: auto;
}

.drawer-wrapper.notes .drawer-header .lar-identifier {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 158px;
  white-space: nowrap;
  display: block;
}

.drawer-wrapper.notes .drawer-header .lar-identifier.long {
  max-width: 250px;
}

.drawer-wrapper.notes .drawer-header .btn_default span {
  margin-left: 0;
}

.drawer-wrapper.notes .notes-list .note-wrapper {
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  padding: 8px 8px 15px;
  background: #fafafa;
  border-radius: 12px;
  position: relative;
}

.drawer-wrapper.notes .notes-list .note-wrapper .notes-action {
  position: absolute;
  top: 16px;
  right: 16px;
}

.drawer-wrapper.notes .notes-list .note-wrapper .notes-action img {
  cursor: pointer;
}

.drawer-wrapper.notes .notes-list .note-wrapper .notes-action > * + * {
  margin-left: 8px;
}

.wrapper_prospect_delete .note-text {
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  padding: 16px;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  margin-top: 16px;
  font-weight: normal;
  width: 100%;
}

.drawer-wrapper.notes .notes-list .note-wrapper .time-created {
  font-size: 12px;
  line-height: 20px;
  color: #40a9ff;
}

.drawer-wrapper.notes .notes-list .note-wrapper .note-creator {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.drawer-wrapper.notes .notes-list .note-wrapper .avatar {
  width: 26px;
  height: 26px;
  background: #ffffff;
  border-radius: 99px;
}

.drawer-wrapper.notes .notes-list .note-wrapper .creator-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  margin-left: 8px;
}

.drawer-wrapper.notes .notes-list .note-wrapper .note-text {
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  margin-top: 4px;
}

.drawer-wrapper.create-note .create-note-footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 48px;
  padding: 0 24px;
}

.drawer-wrapper.create-note .create-note-footer .buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
}

.drawer-wrapper.create-note .create-note-footer > span {
  margin-left: auto;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}

.drawer-wrapper.notes {
  grid-template-rows: max-content 1fr;
  height: calc(100% - 56px);
}
