.root {
  display: grid;
  grid-template-columns: 1fr 20em;
  gap: 1em;
}

.tableWrapper {
  grid-column: 1/-1;
}

.tableColumnHeader {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.tableColumnHeaderWithoutSorting {
  cursor: auto;
}
