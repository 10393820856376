.create_user_wrapper > .navigation {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 8px;
  align-items: center;
  margin: 0 0 1rem;
}

.create_user_wrapper > .navigation span,
.create_user_wrapper > .navigation a {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}

.create_user_wrapper > .navigation span.active {
  color: rgba(0, 0, 0, 0.85);
}

.create_user_wrapper > h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #0f172a;
  margin: 0 0 1.75rem;
}

.create_user_wrapper > .content {
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  padding: 22px 1.25rem 1.5rem;
  max-width: 485px;
}

.create_user_wrapper > .content .inputs {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  align-items: center;
  margin: 0 0 1.25rem;
}

.create_user_wrapper > .content .inputs .inline {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  align-items: start;
}

.create_user_wrapper > .content form > p {
  font-size: 14px;
  line-height: 22px;
  color: #434343;
  margin: 0 0 1.25rem;
}

.create_user_wrapper > .content .buttons_wrap {
  display: grid;
  grid-template-columns: max-content;
  justify-content: end;
  align-items: center;
}

.create_user_wrapper > .content .buttons_wrap_edit {
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
}

.create_user_wrapper > .content .buttons_wrap .btn_default {
  font-size: 14px;
  line-height: 22px;
}
