.root {
  display: flex;
  border: 1px solid var(--colour-neutral-grey-6);
}

.timerTop {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  padding: 0.25rem 1rem;
  font-size: 0.875rem;
}

.timerTime {
  font-weight: 500;
  font-size: 1.25rem;
}

.timerLeft {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  text-align: center;
  font-weight: 500;
  color: var(--colour-neutral-grey-11);
  background-color: var(--colour-neutral-grey-3);
  border-right: 1px solid var(--colour-neutral-grey-6);
}

.gray.timerLeft {
  background-color: var(--colour-neutral-grey-5);
}

.red.timerLeft {
  background-color: var(--colour-red-5);
}

.green.timerLeft {
  background-color: var(--colour-green-5);
}

.yellow.timerLeft {
  background-color: var(--colour-gold-5);
}
