.drawer p {
  margin: 0px;
}
.drawerWrapper {
  overflow: auto;
}

.drawerHeader {
  background-color: #fafafa;
  border-bottom: 1px solid #d9d9d9;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.headerTitle {
  font-size: 16px;
  font-weight: 600;
  font-family: Inter;
  position: absolute;
  right: 50%;
  transform: translate(50%, 0);
}

.section {
  padding: 16px;
  border-bottom: 1px solid #d9d9d9;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--colour-neutral-grey-11);
}

.subTitle {
  line-height: 22px;
  color: var(--colour-neutral-grey-11);
}

.section div.ant-form-item {
  background-color: red;
}
