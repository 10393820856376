.root {
  min-width: 280px;
  flex-shrink: 0;
  border-right: 1px solid var(--colour-neutral-grey-5);
}

.placeholder {
  padding: 1em;
  text-align: center;
}
