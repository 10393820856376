.contactCardsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 1em;
}

.contactCard,
.contactCardForm {
  border: 1px solid var(--colour-neutral-grey-5);
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1.1em;
}

.contactCard {
  padding-right: 4.8em;
  position: relative;
}

.editButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}

.contactCardFormButtonsWrapper {
  display: flex;
  gap: 1em;
  justify-content: flex-end;
}
