.borrowers_wrapper > .section_title_wrapper {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  justify-content: space-between;
}

.borrowers_wrapper .custom_links_wrap {
  position: relative;
}

.borrowers_wrapper .custom_links_wrap .content {
  border: 1px solid var(--main-divider-gray-color);
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 1rem;
  background-color: #ffffff;
  align-items: center;
  padding: 0.75rem 1rem;
  max-width: 500px;
  cursor: pointer;
  transition: 0.3s;
}

.borrowers_wrapper .custom_links_wrap .content_disabled {
  pointer-events: none;
  background: #f0f0f0;
}

.borrowers_tooltip a,
.borrowers_tooltip span {
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.borrowers_tooltip a {
  text-decoration: underline;
}

.borrowers_wrapper .custom_links_wrap .content span {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.borrowers_wrapper .custom_links_wrap .popup {
  position: absolute;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(40, 41, 61, 0.04), 0 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
  top: 3.5rem;
  left: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
  z-index: 100;
  padding: 0 1rem 0 1.5rem;
  width: 100%;
}

.borrowers_wrapper .custom_links_wrap .popup_active {
  max-height: 160px;
  overflow: auto;
}

.borrowers_wrapper .custom_links_wrap .popup .item {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 1rem;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;
}

.borrowers_wrapper .custom_links_wrap .popup .item:first-of-type {
  padding: 16px 0 12px;
}

.borrowers_wrapper .custom_links_wrap .popup .item:last-of-type {
  padding: 12px 0 16px;
}

.borrowers_wrapper .custom_links_wrap .popup .item p {
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.borrowers_wrapper .custom_links_wrap .popup .item span {
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
}

.borrowers_wrapper .custom_links_wrap .popup .item svg {
  margin-left: 6px;
}

.prospect-actions-dropdown {
  box-shadow: 2px 8px 6px 2px rgba(0, 0, 0, 0.06);
  z-index: 999 !important;
}

.prospect-actions-dropdown .ant-menu-title-content .ant-space-item {
  display: flex;
}

.table_with_header_wrapper .section_table_wrapper .ant-space .wrapper_right_side_panel .options {
  position: relative;
  display: grid;
  align-items: center;
}

.table_with_header_wrapper .section_table_wrapper .ant-space .wrapper_right_side_panel .options .wrapper_options_popup {
  bottom: -0.5rem;
  right: 0;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1),
    0 4px 6px -2px rgba(15, 23, 42, 0.05);
  /*border-radius: 2px;*/
  width: 228px;
  padding: 4px;
}

.wrapper_options_popup .every {
  padding: 8px;
  transition: 0.3s;
}

.wrapper_options_popup .every:hover {
  background: #f5f5f5;
}

.table_with_header_wrapper
  .section_table_wrapper
  .ant-space
  .wrapper_right_side_panel
  .options
  .wrapper_options_popup
  span {
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #1f1f1f;
}

@media (max-width: 568px) {
  .table_with_header_wrapper.borrowers_wrapper .section_title_wrapper {
    display: flex;
    justify-content: space-between;
  }

  .table_with_header_wrapper.borrowers_wrapper .section_title_wrapper .btn_default svg {
    width: 20px;
  }

  .table_with_header_wrapper.borrowers_wrapper .mobile-list-wrapper .borrower-item-bot,
  .table_with_header_wrapper.borrowers_wrapper .mobile-list-wrapper .borrower-item-top {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: visible;
  }

  .table_with_header_wrapper.borrowers_wrapper .mobile-list-wrapper > * + * {
    border-top: 1px solid white;
    padding-top: 12px;
    margin-top: 12px;
  }

  .table_with_header_wrapper.borrowers_wrapper .mobile-list-wrapper .borrower-item {
    position: relative;
  }

  .table_with_header_wrapper.borrowers_wrapper .mobile-list-wrapper .borrower-item .options .wrapper_options_popup {
    right: 0;
    z-index: 1;
  }

  .table_with_header_wrapper.borrowers_wrapper .mobile-list-wrapper .borrower-item .options {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(245, 245, 245, 0.8);
  }

  .table_with_header_wrapper.borrowers_wrapper
    .mobile-list-wrapper
    .borrower-item-bot
    .scroll-wrapper
    .wrapper_right_side_panel {
    padding-right: 42px;
  }

  .table_with_header_wrapper.borrowers_wrapper .mobile-list-wrapper .borrower-item-top .ant-space > * + * {
    margin-left: 8px;
  }

  .table_with_header_wrapper.borrowers_wrapper .mobile-list-wrapper .borrower-item-bot {
    margin-top: 18px;
  }

  .table_with_header_wrapper.borrowers_wrapper .section_title_wrapper .btn_default {
    padding: 8px 16px;
    font-size: 14px;
  }

  .table_with_header_wrapper.borrowers_wrapper .section_title_wrapper .section_title h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
}
