.section {
  padding: 0;
  height: inherit;
  overflow-x: hidden;
}

.section > * {
  padding: 16px;
}

.finishedAuctionsWrapper {
  background-color: var(--colour-neutral-grey-4);
  width: 100%;
  min-height: 100%;
}

.tableWrapper {
  overflow-x: auto;
}

.finishedAuctionsWrapper :global(.ant-table-wrapper) {
  background-color: var(--widget-color);
  border: 1px solid var(--colour-neutral-grey-5);
  overflow-x: auto;
  min-width: 1150px;
}

.finishedAuctionsWrapper :global(.ant-table-pagination.ant-pagination) {
  padding-right: 16px;
}

.finishedAuctionsWrapper :global(.ant-table table) {
  border-collapse: collapse;
  border: none;
  border-bottom: 1px solid var(--colour-neutral-grey-5);
}

.finishedAuctionsWrapper :global(.ant-table-thead > tr > th) {
  padding: 8px 18px !important;
  background-color: var(--dr_white) !important;
}

.finishedAuctionsWrapper :global(.ant-table-tbody > tr > td) {
  padding: 8px 14px;
  vertical-align: middle !important;
  position: relative;
}

.finishedAuctionsWrapper :global(.ant-table-tbody > tr > td::after) {
  content: '';
  position: absolute;
  top: 8px;
  right: 0;
  height: calc(100% - 16px);
  width: 1px;
  background: var(--colour-neutral-grey-4);
}

.finishedAuctionsWrapper :global(.ant-table-tbody > tr > td:last-child) {
  padding-right: 18px;
  padding-left: 0;
}

.finishedAuctionsWrapper :global(.ant-table-tbody > tr > td:last-child::after) {
  display: none;
}

.finishedAuctionsWrapper :global(.ant-table tbody tr) {
  border-bottom: 1px solid var(--colour-neutral-grey-4);
}

.finishedAuctionsWrapper :global(.ant-table table) {
  font-size: 14px;
  line-height: 22px;
  color: var(--colour-neutral-grey-10);
}
