.box {
  border: 1px solid var(--light_gray);
}

.box.hor-md {
  padding-left: 24px;
  padding-right: 24px;
}

.box.ver-md {
  padding-top: 24px;
  padding-bottom: 24px;
}

.box.hor-sm {
  padding-left: 14px;
  padding-right: 14px;
}

.box.ver-sm {
  padding-top: 14px;
  padding-bottom: 14px;
}

.box.hor-xs {
  padding-left: 0;
  padding-right: 0;
}

.box.ver-xs {
  padding-top: 0;
  padding-bottom: 0;
}
