.chatRowName {
  font-weight: 700;
}

.chatRowTop {
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
  margin-bottom: 4px;
}

.chatRowTop * {
  margin: 0;
}

.date {
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
  margin-left: auto;
}

.chatRowMid {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.auctionInfo {
  display: flex;
  grid-gap: 0.25rem;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #1f1f1f;
  margin-right: 0.5rem;
  text-transform: capitalize;
}

.auctionInfo p {
  margin: 0;
  padding-left: 0.25rem;
  border-left: 2px solid currentColor;
}

.newMessagesCount {
  font-size: 12px;
  line-height: 20px;
  color: #fdfdff;
  background-color: #40a9ff;
  padding: 0 2px;
  border-radius: 16px;
  width: 32px;
  text-align: center;
}

.chatRowBottom {
  font-size: 12px;
  line-height: 20px;
  color: #595959;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chatRowBottom p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
}
