.wrapper_team .section_title h3 {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #0f172a;
  padding-left: 0;
}

.wrapper_team .dropdowns {
  display: grid;
  grid-gap: 28px;
  margin-bottom: 22px;
}

.wrapper_team .dropdowns > div {
  max-width: 360px;
}

.wrapper_team .wrapper_drop_down {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 3px 12px;
  width: 360px;
  display: grid;
  grid-template-columns: 1fr max-content;
}

.wrapper_team .wrapper_drop_down .submit {
  margin-top: 24px;
}
