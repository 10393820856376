.wrapper_lar_create_team .wrapper_lar_create_team__bcd {
}

.wrapper_lar_create_team .custom_table {
  margin-top: 16px;
}

.lar_created_wrapper .trigger-info {
  display: flex;
  flex-direction: column;
}

.lar_created_wrapper .trigger-info > div {
  display: grid;
  grid-template-columns: 120px max-content;
}

.lar_created_wrapper .trigger-info.email > div {
  grid-template-columns: 150px max-content;
}

.lar_created_wrapper .trigger-info > * + * {
  margin-top: 12px;
}

.lar_created_wrapper .notification_message_preview {
}

.lar_created_wrapper .notification_message_preview {
  margin-top: 24px;
}

.lar_created_wrapper .notification_message_preview .title {
  font-size: 16px;
  line-height: 24px;
  color: var(--nero_black);
}

.lar_created_wrapper .preview {
  background: var(--white_smoke);
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
}

.lar_created_wrapper .preview span {
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #000;
}

.lar_created_wrapper .wrapper_form_btn {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 24px;
  margin-top: 36px;
  justify-content: end;
}

.lar_created_wrapper .wrapper_form_btn.three_btn {
  grid-template-columns: 1fr repeat(2, max-content);
}

.lar_created_wrapper .wrapper_form_btn.four_btn {
  grid-template-columns: 1fr repeat(3, max-content);
}

/*bnt_wrapper*/
.bnt_wrapper .custom_text_area {
  margin-top: 24px;
}

.bnt_wrapper .wrapper_file {
  width: 228px;
  height: 128px;
  background: #fafafa;
  border: 1px dashed var(--light_gray);
  border-radius: 2px;
  display: grid;
  align-content: center;
  text-align: center;
  cursor: pointer;
  justify-items: center;
  grid-gap: 12px;
  justify-content: center;
  position: relative;
}

.bnt_wrapper .wrapper_file_error {
  border: 1px dashed var(--error);
}

.bnt_wrapper .wrapper_file .preview_file {
  width: inherit;
  height: inherit;
  object-fit: cover;
  padding: 1px;
}

.bnt_wrapper .input_container {
  margin-top: 12px;
}

.bnt_wrapper .wrapper_file span {
  width: 65%;
  margin: 0 auto;
}

/*mcmt_wrapper*/
/*email_notification_wrapper*/

.email_notification_wrapper .custom_table .wrapper_template_form_btn {
  display: grid;
  margin-top: 26px;
  grid-template-columns: max-content 1fr;
}

.email_notification_wrapper .custom_table .wrapper_template_form_btn .wrapper_form_btn {
  grid-template-columns: repeat(3, max-content);
  margin-top: 0;
}

.email_notification_wrapper .custom_table .wrapper_template {
  margin-top: 16px;
}

.email_notification_wrapper .merge-fields {
  background-color: #edebed;
  border: 1px #e0dfe0 solid;
  padding: 12px;
  margin-top: 24px;
}

.email_notification_wrapper .merge-fields > span {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  display: block;
}

.email_notification_wrapper .merge-fields .fields {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.email_notification_wrapper .merge-fields .fields .field-row {
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-gap: 12px;
  padding: 8px 0;
  border-bottom: 1px #e0dfe0 solid;
}

.email_notification_wrapper .merge-fields .fields .field-row > span:first-child {
  cursor: pointer;
}

/*email_notification_wrapper*/
