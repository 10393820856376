.root {
  color: var(--colour-neutral-grey-11);
  padding: 0;
}

.root :global(.ant-popover-arrow) {
  display: none;
}

.root :global(.ant-popover-inner-content) {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.root :global(.ant-popover-inner-content > div:not(:first-of-type)) {
  display: flex;
  flex-direction: column;
  gap: 0.25em;
}

.root :global(.ant-popover-inner-content > div:not(:last-child)) {
  border-bottom: 1px solid var(--colour-neutral-grey-4);
  padding-bottom: 1em;
}

.root :global(button) {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0.25em 0;
  text-align: left;
}

.miniProfile {
  align-items: center;
  display: grid;
  gap: 0 0.5em;
  grid-template-columns: 3em 1fr;
}

.miniProfile > :global(.ant-avatar) {
  grid-row: 1/3;
}

.miniProfile > :global(:last-child) {
  color: var(--colour-neutral-grey-7);
}
