.header {
  margin: 1.5rem 0;
}

.root .formItem {
  margin-bottom: 0;
}

.root .formItem :global(.ant-row) {
  align-items: center;
}

.tableWrapper {
  display: flex;
}

.tableWrapper :global(.ant-table-wrapper) {
  width: 100%;
  max-width: calc(100vw - 210px);
  min-width: 0;
}

.tableWrapper :global(.ant-table table) {
  border-color: rgb(240, 240, 240);
  border-left: 0;
}

.tableWrapper :global(.ant-table .ant-table-tbody > tr > td) {
  padding: 0.5rem !important;
}

.tableWrapper :global(.ant-table .ant-table-thead > tr > th) {
  padding: 0.5rem !important;
  white-space: nowrap;
  font-weight: 700;
  color: rgb(38, 38, 38);
  border-color: rgb(240, 240, 240) !important;
}
