.headerInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding-right: 2rem;
}

.headerInner .title {
  margin: 0;
}

.timer {
  margin-left: auto;
}
