.custom-tabs.custom-tabs_no-tabs-ink-bar .ant-tabs-ink-bar {
  display: none;
}

.custom-tabs .ant-tabs-tab-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #8c8c8c;
}

.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--main-blue-color);
  text-shadow: 0 0 0.25px currentColor;
}

.custom-tabs .ant-dropdown-open .anticon {
  color: var(--main-blue-color);
}

.custom-tabs .ant-tabs-tab.ant-tabs-tab-disabled {
  cursor: default;
}

.custom-dropdown-menu_in-custom-tab li span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #1f1f1f;
}

.custom-dropdown-menu_in-custom-tab li:hover span {
  color: var(--main-blue-color);
}

.ant-dropdown-menu.custom-dropdown-menu_in-custom-tab {
  border-radius: 8px;
  min-width: 130px;
}

.custom-tabs.ant-tabs-top > .ant-tabs-nav::before,
.custom-tabs.ant-tabs-top > div > .ant-tabs-nav::before {
  display: none;
}

/*----------------------------- styles for custom tabs in header ----------------------------------*/
.custom-tabs.custom-tabs_in-table .ant-tabs-ink-bar {
  display: flex;
  height: 1px;
}

.custom-tabs.custom-tabs_in-table .ant-tabs-tab-btn {
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
}

.custom-tabs.custom-tabs_in-table.ant-tabs-top > .ant-tabs-nav,
.custom-tabs.custom-tabs_in-table.ant-tabs-bottom > .ant-tabs-nav,
.custom-tabs.custom-tabs_in-table.ant-tabs-top > div > .ant-tabs-nav,
.custom-tabs.custom-tabs_in-table.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0;
}

.custom-tabs.custom-tabs_sm .ant-tabs-tab-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
}

.custom-tabs.custom-tabs_dark .ant-tabs-ink-bar {
  background: #1f1f1f;
}

.custom-tabs.custom-tabs_dark .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1f1f1f;
  text-shadow: 0 0 0.25px currentColor;
}

.table_with_header_wrapper .section_table_wrapper .ant-tabs-top.custom-tabs.custom-tabs_left > .ant-tabs-nav {
  padding-left: 0;
}
