.wrapper_bank_fee_info {
  padding: 32px 0 12px;
}

.wrapper_bank_fee_info__form {
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 8px;
  width: 349px;
  padding: 16px;
}

.wrapper_bank_fee_info__form .title {
  display: grid;
  grid-template-columns: 1fr max-content;
}

.wrapper_bank_fee_info__form .title span {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #2a303c;
}

.wrapper_bank_fee_info__form .title svg {
  cursor: pointer;
}

.wrapper_bank_fee_info__form .info {
  margin-top: 16px;
  display: grid;
  grid-gap: 16px;
}

.wrapper_bank_fee_info__form .info .every {
  display: grid;
}

.wrapper_bank_fee_info__form .info .every .preview {
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
}

.wrapper_bank_fee_info__form .info .every .value {
  font-size: 14px;
  line-height: 22px;
  color: #1f1f1f;
}
