.wrap {
  margin: 0 auto;
  max-width: 920px;
  width: 100%;
}
.headerWrap {
  max-width: 920px;
}
.headerContainer {
  min-height: 80px;
  display: flex;
  align-items: center;
  background: var(--dr_white);
  position: sticky;
  z-index: 10;
  top: 0;
  width: 100%;
  padding: 16px 32px;
}
.formContainer {
  margin-top: 24px;
  margin-bottom: 48px;
  background: #ffffff;
  padding: 24px 32px;
  overflow: hidden;
}
.formContainer :global(.custom-collapse .ant-collapse-content > .ant-collapse-content-box) {
  padding-left: 82px;
}
.formContainer :global(.ant-table-wrapper) {
  overflow-x: auto;
}
.divider {
  border-top: 8px solid rgba(0, 0, 0, 0.06);
}
.buttonWrapper {
  margin: 0 auto;
  max-width: 920px;
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
}
.formWrapper :global(.ant-form-item-label) {
  white-space: normal;
}
.formWrapper :global(.ant-form-item-with-help) {
  margin-bottom: 24px;
}
.formWrapper :global(.ant-form-item-label > label) {
  height: auto;
  margin-top: 4px;
}
