.main_wrapper_auth_body .wrapper_reset_password {
  width: 456px;
  padding: 3rem;
  background: #ffffff;
}

.main_wrapper_auth_body .wrapper_reset_password .auth_describe {
  margin: 24px 0 16px;
}

@media (max-width: 992px) {
  .main_wrapper_auth_body .wrapper_reset_password {
    margin: 0 auto;
  }
}

@media (max-width: 568px) {
  .main_wrapper_auth_body .wrapper_reset_password {
    width: 100%;
    padding: 2rem 1rem;
  }
}
