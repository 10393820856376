.carouselWrapper {
  position: relative;
  max-width: 50vw;
  overflow: hidden;
}

.carouselWrapper :global(.ant-carousel),
.carouselWrapper :global(.slick-slider),
.carouselWrapper :global(.slick-list),
.carouselWrapper :global(.slick-track),
.carouselWrapper :global(.slick-slide) * {
  height: 100%;
}

.carouselWrapper :global(img) {
  object-fit: cover !important;
  object-position: center !important;
}

.carouselWrapper :global(.ant-image-mask-info) {
  align-items: center;
  display: flex;
  justify-content: center;
}

.sliderButtons {
  display: flex;
  inset: 0;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  z-index: 100;
}

.sliderButtonPrev,
.sliderButtonNext {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.sliderButtonPrev {
  left: 0.5rem;
}

.sliderButtonNext {
  right: 0.5rem;
}

.propertyImage {
  height: 400px;
  object-position: center;
  object-fit: contain;
}
