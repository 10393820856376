.wrapper_vertical_tabs {
  background: #fff;
  border: 1px solid #cbd5e1;
  border-radius: 9px;
  display: grid;
  grid-template-columns: 40% 60%;
  margin-top: 32px;
  max-height: 80vh;
}

.wrapper_vertical_tabs__body {
  overflow: hidden;
  height: 80vh;
}

.wrapper_vertical_tabs__links {
  display: grid;
  grid-gap: 12px;
  padding: 6px 0;
  height: max-content;
}

.wrapper_vertical_tabs__links .every {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 8px;
  justify-content: space-between;
  padding: 12px 24px;
  transition: 0.4s;
  font-size: 16px;
  line-height: 24px;
  text-decoration: unset;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #475569;
}

.wrapper_vertical_tabs__links .every:hover {
  background: rgba(24, 144, 255, 0.1);
}

.wrapper_vertical_tabs__links .every_active {
  color: var(--dodger_blue);
}

.wrapper_vertical_tabs__links .every_active svg path {
  stroke: var(--dodger_blue);
}
