.team-members__section-title {
  margin-bottom: 0;
  padding: 6px 16px;
  font-size: 12px;
  line-height: 20px;
  font-style: normal;
  font-weight: normal;
  background: #fafafa;
  color: #242b5c;
}

.team-members__section-link {
  float: right;
  cursor: pointer;
  font-weight: bold;
  color: var(--main-blue-color);
}

.team-members__member {
  background-color: #ffffff;
  padding: 5px 16px;
}

.team-members__member-position {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
  margin-bottom: 7px;
}

.team-members__member_border-bottom {
  border-bottom: 1px solid #d9d9d9;
}

.team-members__member-data {
  margin-bottom: 19px;
  display: flex;
  justify-content: space-between;
}

.team-members__member-info {
  display: flex;
  cursor: pointer;
}

.team-members__status-container {
  flex-direction: column;
  display: flex;
}

.team-members__member-avatar-container {
  margin-right: 8px;
}

.team-members__actions {
  display: flex;
  align-items: center;
  margin-left: 56px;
}

.team-members__member-name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
}

.team-members__member-online-status {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #bfbfbf;
}

.team-members__member-status {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  text-align: right;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #bfbfbf;
}

.team-members__action-btn {
  cursor: pointer;
  width: 56px;
  height: 32px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d9d9d9;
}

.team-members_in-popover {
  margin: -12px -16px;
}

.team-members__actions {
  display: flex;
  gap: 10px;
}

.team-members__action-btn_purple {
  background: #6c5ce7;
  border: 1px solid #d9d9d9;
}

.team-members__action-btn_blue {
  background: var(--main-blue-color);
  border: none;
  border-radius: 0;
}
