.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  grid-gap: 0.5rem;
  grid-row: span 2;
  text-align: center;
  background-color: #fff;
}
