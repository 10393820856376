.root {
  background-color: #fff;
  border-top: 1px solid var(--colour-neutral-grey-5);
}

.form {
  display: grid;
  margin: 2em auto;
  width: 60em;
}

.form :global(.ant-form-item) {
  margin: 0;
}

.form :global(.ant-input-number) {
  width: 100%;
}

.uncollapsable {
  display: grid;
  gap: 1em;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  margin-bottom: 1em;
}

.estimatedClosingDayOfMonth {
  max-width: 150px;
}

.formGrid,
.bigFormGrid {
  align-items: flex-end;
  border-right: 1px solid var(--colour-neutral-grey-5);
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.formGrid > :global(*:nth-child(odd):not(.ant-select)),
.bigFormGrid > :global(*:nth-child(odd):not(.ant-select)) {
  background-color: #f5f5f5;
  border-bottom: 1px solid var(--colour-neutral-grey-5);
  border-left: 1px solid var(--colour-neutral-grey-5);
  padding: 0.5em;
}

.formGrid > :global(*:nth-child(even)),
.bigFormGrid > :global(*:nth-child(even)) {
  text-align: right;
  font-weight: 500;
}

.bigFormGrid {
  grid-template-columns: 3fr 2fr 2fr 2fr;
}

.biggerFormGrid {
  grid-template-columns: 3fr 1.5fr 1.5fr 1.5fr 1.5fr;
}

.notAnInput,
.formGrid :global(input),
.bigFormGrid :global(input) {
  border-bottom: 1px solid var(--colour-neutral-grey-5);
  border-left: 1px solid var(--colour-neutral-grey-5);
  text-align: right;
}

.formGrid .customOtherInput,
.bigFormGrid .customOtherInput {
  text-align: left;
}

.bigFormGrid > :global(*):not(.bigFormGridHHeader) {
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: flex-end;
}

.notAnInput {
  height: 100%;
  padding: 0 0.5em 0.5em 0;
}

.addItemButton {
  background: transparent !important;
  border: 1px dashed #377ecb !important;
  color: #377ecb;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
}

.buttonsWrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
}

.buttonsWrapper button {
  width: 115px;
  min-width: max-content;
  max-width: 100%;
}

.headerGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5em;
}

.headerGridBigger {
  grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr;
}

.rowWithBin {
  display: grid !important;
  grid-template-columns: 1fr 2rem;
  align-items: center;
}

.bigFormGrid .rowWithBin {
  align-items: center;
}

.rowWithBin > :global(:last-child) {
  cursor: pointer;
  margin: 0 0.5rem;
  vertical-align: middle;
}

.root :global(.ant-form-item),
.root :global(.ant-form-item *):not(.rowWithBin *) {
  height: 100%;
}

.root .brokerFeeSelect :global(.ant-select-arrow) {
  height: auto;
}

.root .brokerFeeSelect :global(.ant-select) {
  width: 100%;
  max-width: 150px;
}

.rowNameSelect {
  max-width: 16em;
}

.formDescriptionWrapper {
  margin-bottom: 20px;
}

.formDescriptionText {
  font-weight: 400;
}

.estimatedClosingDayOfMonthWrapper {
  display: flex;
  align-items: center;
}

.estimatedClosingDayOfMonth {
  margin-right: 8px;
}

.bigFormGrid.biggerFormGrid > * {
  height: 100%;
}

.bigFormGrid.biggerFormGrid > *:nth-child(5n + 1),
.bigFormGrid.biggerFormGrid > *:nth-child(5n + 3),
.bigFormGrid.biggerFormGrid > *:nth-child(5n + 5) {
  background-color: var(--colour-neutral-grey-3);
  border-bottom: 1px solid var(--colour-neutral-grey-5);
  border-left: 1px solid var(--colour-neutral-grey-5);
  padding: 0.5em;
  text-align: left;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
}

.bigFormGrid.biggerFormGrid > *:nth-child(5n + 2),
.bigFormGrid.biggerFormGrid > *:nth-child(5n + 4) {
  background-color: var(--widget-color);
  border-bottom: 1px solid var(--colour-neutral-grey-5);
  border-left: 1px solid var(--colour-neutral-grey-5);
  padding: 0.5em;
}

.bigFormGrid.biggerFormGrid input,
.bigFormGrid.biggerFormGrid :global(.ant-input-number) {
  border: none;
  height: 32px;
}

.bigFormGrid.biggerFormGrid :global(.ant-input-number-handler-wrap) {
  display: none;
}

.bigFormGrid.biggerFormGrid :global(.ant-select-selector) {
  background-color: var(--colour-neutral-grey-3);
}

.biggerFormGrid :global(.ant-form-item-row) {
  width: 100%;
}

.biggerFormGrid :global(.ant-select-arrow) {
  top: 0.5rem;
}
