.containerColumns {
  display: flex;
  flex: 1;
  padding-top: 24px;
  gap: 24px;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
}

.columnTitle {
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 22px;
}
