.wrapper_onboarding__wrapper_links {
  background: var(--secondary-darkblue-color);
  overflow: hidden auto;
  transition: width 0.4s;
}

.wrapper_onboarding__wrapper_links_hidden {
  width: 56px;
}

.wrapper_onboarding__wrapper_links hr {
  margin: 20px 14px;
  background: rgba(245, 245, 245, 0.1);
  color: rgba(245, 245, 245, 0.1);
  height: 2px;
  border: unset;
}

.wrapper_onboarding__wrapper_links .admin_panel_wrapper {
  margin: 24px 14px 0;
}

.wrapper_onboarding__wrapper_links .nav_bar_link {
  display: flex;
  grid-gap: 24px;
  padding: 14px;
  text-decoration-line: unset;
  position: relative;
}

.wrapper_onboarding__wrapper_links .btn_purple {
  margin: 10px auto;
  font-size: 14px;
  padding: 4px 8px;
}

.wrapper_onboarding__wrapper_links .nav_bar_link:last-child {
  margin-bottom: 40px;
}

.wrapper_onboarding__wrapper_links .nav_bar_link:active {
  color: unset;
}

.wrapper_onboarding__wrapper_links .nav_bar_link .action-item-count {
  background-color: white;
  right: 46px;
  top: 8px;
}

.wrapper_onboarding__wrapper_links .nav_bar_link .action-item-count span {
  color: #1f3047 !important;
}

.wrapper_onboarding__wrapper_links .nav_bar_link .action-item-label {
  font-size: 16px;
  line-height: 155%;
  color: #fafafa;
  width: max-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: unset;
}

.wrapper_onboarding__wrapper_links .nav_bar_active_link {
  background: rgba(255, 255, 255, 0.1);
}

.wrapper_onboarding__main_content {
  height: inherit;
  overflow-y: auto;
  position: relative;
  transition: 0.4s;
}

.wrapper_onboarding__main_content > * {
  padding: 2em;
}

.wrapper_onboarding__main_content.preview-lar-bg {
  background: url('/src/assets/images/loan-application-preview.jpg') no-repeat !important;
  background-size: cover !important;
}

.wrapper_onboarding__main_content_full {
  margin: 56px 0 0 56px;
  padding: 24px;
}

@media screen and (max-width: 700px) {
  .wrapper_onboarding__main_content {
    background-color: #ffffff !important;
    margin: 56px 0 0 0;
    padding: 16px;
  }
}
