#auth_header {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, max-content);
  margin: 0 0 1.75rem;
  grid-gap: 3.5rem;
  max-width: 456px;
}

#auth_header > .go-back {
  display: grid;
  place-content: center;
  width: 44px;
  height: 44px;
  border: 1px solid rgba(255, 255, 255, 0.32);
  border-radius: 32px;
  cursor: pointer;
}

#auth_header > img {
  max-width: 100%;
  display: block;
}

@media (max-width: 568px) {
  #auth_header {
    grid-gap: 1rem;
    justify-content: space-between;
    width: 100%;
    grid-template-columns: max-content 1fr;
  }

  #auth_header > img {
    margin-left: auto;
  }
}

@media (max-width: 350px) {
  #auth_header {
    grid-gap: 1rem;
    justify-content: space-between;
    width: 100%;
  }
}
