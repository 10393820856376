.item {
  height: var(--quote-row-height);
  font-size: var(--quote-row-fz);
  line-height: var(--quote-row-lh);
  display: flex;
  align-items: center;
  color: var(--black);
}

.item:not(:last-child) {
  border-bottom: 1px solid var(--light_gray);
}
