.root {
  display: flex;
  gap: 0.5em;
}

.schemeOption {
  border: 0.2em solid transparent;
  cursor: pointer;
  display: grid;
  height: 3em;
  width: 3em;
}

.schemeOption > :global(*) {
  display: block;
  height: 100%;
  width: 100%;
}

.selectedSchemeOption {
  border-color: var(--colour-brand-4);
}
