.wrapper {
  height: 100%;
}

.content {
  display: grid;
  grid-gap: 1rem;
  padding: 2rem 3rem;
  background: rgba(240, 240, 240, 1);
}
