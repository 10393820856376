.select {
  width: 100%;
}

.name {
  display: block;
  white-space: nowrap;
}

.table {
  overflow: auto;
}

.table tbody tr td {
  white-space: nowrap;
}
