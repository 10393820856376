.loan-bid-app__inner {
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 56px);
}
.loan-bid-app__choose-page {
  width: 100%;
  margin-top: 100px;
}
.loan-bid-app__choose-page-inner {
  margin: 0 auto;
  width: 100%;
  max-width: 660px;
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: space-between;*/
  /*height: 100%;*/
}
.loan-bid-app__back-link {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.loan-bid-app__back-link-text {
  margin-left: 4px;
  color: var(--main-blue-color);
}
.loan-bid-app__title-block {
  margin-top: 20px;
}
.loan-bid-app__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #0f172a;
}
.loan-bid-app__subtitle {
  margin-top: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #8c8c8c;
}

.loan-bid-app__place-page {
  width: 100%;
}
.loan-bid-app__place-page-inner {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}
.loan-bid-app__place-page-inner.loan-bid-app__place-page-inner_small {
  width: 90%;
}
.loan-bid-app__bid-form-value-text {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  text-align: right;
}

.loan-bid-app__bid-form {
  display: grid;
  gap: 0.5em;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 30px;
  margin-top: 30px;
}

.loan-bid-app__bid-form > button {
  margin-top: 2em;
}

.loan-bid-app__bid-form.loan-bid-app__bid-form_small-spacing {
  margin-top: 12px;
  margin-bottom: 16px;
}
.loan-bid-app__bid-form.loan-bid-app__bid-form_small-spacing .loan-bid-app__bid-form-row:not(:first-child) {
  margin-top: 6px;
}
.loan-bid-app__bid-form-label-col {
  display: flex;
  align-items: center;
  width: 100%;
}
.loan-bid-app__bid-form .ant-radio-inner::after {
  background-color: #5744e4;
}
.loan-bid-app__bid-form .ant-radio-checked .ant-radio-inner {
  border-color: #5744e4;
}
.loan-bid-app__bid-form .ant-radio-wrapper:hover .ant-radio,
.loan-bid-app__bid-form .ant-radio:hover .ant-radio-inner,
.loan-bid-app__bid-form .ant-radio-input:focus + .ant-radio-inner {
  border-color: #5744e4;
}
.ant-form.ant-form-horizontal.loan-bid-app__bid-form .ant-form-item {
  margin-bottom: 0;
}
@media screen and (max-width: 400px) {
  .loan-bid-app__inner {
    padding: 20px 15px;
  }
  .loan-bid-app__back-link,
  .loan-bid-app__title-block {
    max-width: 320px;
    margin: 0 auto;
  }
  .loan-bid-app__title-block {
    margin-top: 20px;
  }
}
