.block {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  width: 100%;
}
.wrapper {
  max-width: 384px;
  min-height: 200px;
  justify-content: space-between;
  background: var(--widget-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  width: 100%;
  border: 1px solid var(--pearl_gray);
  border-radius: 8px;
}
.label {
  font-size: 18px;
  color: var(--nero_black);
  font-weight: 500;
}
.icon {
  color: var(--purple);
}
