.wrapper_fees {
}

.wrapper_fees .wrapper_every_input_head {
  display: grid;
  grid-template-columns: 230px repeat(2, 84px);
  grid-gap: 24px;
}

.wrapper_fees .wrapper_every_input_body .every {
  display: grid;
  grid-template-columns: 230px repeat(2, 84px);
  grid-gap: 24px;
  align-items: center;
}

.wrapper_fees .wrapper_every_input_body .every .input_container .ant-input {
  padding: 8px;
}

.wrapper_fees button {
  margin-top: 24px;
}

.wrapper_fees .list {
  margin-top: 20px;
  padding-left: 20px;
}

.wrapper_fees .list li {
  font-size: 14px;
  line-height: 22px;
  color: #595959;
}

.wrapper_lar_application .wrapper_fees .list li {
  color: #8c8c8c;
}

@media screen and (max-width: 700px) {
  .wrapper_fees .wrapper_every_input_head {
    margin-top: 32px;
    text-align: center;
  }

  .wrapper_fees .wrapper_every_input_head,
  .wrapper_fees .wrapper_every_input_body .every {
    grid-template-columns: 90px repeat(2, 84px);
    grid-gap: 16px;
  }
}
