.root {
  align-content: flex-start;
  background-color: var(--colour-neutral-grey-2);
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto 1fr;
  height: calc(100vh - 4em);
  padding: 0;
}

.root > :global(:not(:first-child)) {
  overflow-y: auto;
}

.root > :global(:nth-child(2)) {
  grid-row: 2/4;
}

.root.noNavRoot > :global(:nth-child(2)) {
  grid-column: 1/3;
}

.root :global(main) {
  position: relative;
}

.main {
  padding: 45px 24px 24px;
  background-color: var(--widget-color);
}

.main > *:not(:last-child) {
  margin-bottom: 24px;
}

.quoteWrapper > *:not(:last-child) {
  margin-bottom: 16px;
}
