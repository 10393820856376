.item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  grid-gap: 0.5rem;
  padding: 1rem;
  min-height: var(--nav-item-height);
  border-bottom: 1px solid var(--colour-neutral-grey-5);
  border-left: 8px solid var(--colour-neutral-grey-5);
  background-color: #fff;
  transition: background-color 0.5s;
}

.viewOnly {
  cursor: default;
  border-left-color: var(--colour-neutral-grey-7);
  border-bottom: 0;
}

.viewOnly:hover {
  background-color: transparent;
}

.item:not(.viewOnly):hover,
.item:not(.viewOnly).activeItem {
  background-color: #bae7ff;
}

.item :global(.ant-badge) {
  grid-column: 2;
  grid-row: 1/3;
}

.item :global(.ant-badge-dot) {
  box-shadow: none;
  height: 0.75em;
  position: absolute;
  right: 5px;
  top: auto;
  bottom: 0;
  width: 0.75em;
}

.leftColumn {
  width: max-content;
  display: flex;
  flex-direction: column;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  min-width: max-content;
  max-width: 40%;
  align-items: flex-end;
}

.itemCaptionText {
  color: #8c8c8c;
  margin: 0;
  font-size: 12px;
  white-space: nowrap;
}

.rightColumn > *:not(:last-child) {
  margin-bottom: 4px;
}

.gray {
  border-left-color: var(--colour-neutral-grey-5);
}

.red {
  border-left-color: var(--colour-red-5);
}

.yellow {
  border-left-color: var(--colour-gold-5);
}

.green {
  border-left-color: var(--colour-green-5);
}

.date {
  padding: 1rem;
}

.icons {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  margin-top: auto;
}
