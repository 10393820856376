.wrapper_preferences {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 54px;
}

.wrapper_preferences__links {
  display: grid;
  height: max-content;
}

.wrapper_preferences__links .nav_bar_link {
  padding: 16px;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  border-bottom: 1px solid #f0f0f0;
}

.wrapper_preferences__links .nav_bar_active_link {
  font-weight: bold;
}

.wrapper_preferences__route {
  min-width: 446px;
}

@media screen and (max-width: 992px) {
  .wrapper_preferences {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }

  .wrapper_preferences__links {
    grid-template-columns: repeat(4, max-content);
    overflow-x: auto;
    grid-gap: 24px;
  }

  .wrapper_preferences__links .nav_bar_link {
    border: 0;
    padding: 12px 0;
  }

  .wrapper_preferences__links .nav_bar_active_link {
    border-bottom: 2px solid #6c5ce7;
  }
}
