.quoteItem {
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  align-items: center;
}

.barTitle {
  font-size: 14px;
  line-height: 22px;
  color: var(--nero_black);
  margin: 19px 0 8px;
}

.quoteItemAmount {
  text-align: center;
  margin-bottom: 8px;
  padding-top: 16px;
  font-size: 20px;
  line-height: 20px;
}

.barWrapper {
  height: 224px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 37px;
  justify-content: flex-end;
  align-items: center;
}

.barItemWrapper {
  min-height: 40%;
  display: flex;
  flex-direction: column;
  width: 122px;
}

.barItem {
  height: 100%;
  padding: 3px;
  border: 1px solid #d9d9d9;
  overflow: hidden;
}

.barItem:first-child {
  cursor: pointer;
}

.barInner {
  background-color: #377ecb;
  position: relative;
  min-height: 100%;
}

.barInnerDP {
  background-color: #40a9ff;
}

.barContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  position: absolute;
  bottom: 4px;
  left: 4px;
  color: #ffffff;
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  width: calc(100% - 8px);
}

.quoteItemNumber {
  text-align: center;
  border-top: 1px solid #d9d9d9;
  padding: 16px 8px;
  font-size: 20px;
  line-height: 20px;
}

.info {
  cursor: pointer;
}

.modal :global(.ant-modal-close-x) {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}
