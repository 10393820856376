.root {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  margin-top: 1rem;
  max-width: 40rem;
}

.main {
  padding: 1.5rem;
  background-color: #fff;
  border: 1px solid var(--colour-neutral-grey-5);
}

.avatar {
  margin-bottom: 1.5rem;
}

.userName {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.2;
  margin-bottom: 0.375rem;
}

.dataWrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.dataLabel {
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0.375rem;
  color: var(--colour-neutral-grey-11);
}

.dataInfo {
  font-size: 0.875rem;
  color: var(--colour-neutral-grey-8);
}

.personalLoanPageWrapper {
  color: var(--colour-neutral-grey-8);
}

.personalLoanPageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0;
}

.form {
  display: grid;
  gap: 0 1em;
  grid-template-columns: 3fr 1fr 2fr;
}

.avatarWrapper {
  grid-column: 1/4;
  margin-bottom: 1em;
}

.avatarWrapper :global(.ant-badge) {
  cursor: pointer;
}

.avatarWrapper svg {
  background-color: #40a9ff;
  border-radius: 50%;
  bottom: -1em !important;
  height: 2em;
  padding: 0.25em;
  right: 0.5em !important;
  top: auto !important;
  width: 2em;
}

.form :global(.ant-form-item:nth-child(3)) {
  grid-column: 2/4;
}

.form :global(.ant-form-item:nth-child(4)),
.form :global(.ant-form-item:nth-child(5)),
.form :global(.ant-form-item:nth-child(6)) {
  grid-column: 1/4;
}

.form :global(button) {
  width: 100%;
}

.form :global(button:nth-last-child(2)) {
  grid-column: 2;
}

.form :global(button:last-child) {
  grid-column: 3;
}
