.edit_EMS_wrapper__bcd {
  margin-bottom: 16px;
}

.edit-EMS-wrapper .edit_EMS_wrapper_form {
  margin-top: 28px;
}

.edit-EMS-wrapper .edit_EMS_wrapper_form .input_container {
  max-width: 360px;
}

.edit-EMS-wrapper .edit_EMS_wrapper_form .custom_text_area {
  margin-top: 24px;
}

.edit-EMS-wrapper .edit_EMS_wrapper_form button {
  justify-self: start;
  margin-top: 12px;
  width: 140px;
}

.edit-EMS-wrapper .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 8px 25px;
}

.edit-EMS-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset;
}

.edit-EMS-wrapper .section-title-wrapper {
  display: flex;
}

.edit-EMS-wrapper .section-title-wrapper .status-indicator-wrapper {
  margin-left: 16px;
}

.edit-EMS-wrapper .messages--action-required {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-EMS-wrapper .messages--action-required a {
  color: #5744e4;
}

.edit-EMS-wrapper .messages--action-required a.disabled {
  color: #8c8c8c;
  pointer-events: none;
}

.edit-EMS-wrapper td[colspan='4'] {
  background: var(--white_smoke);
  padding: 8px 20px !important;
}

.edit-EMS-wrapper .ant-table-tbody > tr.ant-table-row:hover > td[colspan='3'] {
  background: var(--white_smoke);
}

.edit-EMS-wrapper thead th.messages--message-type {
  padding-left: 100px !important;
}

.edit-EMS-wrapper tbody td.messages--message-type > div {
  display: flex;
}

.edit-EMS-wrapper tbody td.messages--message-type > div > span {
  margin-left: 24px;
}

.edit-EMS-wrapper .ant-table tbody tr {
  cursor: unset;
}

.edit-EMS-wrapper .messages--action-required .ant-dropdown-trigger {
  min-width: 130px;
  display: flex;
  justify-content: space-between;
}

.edit-EMS-wrapper h2 {
  margin-top: 1em;
}
