.root {
  background-color: #fdfdff;
  min-height: 100%;
  padding: 24px 52px;
}

.root :global(h1) {
  font-size: 1.7em;
  margin: 0;
}

/*.root > :global(div) {*/
/*	display: grid;*/
/*	gap: 1em;*/
/*}*/
