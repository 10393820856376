.custom_table {
  padding: 30px 24px;
  border: 1px solid var(--main-divider-gray-color);
  /*border-radius: 8px;*/
}
.custom-table_with-header__header {
  /*border-radius: 8px 8px 0 0;*/
  border: 1px solid var(--main-divider-gray-color);
  border-bottom: none;
  padding: 12px 25px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  justify-content: space-between;
}

.custom-table_with-header .ant-table-thead > tr > th {
  padding: 9px 25px !important;
}

.pipeline-table .ant-table-tbody > tr > td {
  padding: 8px;
}

.custom-table_with-header.pipeline-table .ant-table-thead > tr > th {
  padding: 8px !important;
}

.custom-table_with-header__header-text {
  color: #262626;
}

.custom-table_with-header__header-text_bold {
  font-weight: bold;
}

.custom-table.custom-table_with-header .ant-table table {
  /*border-radius: 0 0 8px 8px !important;*/
}

.custom-table_with-header__header .table_view,
.custom-table_with-header__header .table_view .show {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
}

.custom-table_with-header__header .table_view .show {
  grid-gap: 0.5rem;
  padding: 4px 8px;
  border: 1px solid var(--main-divider-gray-color);
  transition: 0.3s;
  cursor: pointer;
}

.custom-table_with-header__header .table_view .show span {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
  transition: 0.3s;
}

.custom-table_with-header__header .table_view .show svg path {
  stroke: #bfbfbf;
  transition: 0.3s;
}

.custom-table_with-header__header .table_view .show_active {
  border: 1px solid #40a9ff;
  background: #40a9ff;
}

.custom-table_with-header__header .table_view .show_active span {
  color: #ffffff;
}

.custom-table_with-header__header .table_view .show_active svg path {
  stroke: #ffffff;
}

.ant-table-cell .inline {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  grid-gap: 8px;
  cursor: pointer;
}

.ant-table-cell .inline.flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ant-table-cell .inline span {
  font-size: 12px;
  line-height: 20px;
  color: #595959;
}

.custom-table .ant-table-tbody > tr > td {
  vertical-align: middle !important;
}

.custom-table .ant-table table {
  color: #1f1f1f;
}

.ant-table-cell .online_status {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  grid-gap: 12px;
}

.ant-table-cell .online_status > span {
  font-size: 14px;
  line-height: 22px;
  color: #262626;
}

.ant-table-cell .online_status > .options {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
  grid-gap: 4px;
}

.ant-table-cell .online_status > .options span {
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  padding: 0 8px;
  border-radius: 24px;
}

.ant-table-cell .notifications {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.ant-table-cell .notifications > span {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: justify;
  color: #5744e4;
  text-transform: uppercase;
}
