.root {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.cardsWrapper {
  --cards-gap: 1em;
  --card-width: 15em;

  display: flex;
  flex-wrap: wrap;
  gap: var(--cards-gap);
  max-width: calc(var(--cards-gap) * 2 + var(--card-width) * 3);
  padding: 1em 0;
}

.card {
  background-color: #fdfdff;
  border: 1px solid var(--colour-neutral-grey-5);
  cursor: pointer;
  padding: 1em;
  width: var(--card-width);
}

.card > :global(h2) {
  font-size: 1em;
}

.card > :global(div) {
  color: var(--colour-neutral-grey-7);
}
