.collapsedButton {
  padding-left: 0;
}

.collapsedList {
  max-height: 10rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  transition: max-height 200ms;
}

.collapsedListOpen {
  max-height: 40rem;
  overflow: auto;
}

.iconButton {
  width: 3rem;
  border-color: var(--colour-neutral-grey-4);
  background-color: var(--colour-neutral-grey-4);
  border-radius: 16px;
}
