.auth_poster {
  max-width: 391px;
  border: 1px solid #a9b1cc;
  border-radius: 12px;
  padding: 4rem 3rem;
  display: grid;
  justify-items: center;
}

.auth_poster .title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #434343;
}

@media (max-width: 500px) {
  .auth_poster {
    padding: 2rem 1rem;
  }
}
