.root {
  align-items: center;
  display: grid;
  gap: 1em;
  grid-template-columns: 20em 20em 1fr 20em 20em;
}

.root > :global(h1) {
  margin-bottom: 0;
}

.root > :global(.custom-search) {
  grid-column: 4/6;
}

.root :global(button) {
  width: 100%;
}

.root :global(button:not(:last-of-type)) {
  grid-column: 4;
}

.root > :global(.ant-select) {
  align-self: end;
}

.root > :global(.ant-table-wrapper) {
  grid-column: 1/6;
}

.root :global(.ant-pagination-disabled) {
  display: none;
}

.root :global(.pagination_btn) {
  box-shadow: 0 2px 0 rgb(0 0 0 / 4%);
  padding: 8px 24px;
  transition: 0.4s;
}
