.notifications-page {
}

.notifications-page__inner {
}

.notifications-page__title-container {
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  grid-gap: 8px;
}

.notifications-page__title-container a {
  display: grid;
  align-items: center;
}

.notifications-page__important-notifications-container {
  margin-bottom: 24px;
  background-color: #ffffff;
}
.notifications-page__regular-messages-container {
  background-color: #ffffff;
}
.notifications-page__notifications-container {
  /*background-color: #FFFFFF;*/
}
