.title {
  margin-bottom: 12px;
}

.mortgagePaymentIncludesWrapper :global(.ant-form-item) {
  margin-bottom: 6px;
}

.noMargin {
  margin: 0;
}
