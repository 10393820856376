.root {
  max-width: 40em;
  margin-top: 1em;
}

.root > :global(button) {
  background-color: transparent;
  border: none;
  cursor: pointer;
  justify-self: end;
}

.main {
  background-color: #fff;
  border: 1px solid var(--colour-neutral-grey-5);
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
}

.main > :global(div) {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.main > :global(div:not(:last-child)) {
  border-bottom: 1px solid var(--colour-neutral-grey-4);
  padding-bottom: 1em;
}

.main > :global(div h2) {
  font-size: 1.1em;
  font-weight: 400;
  grid-column: 1/4;
}

.main > :global(div h2 > span) {
  color: var(--colour-neutral-grey-7);
  margin-left: 0.5em;
}

.main > :global(div > .ant-radio-group) {
  display: flex;
  flex-direction: column;
}

.rowWithButton {
  display: flex;
  justify-content: space-between;
}

.rowWithButton > :global(button) {
  background-color: transparent;
  border: none;
  color: #1890ff;
  cursor: pointer;
}

.main > :global(div > form) {
  display: grid;
  gap: 0 1em;
  grid-template-columns: 2fr 1fr 2fr;
}

.main > :global(div > form > .ant-form-item) {
  grid-column: 1/4;
}

.main > :global(div > form > button) {
  grid-column: 2;
}

.main > :global(div > form > button:last-child) {
  grid-column: 3;
}
