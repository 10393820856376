.item {
  min-width: 140px;
  max-width: 140px;
  display: flex;
  flex-direction: column;
}

.title {
  flex-grow: 1;
}

.iconWrapper {
  margin-bottom: 10px;
  position: relative;
}

.item:not(:last-child):not(:nth-last-child(2)) .iconWrapper::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 40px;
  height: 2px;
  width: calc(100% - 40px);
}

.iconWrapper.green::after {
  background-color: var(--colour-green-6);
}

.iconWrapper.red::after {
  background-color: var(--colour-red-5);
}

.date {
  margin: 0;
  width: 70px;
  height: 44px;
}
