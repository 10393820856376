.root {
  display: grid;
  grid-template-columns: 2fr 1fr;
  max-width: 45em;
  /* margin-top: 1em; */
  margin-top: 32px;
  background-color: #fdfdff;
}

.root > :global(button) {
  background-color: transparent;
  border: none;
  cursor: pointer;
  justify-self: end;
}

.root > :global(button > svg) {
  vertical-align: middle;
}

.root :global(.ant-spin-nested-loading) {
  grid-column: 1/3;
}

.main {
  background-color: #fff;
  border: 1px solid var(--colour-neutral-grey-4);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.main > :global(div) {
  display: grid;
  gap: 1.5em;
  grid-template-columns: 1fr 1fr;
}

.main > :global(div:not(:first-child)),
.main > :global(form > div:not(:first-child)) {
  margin-top: 16px;
  padding-top: 24px;
  border-top: 1px solid var(--colour-neutral-grey-4);
}

.companyName {
  font-size: 1.4em;
}

.companyPic {
  height: 72px;
  width: 72px;
}
.companyPic,
.avatarWrapper {
  justify-self: flex-end;
}

.multiRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5em;
}

.multiRow > :first-child {
  color: #1f1f1f;
}

.multiRow > :global(div:not(:first-child)) {
  color: var(--colour-neutral-grey-8);
}

.wideRow {
  grid-column: 1/3;
}

.adminPic {
  margin-right: 1em;
}

.main > :global(form) {
  display: grid;
  grid-template-columns: 3fr 1fr 2fr;
}

.main > :global(form > div) {
  display: grid;
  gap: 1em;
  grid-column: 1/4;
  grid-template-columns: 1fr 1fr;
}

.main > :global(form h2) {
  font-size: 1.1em;
  font-weight: 400;
  grid-column: 1/3;
}

.main > :global(form button:nth-last-child(2)) {
  grid-column: 2;
}

.main > :global(form button:last-child) {
  grid-column: 3;
}

.avatarWrapper {
  margin-bottom: 1em;
}

.avatarWrapper :global(.ant-badge) {
  cursor: pointer;
}

.avatarWrapper svg {
  background-color: #40a9ff;
  border-radius: 50%;
  bottom: -1em !important;
  height: 2em;
  padding: 0.25em;
  right: 0.5em !important;
  top: auto !important;
  width: 2em;
}

.changeAdminForm {
  grid-column: 1/4;
}

.changeAdminForm :global(button) {
  margin-top: 1em;
}

.main :global(.wrapper_input) {
  margin: 0;
}

.main :global(.custom_input) {
  border-radius: 2px;
  font-size: 1em;
  line-height: 1.5715;
  padding: 4px 11px;
}

.form > button {
  margin-left: 8px;
}

.main .apiWrapper {
  grid-column: 1/3;
  display: grid;
  grid-gap: 0.25rem;
  padding: 1rem;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
}

.copyButton {
  color: #595959;
  background: #f0f0f0;
  border-radius: 2rem;
  border: none;
}

.link {
  color: #40a9ff;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  width: 45em;
  margin-bottom: 32px;
}

.edit {
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.edit p {
  margin: 0;
  margin-right: 10px;
  font-weight: 500;
}
