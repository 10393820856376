.broker-profile-wrapper {
  max-width: 500px;
  margin: 0 auto;
}

.broker-profile-wrapper .broker-profile-content {
  padding-top: 24px;
}

.broker-profile-wrapper .wrapper_file_uploader {
  margin-top: 32px;
}

.broker-profile-wrapper .wrapper_btns {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #cacaca;
  display: flex;
  justify-content: space-between;
}
