.wrapper_lender_registration {
  position: absolute;
  display: grid;
  justify-content: center;
  align-content: center;
  height: calc(100% - 56px);
  width: 100%;
}

.registration-content {
  max-width: 422px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 68px auto;
}

.registration-content .footer-info {
  border-top: 1px solid #f0f0f0;
  padding-top: 16px;
  margin-top: 24px;
  margin-bottom: 68px;
  display: flex;
  flex-direction: column;
}

.registration-content .footer-info span {
  font-size: 12px;
  line-height: 14px;
  color: #8c8c8c;
}

.registration-content .footer-info > span + span {
  margin-top: 8px;
  display: block;
}

.render-inputs-wrapper .divided-inputs {
  display: grid;
  margin-top: 24px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.render-inputs-wrapper .errors-wrapper {
  background: rgba(242, 58, 58, 0.03);
  border: 1px solid rgba(242, 58, 58, 0.15);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  margin-top: -2em;
  padding-left: 24px;
}

.render-inputs-wrapper .errors-wrapper:empty {
  border: none;
}

.render-inputs-wrapper .errors-wrapper li {
  margin: 4px 8px;
  font-size: 12px;
  line-height: 20px;
  color: #f23a3a;
}

.render-inputs-wrapper .select-tags {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.render-inputs-wrapper .select-tags .ant-select {
  margin-top: 8px;
}

.render-inputs-wrapper .select-tags .ant-space {
  margin-top: 12px;
}

.render-inputs-wrapper .input-row {
  margin-top: 24px;
}

.render-inputs-wrapper .checkboxes {
  display: flex;
  flex-direction: column;
}

.render-inputs-wrapper .checkboxes-wrapper:not(.bordered) .checkboxes > * {
  margin-top: 18px;
  margin-left: 24px;
}

.render-inputs-wrapper .checkboxes-wrapper.bordered {
  margin: 16px 0;
  padding: 16px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.render-inputs-wrapper .logo-wrapper {
  margin-top: 24px;
}

.render-inputs-wrapper .switches-wrapper {
  display: flex;
  flex-direction: column;
}

.render-inputs-wrapper .switches-wrapper .switch {
  margin-top: 24px;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 24px;
}

.render-inputs-wrapper .switches-wrapper .switch .switch-text {
  display: flex;
  flex-direction: column;
}

.render-inputs-wrapper .switches-wrapper .switch .switch-text .switch-title {
  font-size: 16px;
  line-height: 24px;
  color: #0f172a;
}

.render-inputs-wrapper .switches-wrapper .switch .switch-text .switch-subtitle {
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
  margin-top: 8px;
}

.render-inputs-wrapper .logo-wrapper .upload-logo {
  display: flex;
  align-items: center;
}

.render-inputs-wrapper .logo-row > img,
.render-inputs-wrapper .logo-row > .avatar_placeholder {
  margin-right: 16px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  object-fit: cover;
}

.render-inputs-wrapper .logo-wrapper .upload-logo span {
  font-size: 14px;
  line-height: 22px;
  color: white;
  margin-left: 8px;
}

.render-inputs-wrapper .logo-wrapper .btn_purple:hover .upload-logo span {
  transition: 0.4s;
  color: var(--awesome_purple);
}

.render-inputs-wrapper .logo-wrapper .btn_purple:hover .upload-logo svg > path {
  transition: 0.4s;
  fill: none;
  stroke: var(--awesome_purple);
}

.render-inputs-wrapper .logo-wrapper .logo-row {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.render-inputs-wrapper .side-title-wrapper {
  width: 100%;
  position: relative;
  margin-top: 28px;
}

.render-inputs-wrapper .side-title-wrapper.bordered {
  border-top: 1px solid #f0f0f0;
}

.render-inputs-wrapper .side-title-wrapper .section_title {
  position: absolute;
  right: calc(100% + 150px);
  width: max-content;
  top: 12px;
}

.ant-checkbox-disabled + span,
.ant-radio-disabled + span {
  color: unset;
}

.ant-input[disabled],
.ant-input-disabled {
  color: black;
}

.main-registration-wrapper.confirm .logo img {
  height: 24px;
}

.main-registration-wrapper .phone-wrapper .react-tel-input .form-control[disabled] {
  color: black !important;
}

.wrapper_lender_registration .thanks-wrapper {
  max-width: 422px;
  width: 100%;
  border: 1px solid #cbd5e1;
  padding: 32px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.wrapper_lender_registration .thanks-wrapper svg {
  margin: 24px 0;
}

.wrapper_lender_registration .thanks-wrapper .bordered {
  margin: 16px 0 18px;
  padding-top: 12px;
  border-top: 1px solid #d9d9d9;
}

@media (max-width: 750px) {
  .render-inputs-wrapper .side-title-wrapper .section_title {
    position: unset;
    margin-top: 12px;
  }

  .render-inputs-wrapper .divided-inputs.mobile-layout {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  .registration-content {
    margin: 68px auto;
    padding: 0 12px;
  }

  .section_title h6 {
    font-size: 17px;
  }
}

@media (max-width: 400px) {
  .wrapper_lender_registration {
    padding: 0 12px;
  }

  .wrapper_lender_registration .input_container .wrapper_input {
    padding: 0;
  }
}
