.root {
  display: grid;
  gap: 1em;
  grid-template-columns: 10em 1fr 15em 15em;
}

.root :global(h1),
.root :global(p) {
  margin: 0;
  text-align: justify;
}

.root :global(p) {
  grid-column: 1/3;
}

.root > :global(button) {
  width: 100%;
  grid-column: 3;
}

.root > :global(button:last-of-type) {
  grid-column: 4;
}

.root > :global(h1),
.root > :global(.ant-table-wrapper) {
  grid-column: 1/5;
}

.root > :global(.ant-checkbox-wrapper),
.root > :global(button) {
  align-self: center;
  grid-row: 3;
}

.root > :global(.ant-table-wrapper th) {
  padding: 0 !important;
}

.root :global(.ant-table-wrapper th .ant-select) {
  width: 100%;
}

.root > :global(.ant-table-wrapper th .ant-select-selector) {
  border: none;
  font-weight: 800;
}

.root > :global(.ant-table-wrapper table) {
  border-bottom: 2px solid #ccc;
}

.root > :global(.ant-table-wrapper tr > *) {
  border: 1px solid #ccc;
}

.root > :global(.ant-table-wrapper .ant-select-selector),
.root > :global(.ant-table-wrapper td.disabled-column) {
  background-color: #efeff0;
}

.root > :global(.ant-table-wrapper .ant-select-single.ant-select-show-arrow .ant-select-selection-item) {
  font-weight: 600;
}

.leftRightButtons {
  display: flex;
  gap: 0.5em;
  grid-column: 4;
  justify-self: right;
}
