.wrapper_picker_error > div {
  border: 1px solid var(--error);
}

.wrapper_picker {
  display: grid;
  align-self: self-start;
}

.wrapper_picker__title {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.disabled_text_black input {
  color: rgba(0, 0, 0, 0.85) !important;
}
