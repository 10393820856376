.button {
  color: var(--main_blue_color);
  font-weight: 600;
}

.button path {
  transition: 0.4s;
}

.button:hover path,
.button:focus path {
  stroke: var(--black);
}
