.button > div {
  background-color: transparent;
}

.button:global:not(.ant-btn-primary) {
  background-color: transparent;
}

.button:hover {
  color: #40a9ff;
}

.button:hover svg {
  stroke: #40a9ff;
}
