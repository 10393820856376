.wrapper_categories {
  margin: 0 -32px -24px;
}

.wrapper_categories .title {
  display: flex;
  justify-content: space-between;
  padding: 20px 24px 0;
}

.wrapper_categories .title .button_wrapper {
  display: flex;
  gap: 1em;
}

.wrapper_categories .title h3 {
  padding: 0;
}

.wrapper_categories .title button {
  padding: 7px 20px;
}

.wrapper_categories .categories_list input:focus,
.wrapper_categories .categories_list input {
  border: unset;
  box-shadow: unset;
}

.wrapper_categories .categories_list {
  margin-top: 10px;
}

.wrapper_categories .categories_list .input_container .wrapper_input {
  margin-top: 0;
  height: inherit;
}

.wrapper_categories .categories_list .every {
  border-top: 1px solid #cbd5e1;
  display: grid;
  grid-template-columns: 1fr 7em;
  padding: 4px 4px 4px 0;
}
