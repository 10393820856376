.container {
  max-width: 800px;
}

.typeBtn {
  background-color: transparent;
  border: 1px solid #d9d9d9;
  min-width: 234px;
  padding: 25px;
  cursor: pointer;
  transition: 0.2s;
  margin-right: 16px;
}

.authorBtn {
  padding: 5px;
  min-width: 96px;
}

.typeBtn:hover,
.typeBtn.active {
  border-color: var(--main-blue-color);
  background-color: var(--main-light-blue-color);
}

.selectBorrowerWrapper {
  display: flex;
  align-items: center;
}

.templateWrapper,
.messageWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-bottom: 16px;
}

.templateWrapper > :global(div) {
  background-color: #f5f5f5;
  border-radius: var(--border-radius-standard);
  border: var(--border-standard);
  padding: 1em;
}

.templateWrapper > :global(div p) {
  margin: 1em 0;
}

.buttonWrapper {
  margin-bottom: 8px;
}

.wrapper {
  border: 1px solid #f0f0f0;
  padding: 16px;
  max-width: 584px;
}

.footerButtons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.borrowerSelect {
  border: 1px solid var(--colour-neutral-grey-6);
}

.borrowerSelect > div {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 5px 0 5px 16px;
  font-size: 14px;
  line-height: 22px;
}

.newBorrowerBtn {
  font-size: 14px;
  line-height: 22px;
  padding: 5px 24px;
}

.row {
  display: flex;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 24px;
  gap: 16px;
}

.leftCol {
  width: 25%;
}

.leftCol span {
  font-weight: 700;
}

.rightCol {
  display: flex;
  gap: 8px;
  width: 75%;
}

.rightColColumn {
  flex-direction: column;
}

.select {
  width: 300px;
  max-width: 100%;
}
