.root {
  display: grid;
  gap: 1em;
  grid-template-columns: 1fr 1fr;
  padding-top: 2em;
}

.root :global(nav) {
  grid-column: 1/3;
  margin: 0 2em;
}

.leftPart {
  align-items: center;
  display: flex;
  gap: 0.5em;
  margin-left: 2em;
}

.leftPart > :global(h1) {
  font-weight: 600;
  font-size: 1.7em;
  margin: 0;
}

.leftPart > :global(div) {
  align-items: center;
  background-color: #73d13d;
  color: #fff;
  display: flex;
  font-size: 0.9em;
  padding: 0.5em;
}

.leftPart > :global(button) {
  border: none;
}

.rightPart {
  align-items: center;
  display: flex;
  gap: 1em;
  justify-content: flex-end;
  margin-right: 2em;
}

.notesButton:hover,
.notesButton {
  background-color: #fff1b8;
  border: none;
  color: #613400;
}
