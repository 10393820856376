.root {
  display: grid;
  grid-template-columns: 49% 49%;
  justify-content: space-between;
  padding: 1.25rem 0.5rem;
  margin-bottom: 2rem;
}

.widget {
  padding: 1rem;
  background-color: #ffffff;
  border: 1px solid var(--colour-neutral-grey-5);
}

.widgetHeader {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--colour-neutral-grey-5);
}

.widgetHeader,
.widgetItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1rem;
}

.widgetHeader .widgetTitle {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  margin: 0;
}

.badge > sup {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.empty {
  padding: 1.5rem 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.5rem;
}

.emptyIcon {
  display: flex;
  border-radius: 50%;
  color: var(--colour-brand-5);
  background-color: var(--colour-brand-1);
}

.icon {
  fill: currentColor;
}

.table :global(.ant-table table) {
  border: 0;
}

.table :global(.ant-table-thead > tr > th) {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  font-weight: 700;
  color: var(--colour-neutral-grey-11) !important;
  font-size: 0.875rem;
}

.table :global(.ant-table-tbody > tr > td) {
  white-space: nowrap;
}

.table :global(.ant-table-thead > tr > th:first-child),
.table :global(.ant-table-tbody > tr > td:first-child) {
  padding-left: 0 !important;
}
