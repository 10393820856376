.root {
  display: flex;
  align-items: center;
}

.root > :global(a),
.rightWrapper {
  display: flex;
  gap: 1em;
  align-items: stretch;
}

.title {
  color: var(--nero_black);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0;
  flex-grow: 1;
  margin-left: 8px;
}

.progressTitle {
  color: var(--nero_black);
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
}

.exitPopup :global(.ant-modal-header),
.exitPopup :global(.ant-modal-footer) {
  border: none;
}

.progress {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--widget-color);
  border: 1px solid var(--light_gray);
  padding: 2px 12px;
}

.rightWrapper button {
  height: auto;
  border-radius: 0;
}

.progressPercents {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.progressScenario {
  color: var(--colour-brand-5);
  font-weight: 700;
}

.progressLA {
  color: var(--colour-green-6);
  font-weight: 700;
}

.linkBack {
  cursor: pointer;
  display: inline-block;
  margin-right: 1rem;
  vertical-align: middle;
}
