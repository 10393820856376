.invite-users-form {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  margin-bottom: 2rem;
}

.invite-users-form__inner {
}

.invite-users-form__header {
}

.invite-users-form__desc-block {
  text-align: center;
  padding: 16px 10%;
}

.invite-users-form__desc-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #8c8c8c;
  margin-bottom: 0;
}

.invite-users-form__desc-title-container {
  margin-bottom: 8px;
}

.invite-users-form__desc-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1f1f1f;
}

.invite-users-form__invited-users-table-container {
}

.invite-users-form__footer {
  padding: 16px 26px 19px 26px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.invite-users-table {
  overflow: auto;
  width: 100%;
}

.invite-users-table table {
  min-width: 1000px;
}

#invite-users-table .ant-table-thead > tr > th {
  padding: 14.5px 18px !important;
}

.company-members .ant-table-tbody > tr > td {
  vertical-align: middle !important;
}
#invite-users-table .ant-table-tbody > tr > td {
  vertical-align: middle !important;
  padding: 8px 18px;
}

#invite-users-table .ant-table table {
  border: 1px solid #d9d9d9;
  border-radius: 0 !important;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

#invite-users-table .ant-table-thead > tr > th {
  border-bottom: 1px solid #d9d9d9 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000 !important;
}

#invite-users-table .ant-table tbody tr {
}

#invite-users-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
#invite-users-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-right: none;
}

#invite-users-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-bottom: 1px solid #f5f5f5 !important;
}

#invite-users-table .ant-table.ant-table-bordered > .ant-table-container {
  border-left: none;
}

#invite-users-table__role-select .ant-select-selector {
  border: none;
  box-shadow: none;
}

#invite-users-table__role-select .ant-select-selector:after {
  display: none;
}

#invite-users-table .ant-table-content {
  overflow-x: auto;
}

.invite-users-form__add-user-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: var(--main-blue-color);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.invite-users-table__footer {
  padding: 14px 12px;
  border-bottom: 1px solid #d9d9d9;
}
