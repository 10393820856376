.header {
  padding: 16px 16px 10px;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.titleWrapper {
  align-items: center;
  display: flex;
  height: 2em;
  margin-bottom: 8px;
}

.searchAndArchiveWrapper {
  display: flex;
  gap: 8px;
}

.title {
  flex-grow: 1;
  font-weight: 600;
  margin: 0;
}

.show {
  position: absolute;
  left: 20px;
  transform: rotate(180deg);
}

.iconButton {
  padding: 4px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.inputWrapper input {
  padding: 6px 12px;
  border-color: var(--main-divider-gray-color);
}

.hide {
  transform: rotate(180deg);
}
