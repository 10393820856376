.popupBodyInsert {
  border-bottom: 1px solid var(--light_gray);
  border-top: 1px solid var(--light_gray);
  padding: 16px 0;
  margin-bottom: 16px;
}

.popupLinkWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}

.openLoanProfileLink {
  font-weight: 700;
  color: var(--main_blue_color);
}

.popupLink {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0;
}
