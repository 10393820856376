.wrapper_login {
  max-width: 456px;
  width: 100%;
  padding: 3rem;
  background: #ffffff;
}

.wrapper_login .forgot_password_link {
  margin-top: 2rem;
  display: block;
  text-align: left;
  text-decoration-line: unset;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #40a9ff;
}

.wrapper_login .forgot_password_link:hover {
  text-decoration-line: underline;
}

@media (max-width: 992px) {
  .wrapper_login {
    margin: 0 auto;
  }
}

@media (max-width: 568px) {
  .wrapper_login {
    width: 100%;
    padding: 2rem 1rem;
  }
}
