.container {
  padding: 16px;
  border: 1px dashed var(--colour-neutral-grey-4);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.container p {
  margin: 0;
}

.title {
  font-weight: 700;
  color: var(--colour-brand-5);
  line-height: 22px;
}

.subTitle {
  line-height: 22px;
}
