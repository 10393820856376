.header {
  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.root :global(.ant-tabs-nav) {
  margin-bottom: 0;
}

.root :global(.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab) {
  background-color: #ffffff;
  border-color: var(--colour-neutral-grey-5);
}

.root :global(.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active) {
  color: #ffffff;
  background-color: var(--colour-brand-5);
}

.root :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
  color: #ffffff;
}

.root :global(.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab) {
  margin-left: 0;
  border-left: 0;
}

.root :global(.ant-tabs-top .ant-tabs-nav::before) {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}

.root :global(.ant-table.ant-table-middle) table :global(.ant-table-tbody) > tr > td:first-child {
  width: 120px;
  padding: 0 !important;
}

.root :global(.ant-table.ant-table-middle) table :global(.ant-table-tbody) > tr > td:nth-child(2) {
  white-space: nowrap;
}

.root :global(.ant-table-thead > tr > th) {
  font-weight: 600;
  color: var(--colour-neutral-grey-11) !important;
  font-size: 0.875rem;
}

.tableHeaderCell {
  cursor: pointer;
  display: flex;
  grid-gap: 0.5rem;
  white-space: nowrap;
}

.actionsNumber {
  color: #fff;
  width: 3rem;
  text-align: center;
}

.date {
  white-space: nowrap;
  color: var(--colour-brand-5);
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.25rem;
  font-size: 0.875rem;
  color: #ffffff;
  min-width: 50px;
}

.badgeRed {
  background-color: var(--colour-red-5);
}

.badgeOrange {
  background-color: var(--colour-gold-5);
}

.badgeGreen {
  background-color: var(--colour-green-5);
}

.tableContainer :global(.ant-table) {
  overflow: auto;
}
