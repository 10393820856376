.wrapper_invites_auction .section_title {
  padding-top: 24px;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0;
  color: #0f172a;
}

.wrapper_invites_auction .invites_wrapper {
  display: grid;
  grid-gap: 24px;
  border-bottom: 1px solid #cbd5e1;
  padding-bottom: 24px;
}

.wrapper_invites_auction .invites_wrapper .radio_wrapper {
  display: grid;
  grid-gap: 8px;
}

.wrapper_invites_auction .invites_wrapper .radio_wrapper .ant-radio-group {
  display: grid;
  grid-gap: 16px;
}

.wrapper_invites_auction .submit {
  grid-column: 1/-1;
  justify-self: start;
  padding: 8px 48px;
}

.wrapper_invites_auction .bordered {
  max-width: 350px;
}

.wrapper_invites_auction .two_col,
.wrapper_invites_auction .three_col {
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-gap: 24px;
  align-items: center;
}

.wrapper_invites_auction .auctions {
  display: grid;
  grid-gap: 26px;
}

.wrapper_invites_auction .auctions .auction_block {
  display: grid;
  grid-template-columns: 300px 1fr;
}

.wrapper_invites_auction .auctions .two_col {
  grid-template-columns: 1fr 1fr;
}

.wrapper_invites_auction .auctions .two_col_timezone {
  grid-template-columns: 225px;
}

.wrapper_invites_auction .auctions .three_col {
  grid-template-columns: 225px 80px 1fr;
}

.wrapper_invites_auction .auctions .auction_block .ant-picker {
  max-width: 225px;
}

.wrapper_invites_auction .auctions .auction_block .bordered .ant-dropdown-trigger {
  padding: 3px 12px;
}

.wrapper_invites_auction .auctions .section_title:not(:first-child) {
  padding-top: 0;
}
