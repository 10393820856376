.editorWrapper :global(.rdw-editor-wrapper) {
  background: var(--widget-color);
  border: 1px solid var(--light_gray);
  margin-bottom: 24px;
}

.editorWrapper :global(.rdw-editor-toolbar) {
  border: none;
  border-bottom: 1px solid var(--light_gray);
}

.editorWrapper :global(.rdw-editor-main) {
  padding: 8px;
  min-height: 128px;
}

.editorWrapper :global(.public-DraftStyleDefault-block) {
  margin: 0;
}
