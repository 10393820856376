.wrapper_prospect .wrapper_prospect__title {
  display: grid;
  grid-gap: 8px;
  justify-items: center;
}

.wrapper_prospect .wrapper_prospect__title .title {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0f172a;
}

.wrapper_prospect .wrapper_prospect__title .description {
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
}

.wrapper_prospect .wrapper_prospect__title .subtitle {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}

.wrapper_prospect .wrapper_remove_prospect_btns,
.wrapper_prospect .wrapper_add_prospect_btns {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
}

.wrapper_prospect .wrapper_add_prospect_btns .errorMessage {
  text-align: left;
  grid-column: 1/-1;
  margin-bottom: 12px;
}

.wrapper_prospect .wrapper_remove_prospect_btns {
  padding-top: 24px;
}

.phone-wrapper .react-tel-input .form-control {
  height: 42px;
  width: 100%;
}

.phone-wrapper .react-tel-input .form-control[disabled] {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
}

.phone-wrapper .react-tel-input {
  margin-top: 12px;
}

.input_error .react-tel-input .flag-dropdown {
  border: 1px solid var(--error);
  border-right: none;
}

.phone-wrapper {
  display: flex;
  flex-direction: column;
  grid-column: 1/3;
}

.wrapper_prospect .wrapper_add_prospect_form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.wrapper_prospect .wrapper_add_prospect_form .wrapper_input {
  margin-top: 8px;
}

.wrapper_prospect .wrapper_add_prospect_form .input_container:nth-child(3),
.wrapper_prospect .wrapper_add_prospect_form .input_container:nth-child(4) {
  grid-column: 1/-1;
}

.wrapper_prospect_delete .ant-modal-body {
  display: none;
}

.wrapper_prospect_delete .ant-modal-footer {
  border-top: unset;
  padding: 0 24px 12px;
}
