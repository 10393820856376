.wrapper_general .blackout_days_form {
  margin-bottom: 24px;
}

.wrapper_general .business_day {
  display: grid;
  grid-template-columns: max-content 300px;
  grid-gap: 24px;
  border-bottom: 1px solid #e2e8f0;
  padding: 24px 0;
}

.wrapper_general .business_day .wrapper_picker {
  width: max-content;
}

.wrapper_general .business_day .ant-picker-input {
  width: 140px;
}

.wrapper_general .business_day .wrapper_drop_down {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 100%;
  padding: 3px 12px;
  min-width: 100px;
  display: grid;
  grid-template-columns: 1fr max-content;
}

.wrapper_general .section_title h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  padding-left: 0;
  color: #0f172a;
}

.wrapper_general .blackout_days__of_the_week {
  display: grid;
  grid-gap: 16px;
}

.wrapper_general .blackout_days__of_the_week .checkboxes {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(7, 40px);
}

.wrapper_general .blackout_days__of_the_week .checkboxes .every {
  font-weight: bold;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  transition: 0.4s;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 6px 12px;
  font-size: 14px;
  text-align: center;
  color: #d9d9d9;
  width: 40px;
  height: 36px;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.wrapper_general .blackout_days__of_the_week .checkboxes .every_active {
  border: 1px solid var(--main-blue-color);
  color: var(--main-blue-color);
}

.wrapper_general .blackout_days__of_the_year {
  margin-top: 24px;
}

.wrapper_general .blackout_days__of_the_year .checkboxes {
}

.wrapper_general .day_off_manually_list {
  margin: 24px 0;
}

.wrapper_general .day_off_manually_list .checkboxes {
  display: grid;
  grid-gap: 24px;
}

.wrapper_general .day_off_manually_list .checkboxes svg {
  cursor: pointer;
}

.wrapper_general .day_off_manually_list .every {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 6px;
  align-items: center;
}

.wrapper_general .add_day_off_manually {
  border: 1px solid #cbd5e1;
  box-sizing: border-box;
  padding: 16px 24px;
  width: 604px;
  border-radius: 8px;
}

.wrapper_general .add_day_off_manually .fields {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 16px;
}

.wrapper_general .add_day_off_manually .wrapper_picker {
  width: 120px;
}

.wrapper_general .add_day_off_manually .wrapper_picker .ant-picker-header > button {
}

.wrapper_general .add_day_off_manually button {
  margin-top: 16px;
  padding: 2px 40px;
}

.wrapper_general .btns-container {
  margin-top: 24px;
}

.wrapper_general .add_day_off_manually .fields .wrapper_input input {
  padding: 4px 12px;
}

.wrapper_general .communication {
  padding-top: 24px;
  border-top: 1px solid #e2e8f0;
}
.wrapper_general .communication .section_title h3 {
  padding: 0;
}

@media screen and (max-width: 1300px) {
  .wrapper_general .business_day {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }
}

@media screen and (max-width: 500px) {
  .wrapper_general .business_day {
    grid-template-columns: 1fr;
  }
}
