.chatsNotFound {
  margin-top: 24px;
  display: block;
  text-align: center;
  font-weight: 700;
}

.tabs > div:first-child {
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  z-index: 1;
}
