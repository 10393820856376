.diligence-add-item .ant-modal-body > * + * {
  margin-top: 24px;
}

.diligence-add-item .ant-modal-title {
  font-size: 20px;
}

.diligence-add-item .add-item-title {
  color: #000;
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}

.diligence-add-item .questions-list > .question-wrapper + .question-wrapper {
  margin-top: 32px;
}

.diligence-add-item .questions-list .question-wrapper > * + * {
  margin-top: 8px;
}

.question-wrapper input {
  min-height: 38px;
}

.question-wrapper .ant-input-number-group-wrapper {
  width: 100%;
}

.diligence-add-item .add-item-disclaimer {
  background: var(--colour-neutral-grey-4);
  border-radius: 8px;
  padding: 10px;
}

.diligence-add-item .questions-list .question-wrapper.additional-question-wrapper > div {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 24px;
}

.diligence-add-item .questions-list .question-wrapper.additional-question-wrapper > div svg {
  cursor: pointer;
}

.diligence-add-item .actions {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}

.diligence-add-item .logo-row {
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 4px;
  min-height: 120px;
  padding: 16px;
  transition: 0.3s border;
  width: 100%;
}

.diligence-add-item .logo-row.error {
  border-color: #f00;
}

.diligence-add-item .logo-row .document {
  align-items: center;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 24px;
}

.diligence-add-item .logo-row .document > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.diligence-add-item .logo-row .upload-logo {
  align-items: center;
  display: flex;
  font-size: 14px;
}

.diligence-add-item .logo-row .upload-logo > svg {
  margin-left: 16px;
}

.diligence-add-item .ant-btn {
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
}

.diligence-add-item .ant-btn.btn_darkblue:hover,
.diligence-add-item .ant-btn.btn_darkblue:focus {
  color: #ffffff;
  background-color: var(--main-darkblue-color);
  border-color: var(--main-darkblue-color);
}
