.headerWrap,
.contentWrap {
  grid-column: 1/3;
}

.headerWrap {
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  display: grid;
  grid-template-columns: 210px 1fr;
  align-items: center;
  min-height: 56px;
  width: 100%;
  z-index: 42;
}

.headerWrap header {
  box-shadow: none;
}

.contentWrap {
  min-height: calc(100vh - 56px);
  overflow-y: auto;
}

.contentWhite {
  background: var(--dr_white);
}

.contentGray {
  background: #f0f0f0;
}
