.drop_down_wrapper {
  display: grid;
  align-self: self-start;
}

.bordered .ant-dropdown-trigger {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 100%;
  padding: 9px 16px;
  /* The same as text input */
  display: grid;
  grid-template-columns: 1fr max-content;
  transition: 0.3s;
}

.bordered.input_error .ant-dropdown-trigger {
  border: 1px solid var(--error) !important;
}

.drop_down_wrapper__title {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 8px;
}

.drop_down_wrapper_disabled .ant-dropdown-trigger {
  background: #f5f5f5;
  cursor: not-allowed;
}

.ant-dropdown-trigger > img {
  margin: 0 0 0 auto;
}

.ant-dropdown-trigger {
  cursor: pointer;
  width: max-content;
}

.ant-dropdown-menu {
  max-height: 200px;
  overflow: auto;
}

.wrapper_drop_down .wrapper_input {
  margin: 0;
}

.wrapper_drop_down .input_container .custom_input {
  border: unset;
  padding: 0;
}

.wrapper_drop_down .input_container .custom_input:focus {
  box-shadow: unset;
}
